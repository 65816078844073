import { useSelector } from "react-redux";
import { RootState } from "../stores/store";

const useSessionData = () => {
  const authToken = useSelector((state: RootState) => state.auth.authToken);

  const getClientToken = (): string | null => {
    return authToken;
  };

  const getBaseURL = (): string | undefined => {
    const API_URL =
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_BASE_LOCAL_URL
        : process.env.REACT_APP_BASE_URL;
    return API_URL;
  };
  return { getClientToken, getBaseURL };
};

export default useSessionData;
