import React, { useState } from "react";
import DynamicForm, { FieldConfig } from "../../components/forms/DynamicForm";
import * as Yup from "yup";
import { ArticleSetFormValues } from "../../interface/responseInterface/ArticleSetInterface";
import ArticleForm from "./ArticleSetForm";
import useBranches from "../../hooks/useBranches";

interface Article {
  articleName: string;
  articleTag: string;
}

const RegisterArticleSet: React.FC = () => {
  const [articles, setArticles] = useState<Article[]>([
    { articleName: "", articleTag: "" },
  ]);

  const { branchOptions } = useBranches();

  const articleInitialValues: ArticleSetFormValues = {
    ak_name: "",
    ak_tray_id: "",
    ak_tray_name: "",
    ak_lot: 0,
    ak_isActive: "3",
    branch_name: "0",
    ak_additionalInfo: "",
  };

  const articleValidationSchema: Yup.ObjectSchema<ArticleSetFormValues> =
    Yup.object().shape({
      ak_name: Yup.string().required("Scanned Tag is required."),
      ak_tray_id: Yup.string().required("Article Type is required"),
      ak_tray_name: Yup.string().required("Article Category is required"),
      ak_lot: Yup.number().required("Lot Number is required"),
      ak_isActive: Yup.string().required("Article Active is required."),
      branch_name: Yup.string().required("Branch is required"),
      ak_additionalInfo: Yup.string(),
      ak_tag_ids: Yup.string(),
      ak_tag_names: Yup.string(),
    });

  const articleFields: FieldConfig[] = [
    {
      name: "ak_name",
      label: "Article Kit Name *",
      type: "text",
      fullWidth: true,
    },
    {
      name: "ak_tray_name",
      label: "Tray Name *",
      type: "text",
    },
    {
      name: "ak_tray_id",
      label: "Scan Tag for Tray *",
      type: "text",
    },
    { name: "ak_lot", label: "Lot Number *", type: "text" },
    {
      name: "ak_isActive",
      label: "Article Active *",
      type: "select",
      options: [
        { value: "3", label: "-- Select --" },
        { value: "1", label: "Yes" },
        { value: "0", label: "No" },
      ],
    },
    {
      name: "branch_name",
      label: "Select Branch *",
      type: "select",
      options: branchOptions,
      fullWidth: true,
    },
    {
      name: "ak_additionalInfo",
      label: "Additional Information",
      type: "text",
      fullWidth: true,
    },
  ];

  const handleArticleChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    const updatedArticles = [...articles];
    updatedArticles[index][name as keyof Article] = value;
    setArticles(updatedArticles);
  };

  const removeArticleData = (index: number) => {
    const updatedArticles = articles.filter((_, i) => i !== index);
    setArticles(updatedArticles);
  };

  const addArticle = () => {
    setArticles([...articles, { articleName: "", articleTag: "" }]);
  };

  const handleSubmit = (values: ArticleSetFormValues) => {
    console.log("Form Data:", values, "Articles:", articles);
  };

  return (
    <div>
      <DynamicForm
        initialValues={articleInitialValues}
        validationSchema={articleValidationSchema}
        fields={articleFields}
        formTitle="Register Autoclave"
        onSubmit={handleSubmit}
      />
      <div>
        {articles.map((input, index) => (
          <ArticleForm
            key={index}
            input={input}
            index={index}
            handleArticleChange={handleArticleChange}
            removeArticleData={removeArticleData}
          />
        ))}
        <button onClick={addArticle}>Add More Article</button>
        <button onClick={() => handleSubmit(articleInitialValues)}>
          Submit
        </button>
      </div>
    </div>
  );
};

export default RegisterArticleSet;
