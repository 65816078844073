import {
  Box,
  Container,
  Typography,
  Card,
  CardContent,
  Avatar,
  Grid,
  IconButton,
  CircularProgress,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import LockIcon from "@mui/icons-material/Lock";
import axios from "axios";
import { useEffect, useState } from "react";
import useSessionData from "../../hooks/useSessionData";
import { UserInterface } from "../../interface/responseInterface/UserProfileInterface";
import { useSelector } from "react-redux";
import { RootState } from "../../stores/store";
import medicalIcon from "../../assets/images/medical-icon.png";

const UserProfile = () => {
  const email = useSelector((state: RootState) => state.auth.emailId);
  const { getClientToken, getBaseURL } = useSessionData();
  const API_URL = getBaseURL();
  const [response, setResponse] = useState<UserInterface>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    const getUserInformation = async () => {
      try {
        const { data } = await axios.get<UserInterface>(
          `${API_URL}user/user-profile/${email}`,
          {
            headers: {
              Authorization: `Bearer ${getClientToken()}`,
            },
          }
        );
        setResponse(data);
      } catch (error) {
        console.error("Error fetching service log:", error);
      } finally {
        setLoading(false);
      }
    };

    getUserInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      {loading && (
        <Box display="flex" justifyContent="center" mt={3}>
          <CircularProgress color="primary" size={70} />
        </Box>
      )}
      {response && (
        <Card sx={{ display: "flex", padding: 2 }}>
          <Avatar
            src={medicalIcon}
            alt={response.name}
            sx={{ width: 120, height: 120, marginRight: 3 }}
          />
          <CardContent sx={{ flex: 1 }}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h4">Dr. {response.name}</Typography>
              <Box display="flex" justifyContent="flex-end">
                {/* <IconButton
                  color="primary"
                  aria-label="edit"
                  sx={{ padding: 0 }}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  color="error"
                  aria-label="change-password"
                  sx={{ padding: 0 }}
                >
                  <LockIcon />
                </IconButton> */}
              </Box>
            </Box>
            <Typography variant="subtitle1" color="text.secondary">
              {Number(response.role_id) === 1
                ? "Admin"
                : Number(response.role_id) === 2
                ? "Supervisor"
                : "Staff"}
            </Typography>
            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={12} sm={4}>
                <Typography variant="body1">
                  <strong>Username:</strong> {response.username}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Typography variant="body1">
                  <strong>Email:</strong> {response.email}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  <strong>Registered Branch:</strong> {response.branch_name},{" "}
                  {response.city}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
    </Container>
  );
};

export default UserProfile;
