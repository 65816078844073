import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Paper,
  Typography,
} from "@mui/material";
import {
  AddCircleOutline as AddCircleOutlineIcon,
  Add as AddIcon,
  RemoveCircleOutline as RemoveCircleOutlineIcon,
  Remove as RemoveIcon,
} from "@mui/icons-material";
import { BilogicalRecordSheetResponse } from "../../interface/responseInterface/BiFailedReportInterface";

interface DetailsDialogProps {
  open: boolean;
  onClose: () => void;
  article?: BilogicalRecordSheetResponse | null;
  onViewPhoto: () => void;
}

const DetailsDialog = ({
  open,
  onClose,
  article,
  onViewPhoto,
}: DetailsDialogProps) => {
  if (!article) return null;

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
      <DialogTitle sx={{ textAlign: "center", fontWeight: "bold" }}>
        {article.sterilizer_name}
      </DialogTitle>
      <DialogContent sx={{ backgroundColor: "#eff9f0" }}>
        <Box m={2}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="stretch"
            sx={{ gap: 2 }}
          >
            <Paper
              elevation={3}
              sx={{
                textAlign: "center",
                padding: 3,
                flex: 1,
                backgroundColor: "#fff",
              }}
            >
              <Typography variant="body1" fontWeight="bold" gutterBottom>
                Incubation Date
              </Typography>
              <Typography>
                In: {article.load_started_at.split("T")[0]}
              </Typography>
              <Typography>
                Out: {article.bi_finished_at.split("T")[0]}
              </Typography>
            </Paper>

            <Paper
              elevation={3}
              sx={{
                textAlign: "center",
                padding: 3,
                flex: 1,
                backgroundColor: "#fff",
              }}
            >
              <Typography variant="body1" fontWeight="bold" gutterBottom>
                Test Results
              </Typography>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ gap: 2, height: "100%" }}
              >
                {article.bi_result === 1 ? (
                  <>
                    <AddIcon sx={{ color: "red", fontSize: "40px" }} />
                    <RemoveCircleOutlineIcon
                      sx={{ color: "green", fontSize: "40px" }}
                    />
                  </>
                ) : (
                  <>
                    <AddIcon sx={{ color: "red", fontSize: "40px" }} />
                    <RemoveIcon sx={{ color: "red", fontSize: "40px" }} />
                  </>
                )}
              </Box>
            </Paper>
            <Paper
              elevation={3}
              sx={{
                textAlign: "center",
                padding: 3,
                flex: 1,
                backgroundColor: "#fff",
              }}
            >
              <Typography variant="body2" fontWeight="bold" gutterBottom>
                Control Results
              </Typography>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ gap: 2, height: "100%" }}
              >
                {article.bi_result === 1 ? (
                  <>
                    <AddCircleOutlineIcon
                      sx={{ color: "green", fontSize: "40px" }}
                    />
                    <RemoveIcon sx={{ color: "red", fontSize: "40px" }} />
                  </>
                ) : (
                  <>
                    <AddIcon sx={{ color: "red", fontSize: "40px" }} />
                    <RemoveIcon sx={{ color: "red", fontSize: "40px" }} />
                  </>
                )}
              </Box>
            </Paper>
            <Paper
              elevation={3}
              sx={{
                textAlign: "center",
                padding: 3,
                flex: 1,
                backgroundColor: "#fff",
              }}
            >
              <Typography variant="body1" fontWeight="bold" gutterBottom>
                Comments
              </Typography>
              <Typography>No Comments!</Typography>
            </Paper>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions style={{ backgroundColor: "#eff9f0" }}>
        <Button onClick={onClose} color="primary" type="button">
          OK
        </Button>
        <Button variant="outlined" onClick={onViewPhoto} type="button">
          View Photo
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DetailsDialog;
