import {
  Box,
  Container,
  Typography,
  Card,
  CardContent,
  Avatar,
  Grid,
  IconButton,
  CircularProgress,
} from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import axios from "axios";
import { useEffect, useState } from "react";
import useSessionData from "../../hooks/useSessionData";
import { AccountResponseInterface } from "../../interface/responseInterface/AccountInterface";
import clinicIcon from "../../assets/images/dental-clinic.png";
import { useNavigate } from "react-router-dom";

const Account = () => {
  const { getClientToken, getBaseURL } = useSessionData();
  const API_URL = getBaseURL();
  const [response, setResponse] = useState<AccountResponseInterface>();
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    const getUserInformation = async () => {
      try {
        const { data } = await axios.get<AccountResponseInterface>(
          `${API_URL}user/account-information`,
          {
            headers: {
              Authorization: `Bearer ${getClientToken()}`,
            },
          }
        );
        console.log(data);
        setResponse(data);
      } catch (error) {
        console.error("Error fetching service log:", error);
      } finally {
        setLoading(false);
      }
    };

    getUserInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      {loading ? (
        <Box display="flex" justifyContent="center" mt={3}>
          <CircularProgress color="primary" size={70} />
        </Box>
      ) : (
        response && (
          <Card sx={{ display: "flex", padding: 2 }}>
            <CardContent sx={{ flex: 1 }}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h4">Dr. {response.users.name}</Typography>
                <Box display="flex" justifyContent="flex-end">
                  {/* <IconButton
                  color="primary"
                  aria-label="edit"
                  sx={{ padding: 0 }}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  color="error"
                  aria-label="change-password"
                  sx={{ padding: 0 }}
                >
                  <LockIcon />
                </IconButton> */}
                </Box>
              </Box>
              <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={12}>
                  <Typography variant="body1">
                    <strong>Clinic Name:</strong>{" "}
                    {response.details.client_id.split("_")[1]}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">
                    <strong>Primary Contact:</strong>{" "}
                    {response.users.branch_contact}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">
                    <strong>Primary Branch:</strong>{" "}
                    {response.users.branch_name}, {response.users.city}
                  </Typography>
                </Grid>
              </Grid>

              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={2}
              ></Box>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={4}>
                  <Box display="flex" justifyContent="" alignItems="center">
                    <Typography variant="body1">
                      <strong>Total Users:</strong>{" "}
                      {response.details.total_users}
                    </Typography>
                    <Box display="flex">
                      <IconButton
                        color="primary"
                        aria-label="manage"
                        sx={{ marginLeft: 2 }}
                        onClick={() => navigate("/users")}
                      >
                        <LaunchIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Box display="flex" justifyContent="" alignItems="center">
                    <Typography variant="body1">
                      <strong>Total Branches:</strong>{" "}
                      {response.details.total_branches}
                    </Typography>
                    <Box display="flex">
                      <IconButton
                        color="primary"
                        aria-label="manage"
                        sx={{ marginLeft: 2 }}
                        onClick={() => navigate("/branches")}
                      >
                        <LaunchIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </Grid>
                {response.details.total_readers > 0 && (
                  <Grid item xs={12} sm={4}>
                    <Box display="flex" justifyContent="" alignItems="center">
                      <Typography variant="body1">
                        <strong>Total Terminals:</strong>{" "}
                        {response.details.total_readers}
                      </Typography>
                      <Box display="flex">
                        <IconButton
                          color="primary"
                          aria-label="manage"
                          sx={{ marginLeft: 2 }}
                          onClick={() => navigate("/readers")}
                        >
                          <LaunchIcon />
                        </IconButton>
                      </Box>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>
        )
      )}
    </Container>
  );
};

export default Account;
