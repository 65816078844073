import { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Paper,
  Typography,
  TextField,
  Button,
  DialogActions,
  CircularProgress,
  Box,
} from "@mui/material";
import axios from "axios";
import {
  articleDetails,
  FailedBIReportDialogProps,
} from "../../interface/responseInterface/BiFailedReportInterface";
import useSessionData from "../../hooks/useSessionData";
import { useMessageModal } from "../../hooks/useMessageModal";

const FailedBIReportDialog = ({
  open,
  articleInfo,
  parentCallback,
}: FailedBIReportDialogProps) => {
  const [response, setResponse] = useState<articleDetails[]>([]);
  const [loading, setLoading] = useState<boolean>(true); // Loading state
  const [emailDialogOpen, setEmailDialogOpen] = useState<boolean>(false);
  const [emailId, setEmailId] = useState<string>("");
  const [sendEmailLoading, setSendEmailLoading] = useState<boolean>(false);
  const { getClientToken, getBaseURL } = useSessionData();
  const { handleOpenModal, ModalComponent } = useMessageModal();
  const API_URL = getBaseURL();

  useEffect(() => {
    if (open) {
      const fetchArticleDetails = async () => {
        setLoading(true); // Start loading
        try {
          const result = await axios.post(
            `${API_URL}/web-reports/failedbireport`,
            {
              st_id: articleInfo.st_id,
              cycle_start: articleInfo.cycle_start,
              cycle_end: articleInfo.cycle_end,
            },
            {
              headers: {
                Authorization: `Bearer ${getClientToken()}`,
              },
            }
          );
          setResponse(result.data);
        } catch (error) {
          console.error("Error fetching article details:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchArticleDetails();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleClose = () => {
    parentCallback(false);
  };

  const handleSendEmail = async () => {
    setSendEmailLoading(true);
    try {
      const result = await axios.post(
        `${API_URL}web-reports/bicallback`,
        {
          st_id: articleInfo.st_id,
          cycle_start: articleInfo.cycle_start,
          cycle_end: articleInfo.cycle_end,
          emailid: emailId,
        },
        {
          headers: {
            Authorization: `Bearer ${getClientToken()}`,
          },
        }
      );
      if (result.status === 201) {
        handleOpenModal("success", "Email Sent Successfully.");
      } else {
        handleOpenModal(
          "error",
          "Error Sending Email. Please check the entered email address."
        );
      }
    } catch (error) {
      handleOpenModal(
        "error",
        "Error Sending Email. Please check the entered email address."
      );
    } finally {
      setSendEmailLoading(false);
      setEmailDialogOpen(false);
    }
  };

  const handleConfirmEmailSend = () => {
    setEmailDialogOpen(false);
    handleSendEmail();
  };

  return (
    <>
      <ModalComponent />
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Callback Details</DialogTitle>
        <DialogContent
          style={{ backgroundColor: "#eff9f0", paddingBottom: "5px" }}
        >
          {loading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="200px"
            >
              <CircularProgress />
            </Box>
          ) : response.length > 0 ? (
            response.map((item, index) => (
              <Paper
                key={index}
                sx={{ padding: 2, marginBottom: 2, marginTop: 2 }}
              >
                <Typography variant="h6" component="div">
                  Article Name: {item.articleName}
                </Typography>
                <Typography component="p">
                  Control No: {item.control_no}
                </Typography>
                <Typography component="p">Cycle No: {item.cycle_no}</Typography>
                {item.is_used ? (
                  <Typography color="error" component="p">
                    USED - Call The Patient
                  </Typography>
                ) : item.is_failed ? (
                  <Typography color="success" component="p">
                    DISCARDED
                  </Typography>
                ) : null}
              </Paper>
            ))
          ) : (
            <Typography variant="body1" align="center">
              No reports available.
            </Typography>
          )}
        </DialogContent>
        <DialogActions style={{ backgroundColor: "#eff9f0" }}>
          <Button onClick={handleClose} color="primary">
            OK
          </Button>
          <Button
            onClick={() => setEmailDialogOpen(true)}
            color="primary"
            disabled={sendEmailLoading}
            startIcon={sendEmailLoading ? <CircularProgress size={24} /> : null}
          >
             {sendEmailLoading ? "Sending..." : "Send Email Report"}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Email Dialog */}
      <Dialog
        open={emailDialogOpen}
        onClose={() => setEmailDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Enter Email Address</DialogTitle>
        <DialogContent style={{ backgroundColor: "#eff9f0" }}>
          <TextField
            fullWidth
            label="Email"
            variant="standard"
            onChange={(e) => setEmailId(e.target.value)}
          />
        </DialogContent>
        <DialogActions style={{ backgroundColor: "#eff9f0" }}>
          <Button onClick={() => setEmailDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmEmailSend} color="primary">
            Send Email
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FailedBIReportDialog;
