import { useSelector } from "react-redux";
import { RootState } from "../stores/store";

export default class SessionData {
  // Get client token
  getClientToken = (): string | null => {
    return useSelector((state: RootState) => state.auth.authToken);
  };

  // Get base url
  getBaseURL = (): string | undefined => {
    const API_URL =
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_BASE_LOCAL_URL
        : process.env.REACT_APP_BASE_URL;
    return API_URL;
  };
}
