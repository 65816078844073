import DynamicForm, { FieldConfig } from "../../components/forms/DynamicForm";
import * as Yup from "yup";
import { UserFormValues } from "../../interface/responseInterface/UserInterface";
import useSessionData from "../../hooks/useSessionData";
import { FormikHelpers } from "formik";
import axios from "axios";
import useBranches from "../../hooks/useBranches";
import { useMessageModal } from "../../hooks/useMessageModal";

const RegisterUser = () => {
  const { getClientToken, getBaseURL } = useSessionData();
  const API_URL = getBaseURL();
  const { branchOptions } = useBranches();
  const { handleOpenModal, ModalComponent } = useMessageModal();

  const userInitialValues: UserFormValues = {
    firstName: "",
    lastName: "",
    email: "",
    username: "",
    pin: "",
    wristband: "",
    password: "",
    repeatPass: "",
    level: "0",
    branch_name: "0",
    sendEmail: false,
  };

  const userValidationSchema: Yup.ObjectSchema<UserFormValues> =
    Yup.object().shape({
      firstName: Yup.string().required("First Name is required"),
      lastName: Yup.string().required("Last Name is required"),
      email: Yup.string().email("Invalid email").required("Email is required"),
      username: Yup.string().required("Username is required"),
      pin: Yup.string().required("Pin is required"),
      wristband: Yup.string().nullable(), // Allow wristband to be nullable
      password: Yup.string().required("Password is required"),
      repeatPass: Yup.string()
        .oneOf([Yup.ref("password")], "Passwords must match")
        .required("Repeat Password is required"),
      level: Yup.string().required("User level is required"),
      branch_name: Yup.string().required("Branch is required"),
      sendEmail: Yup.boolean(),
    });

  const userFields: FieldConfig[] = [
    { name: "firstName", label: "First Name *", type: "text" },
    { name: "lastName", label: "Last Name *", type: "text" },
    { name: "email", label: "Email *", type: "text", inputType: "email" },
    { name: "username", label: "Username *", type: "text" },
    { name: "pin", label: "Pin *", type: "text" },
    { name: "wristband", label: "Wristband Tagid", type: "text" },
    {
      name: "password",
      label: "Password *",
      type: "text",
      inputType: "password",
    },
    {
      name: "repeatPass",
      label: "Repeat Password *",
      type: "text",
      inputType: "password",
    },
    {
      name: "level",
      label: "User Level *",
      type: "select",
      options: [
        { value: "0", label: "-- Select User Level --" },
        { value: "3", label: "Staff" },
        { value: "2", label: "Supervisor" },
        { value: "1", label: "Admin" },
      ],
    },
    {
      name: "branch_name",
      label: "Branch *",
      type: "select",
      options: branchOptions,
    },
    { name: "sendEmail", label: "Send Email", type: "checkbox" },
  ];

  const handleSubmit = async (
    values: UserFormValues,
    { resetForm }: FormikHelpers<UserFormValues>
  ) => {
    try {
      const response = await axios.post(
        `${API_URL}user`,
        {
          name: `${values.firstName} ${values.lastName}`,
          email: values.email,
          password: values.password,
          username: values.username,
          pin: values.pin,
          level: values.level,
          branch_id: values.branch_name,
          wristband: values.wristband,
          sendEmail: values.sendEmail,
        },
        {
          headers: {
            Authorization: `Bearer ${getClientToken()} `,
          },
        }
      );
      if (response.status !== 201) {
        handleOpenModal("error", "Error while registering user.");
        return;
      }
      handleOpenModal("success", "Successfully registered user.");
    } catch (error) {
      handleOpenModal("error", "Error while registering user.");
    } finally {
      resetForm();
    }
  };

  return (
    <div>
      <ModalComponent />
      <DynamicForm
        initialValues={userInitialValues}
        validationSchema={userValidationSchema}
        fields={userFields}
        formTitle="Register User"
        onSubmit={handleSubmit}
      />
    </div>
  );
};

export default RegisterUser;
