import { createAction } from "@reduxjs/toolkit";

export const login = createAction(
  "auth/login",
  (user: {
    authToken: string;
    clientName: string;
    emailId: string;
    roleId: string;
    rfidEnabled: string;
  }) => {
    return {
      payload: user,
    };
  }
);

export const logout = createAction("auth/logout");
