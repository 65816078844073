import { createTheme } from "@mui/material";
import { indigo, amber, red, deepPurple, teal } from "@mui/material/colors";

const theme = createTheme({
    palette: {
        mode: "light",
        primary: indigo,
        secondary: amber,
        error: red,
        warning: deepPurple,
        success: teal,
        info: deepPurple
    },
    typography: {
        fontFamily: [
            "Montserrat",
            "sans-serif",
            '"Segoe UI"',
            '"Helvetica Neue"',
        ].join(","),
        fontSize: 16,
    },
});

export default theme;