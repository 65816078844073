import { useEffect, useState } from "react";
import DynamicTable from "../../components/table/DynamicTable";
import axios from "axios";
import useSessionData from "../../hooks/useSessionData";
import { Column, Data, TableActions } from "../../interface/TableInterface";
import { useSelector } from "react-redux";
import { RootState } from "../../stores/store";
import { useMessageModal } from "../../hooks/useMessageModal";
import ViewAutoclavesCycles from "./ViewAutoclavesCycles";
import ConfirmationDialog from "../../components/dialog/ConfirmationDialog";
import { AutoclaveTypeResponse } from "../../interface/responseInterface/AutoclaveInterface";
import { TextField, Select, MenuItem } from "@mui/material";

const columns: Column[] = [
  { id: "id", label: "#", numeric: false },
  { id: "name", label: "Name", numeric: false },
  { id: "status", label: "Status", numeric: false },
  { id: "branch_name", label: "Branch", numeric: false },
];

const Autoclaves = () => {
  const { getClientToken, getBaseURL } = useSessionData();
  const API_URL = getBaseURL();
  const [responseData, setResponseData] = useState<AutoclaveTypeResponse[]>([]);
  const [filteredData, setFilteredData] = useState<Data[]>([]);
  const { handleOpenModal, ModalComponent } = useMessageModal();
  const currentBranch = useSelector(
    (state: RootState) => state.branch.currentBranch
  );
  const [selectedAutoclave, setSelectedAutoclave] =
    useState<AutoclaveTypeResponse | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [statusChangeAutoclave, setStatusChangeAutoclave] =
    useState<AutoclaveTypeResponse | null>(null);

  // State for filters
  const [statusFilter, setStatusFilter] = useState<string>("");
  const [nameFilter, setNameFilter] = useState<string>("");

  const getAutoclaveList = async () => {
    const branchQuery: string = `br.branch_id=${currentBranch}`;
    try {
      const { data } = await axios.get<AutoclaveTypeResponse[]>(
        `${API_URL}/sterilizer/web?${branchQuery}`,
        {
          headers: {
            Authorization: `Bearer ${getClientToken()}`,
          },
        }
      );
      setResponseData(data);
      setFilteredData(mapData(data));
    } catch (error) {
      handleOpenModal("error", "Something went wrong while fetching data!");
    }
  };

  const mapData = (data: AutoclaveTypeResponse[]): Data[] => {
    return data.map((item) => ({
      id: item.id,
      name: item.name,
      status: item.status.toUpperCase(),
      branch_name: item.branch_name,
    }));
  };

  // Filter data based on the filters
  const applyFilters = () => {
    let filtered = responseData;

    if (statusFilter) {
      filtered = filtered.filter(
        (autoclave) =>
          autoclave.status.toLowerCase() === statusFilter.toLowerCase()
      );
    }

    if (nameFilter) {
      filtered = filtered.filter((autoclave) =>
        autoclave.name.toLowerCase().includes(nameFilter.toLowerCase())
      );
    }

    setFilteredData(mapData(filtered));
  };

  useEffect(() => {
    getAutoclaveList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    applyFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusFilter, nameFilter]);

  const handleViewDetails = (id: number) => {
    const autoclave = responseData.find((row) => row.id === id);
    if (autoclave) {
      setSelectedAutoclave(autoclave);
      setDialogOpen(true);
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedAutoclave(null);
  };

  const handleStatusChange = (id: number) => {
    const autoclave = responseData.find((row) => row.id === id);

    if (autoclave) {
      setStatusChangeAutoclave(autoclave);
      const newStatus =
        autoclave.status.toLowerCase() === "available" ? "Suspend" : "Activate";
      setConfirmationMessage(
        `Are you sure you want to ${newStatus} the autoclave?`
      );
      setConfirmationDialogOpen(true);
    }
  };

  const confirmStatusChange = async () => {
    if (!statusChangeAutoclave) return;

    try {
      const statusDigit =
        statusChangeAutoclave.status.toLowerCase() === "available" ? "0" : "1";

      const { status } = await axios.post(
        `${API_URL}/web-register/suspend`,
        {
          id: statusChangeAutoclave.id.toString(),
          status_digit: statusDigit,
        },
        {
          headers: {
            Authorization: `Bearer ${getClientToken()}`,
          },
        }
      );

      if (status === 201) {
        handleOpenModal("success", "Status Changed");
        setConfirmationDialogOpen(false);
        getAutoclaveList();
      }
    } catch (error) {
      handleOpenModal("error", "Error changing status");
    }
  };

  const handleCloseConfirmationDialog = () => {
    setConfirmationDialogOpen(false);
  };

  const rowActions: TableActions[] = filteredData.map((row) => ({
    id: `view_${row.id}`,
    label: "View Cycles",
    onClick: () => handleViewDetails(row.id),
  }));

  filteredData.forEach((row) => {
    rowActions.push({
      id: `edit_${row.id}`,
      label:
        row.status === "AVAILABLE"
          ? "Suspend"
          : row.status === "IN-USE"
          ? ""
          : "Activate",
      onClick: () => handleStatusChange(row.id),
    });
  });

  return (
    <>
      <ModalComponent />
      <h4>Autoclaves</h4>

      {/* Filter Inputs */}
      <div style={{ marginBottom: "1rem" }}>
        <TextField
          label="Autoclave Name"
          variant="outlined"
          size="small"
          value={nameFilter}
          onChange={(e) => setNameFilter(e.target.value)}
          style={{ marginRight: "1rem" }}
        />
        <Select
          value={statusFilter}
          size="small"
          onChange={(e) => setStatusFilter(e.target.value)}
          displayEmpty
          variant="outlined"
        >
          <MenuItem value="">All Statuses</MenuItem>
          <MenuItem value="available">Available</MenuItem>
          <MenuItem value="out-of-order">Out-Of-Order</MenuItem>
          <MenuItem value="in-use">In-Use</MenuItem>
          <MenuItem value="unavailable">Unavailable</MenuItem>
        </Select>
      </div>

      <DynamicTable
        headCells={columns}
        rows={filteredData}
        actions={rowActions}
      />

      {selectedAutoclave && (
        <ViewAutoclavesCycles
          open={dialogOpen}
          articleInfo={selectedAutoclave}
          parentCallback={handleCloseDialog}
        />
      )}

      <ConfirmationDialog
        open={confirmationDialogOpen}
        title="Confirm Action"
        message={confirmationMessage}
        onConfirm={confirmStatusChange}
        onCancel={handleCloseConfirmationDialog}
      />
    </>
  );
};

export default Autoclaves;
