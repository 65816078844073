import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {
  Avatar,
  ListItemButton,
  Typography,
  CircularProgress,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import dashboard from "../../assets/images/dashboard.png";
import medicalIcon from "../../assets/images/medical-icon.png";
import dentalIcon from "../../assets/images/dental-clinic.png";
import useInstrument from "../../assets/images/use-instrument.png";
import searchIcon from "../../assets/images/search-icon.png";
import avImage from "../../assets/images/autoclave-icon.png";
import avLaods from "../../assets/images/autoclave-loads.png";
import usedArticle from "../../assets/images/used-articles.png";
import autoclaveService from "../../assets/images/autoclave-service.png";
import failedReport from "../../assets/images/failed-bi.png";
import biExternalReport from "../../assets/images/bi-external-report.png";
import biRecordSheet from "../../assets/images/bi-record-sheet.png";
import readerIcon from "../../assets/images/location-pointer3.png";
import branchIcon from "../../assets/images/location-pointer2.png";
import users from "../../assets/images/user-logo.png";
import { HeaderProps, MenuTypes } from "../../interface/SidebarInterface";
import useExtractionLocation from "../../hooks/useExtractionLocation";
import React, { useEffect } from "react";
import { AppDispatch, RootState } from "../../stores/store";
import { useDispatch, useSelector } from "react-redux";
import { fetchClient } from "../../thunks/clientThunks";
import useSessionData from "../../hooks/useSessionData";

const menuItems: MenuTypes[] = [
  { text: "Dashboard", icon: dashboard, path: "/" },
  { text: "Check Status", icon: searchIcon, path: "/check-status" },
  { text: "Use Instrument", icon: useInstrument, path: "/use-instrument" },
  { text: "Autoclaves", icon: avImage, path: "/autoclaves" },
  { text: "Autoclave Loads", icon: avLaods, path: "/autoclaves-loads" },
  {
    text: "Autoclave Service Logs",
    icon: autoclaveService,
    path: "/service-log",
  },
  { text: "Used Articles", icon: usedArticle, path: "/articles" },
  { text: "BI Callback", icon: failedReport, path: "/bi-callback" },
  { text: "BI Record Sheet", icon: biRecordSheet, path: "/bi-record-sheet" },
  // {
  //   text: "BI External Report",
  //   icon: biExternalReport,
  //   path: "/bi-external-report",
  // },
  {
    text: "Users",
    icon: users,
    path: "/users",
    condition: (roleId) => roleId === 1 || roleId === 2,
  },
  {
    text: "Branches",
    icon: branchIcon,
    path: "/branches",
    condition: (roleId) => roleId === 1,
  },
  {
    text: "Readers",
    icon: readerIcon,
    path: "/readers",
    rfidCondition: (rfidEnabled) => rfidEnabled === 1,
  },
  {
    text: "Dental Account",
    icon: dentalIcon,
    path: "/account",
    rfidCondition: (rfidEnabled) => rfidEnabled === 1,
  },
];

const Sidebar = ({ drawerWidth }: HeaderProps) => {
  const { pathname } = useExtractionLocation();
  const dispatch = useDispatch<AppDispatch>();
  const clientInfoArray = useSelector(
    (state: RootState) => state.client.clientInfo
  );
  const loading = useSelector((state: RootState) => state.client.loading);

  const emailId = useSelector((state: RootState) => state.auth.emailId);

  const { getClientToken, getBaseURL } = useSessionData();
  const baseUrl = getBaseURL();
  const clientToken = getClientToken();

  useEffect(() => {
    if (emailId && baseUrl && clientToken) {
      dispatch(fetchClient(emailId, baseUrl, clientToken));
    }
  }, [baseUrl, clientToken, dispatch, emailId]);

  const clientInfo =
    clientInfoArray && clientInfoArray.length > 0 ? clientInfoArray[0] : null;

  const roleId = clientInfo ? Number(clientInfo.role_id) : null;
  const rfidEnabled = Number(
    useSelector((state: RootState) => state.auth.rfidEnabled)
  );

  const CustomListItemButton = React.forwardRef<HTMLDivElement, any>(
    (props, ref) => (
      <ListItemButton
        component={RouterLink}
        to={props.to}
        ref={ref}
        {...props}
      />
    )
  );

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Divider />
      <List>
        <ListItem>
          <ListItemIcon>
            <Avatar alt="medical-user" src={medicalIcon} />
          </ListItemIcon>
          <ListItemText
            primary={
              loading ? (
                <CircularProgress size={24} />
              ) : (
                <Typography fontWeight="bold">{clientInfo?.name}</Typography>
              )
            }
            secondary={
              !loading &&
              clientInfo && (
                <Typography
                  sx={{ display: "inline" }}
                  component="p"
                  variant="body2"
                  color="text.primary"
                  fontSize={12}
                >
                  {clientInfo.branch_name} - {clientInfo.city}
                </Typography>
              )
            }
            style={{ fontSize: "10px" }}
          />
        </ListItem>
      </List>
      <Divider />
      <List>
        {menuItems
          .filter((item) => {
            if (item.condition && !item.condition(roleId)) return false;
            if (item.rfidCondition && !item.rfidCondition(rfidEnabled))
              return false;
            return true;
          })
          .map((item, index) => (
            <ListItem
              disablePadding // CSS class for active link
              key={index}
            >
              <CustomListItemButton
                selected={pathname === item.path ? true : false}
                to={item.path}
              >
                <ListItemIcon>
                  <img className="imageIcon" src={item.icon} alt={item.text} />
                </ListItemIcon>
                <ListItemText primary={item.text} />
              </CustomListItemButton>
            </ListItem>
          ))}
      </List>
      <Divider />
    </Drawer>
  );
};

export default Sidebar;
