import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { MessageModalProps } from "../../interface/MessageModalInterface";
import { useTheme, Theme, darken } from "@mui/material/styles";

const getType = (type: "error" | "success" | "warning" | "info" | "alert") => {
  switch (type) {
    case "error":
      return "Error";
    case "success":
      return "Success";
    case "warning":
      return "Warning";
    case "alert":
      return "Alert";
    case "info":
      return "Information";
    default:
      return "Information";
  }
};

const getColor = (
  type: "error" | "success" | "warning" | "info" | "alert",
  theme: Theme
) => {
  switch (type) {
    case "error":
      return theme.palette.error.main;
    case "success":
      return theme.palette.success.main;
    case "warning":
      return theme.palette.secondary.main;
    case "alert":
      return theme.palette.secondary.main;
    case "info":
      return "#3f51b5";
    default:
      return "#3f51b5";
  }
};

const MessageModal = ({
  open,
  onClose,
  type,
  message,
  title,
}: MessageModalProps) => {
  const theme = useTheme();

  const color = getColor(type, theme);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{
          color: color,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {title || getType(type)}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ color: theme.palette.grey[500] }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          sx={{ color: theme.palette.text.primary }}
        >
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {/* <Button
          onClick={onClose}
          sx={{
            color: color,
            borderColor: color,
            "&:hover": {
              backgroundColor: darken(color, 0.2),
              color: "#fff",
            },
          }}
          variant="outlined"
        >
          Disagree
        </Button> */}
        <Button
          onClick={onClose}
          sx={{
            backgroundColor: color,
            color: "#fff",
            "&:hover": {
              backgroundColor: darken(color, 0.2),
            },
          }}
          variant="contained"
          autoFocus
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MessageModal;
