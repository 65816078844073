import { useState } from "react";
import axios from "axios";
import DynamicForm, { FieldConfig } from "../../components/forms/DynamicForm";
import {
  UseInstrumentInterface as CheckStatusInterface,
  UseInstrumentInterface,
} from "../../interface/responseInterface/UseInstrumentInterface";
import * as Yup from "yup";
import { FormikHelpers } from "formik";
import { useMessageModal } from "../../hooks/useMessageModal";
import useSessionData from "../../hooks/useSessionData";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { StatusData } from "../../interface/responseInterface/CheckStatusInterface";
import FetchBranches from "../../utils/FetchBranches";
import { BranchResponse } from "../../interface/responseInterface/BranchResponseInterface";
import { useSelector } from "react-redux";
import { RootState } from "../../stores/store";

const initialStatusData: StatusData = {
  itemName: "",
  totalCycles: 0,
  processedDate: "",
  sterilizer: "",
  certifiedBy: "",
  cycleNo: null,
  status: "",
  expiry: 0,
  controlNo: "",
  errorColor: false,
  showStatus: false,
};

const CheckStatus = () => {
  const { branchData } = FetchBranches();
  const { getClientToken, getBaseURL } = useSessionData();
  const { handleOpenModal, ModalComponent } = useMessageModal();
  const [discardDialogOpen, setDiscardDialogOpen] = useState<boolean>(false);
  const [removalReason, setRemovalReason] = useState<string>("");
  const [controlNo, setControlNo] = useState<string | number>("");
  const [loadedId, setLoadedId] = useState<number>(0);
  const [articleId, setArticleId] = useState<number>(0);
  const API_URL = getBaseURL();
  const [loading, setLoading] = useState<boolean>(false);
  const [statusData, setStatusData] = useState<StatusData>(initialStatusData);
  const currentBranch = useSelector(
    (state: RootState) => state.branch.currentBranch
  );
  // const rfidEnabled = useSelector((state: RootState) => state.auth.rfidEnabled);
  // const rfidEnabledValue = rfidEnabled !== null ? parseInt(rfidEnabled, 10) : null;

  const branches: { value: string; label: string }[] =
    branchData.length > 0
      ? [
          { value: "0", label: "-- Select Branch --" },
          ...branchData.map((branch: BranchResponse) => ({
            value: branch.branch_id.toString(),
            label: branch.branch_name,
          })),
        ]
      : [{ value: "0", label: "-- No Branches Available --" }];

  const checkStatusInitialValues: CheckStatusInterface = {
    controlNo: "",
    branch_id: currentBranch ? currentBranch : "0",
  };

  const checkStatusValidationSchema: Yup.ObjectSchema<CheckStatusInterface> =
    Yup.object().shape({
      controlNo: Yup.string().required("Control Number is required"),
      branch_id: Yup.string().required("Branch is required"),
    });

  const checkStatusFields: FieldConfig[] = [
    {
      name: "controlNo",
      label: "Enter Control Number",
      type: "text",
      fullWidth: true,
    },
    {
      name: "branch_id",
      label: "Select Branch *",
      type: "select",
      options: branches,
      fullWidth: true,
    },
  ];

  const handleSubmit = async (
    values: UseInstrumentInterface,
    { resetForm }: FormikHelpers<UseInstrumentInterface>
  ) => {
    setLoading(true);
    setControlNo(values.controlNo);
    setStatusData((prevState) => ({
      ...prevState,
      showStatus: false,
    }));

    try {
      const response = await axios.get(
        `${API_URL}web-standalone/get-article-info-using-controlno/${values.controlNo}/${values.branch_id}`,
        {
          headers: {
            Authorization: `Bearer ${getClientToken()} `,
          },
        }
      );

      if (response.data.status === 205) {
        handleOpenModal("error", response.data.error);
        setLoading(false);
        return;
      }

      await handleArticleInfo(response.data, values.controlNo);
    } catch (error) {
      handleOpenModal("error", "Scanned item not found!");
    } finally {
      setLoading(false);
    }
  };

  const handleArticleInfo = async (data: any, controlNo: string | number) => {
    if (data.loaded_activity_id === 20761) {
      if (
        ["faketag", "faketag2", "faketag3"].includes(data.tag_id) &&
        data.article_type === "material"
      ) {
        handleOpenModal("alert", "Chemical indicator detected!");
        return;
      }

      const result = await axios.get(
        `${API_URL}web-standalone/check-status-using-control-no/${controlNo}`,
        {
          headers: {
            Authorization: `Bearer ${getClientToken()} `,
          },
        }
      );

      await handleStatusCheck(result.data);
    } else {
      handleNonStandardStatus(data.loaded_activity_id, data);
    }
  };

  const handleStatusCheck = async (data: any) => {
    setLoadedId(data.loaded_id);
    setArticleId(data.id);
    const expiryDays = 180 - (data.load_finished_at % 365);
    if (data.status === 205) {
      handleOpenModal("alert", "This item is held for the B1 test result!");
    } else if (expiryDays < 1) {
      await discardExpiredItem(data);
    } else if (data.load_finished_at > 180) {
      handleOpenModal(
        "alert",
        "Found the required Information, but the item is sterilized for more than 6 months, please reprocess before using it again!"
      );
    } else {
      updateStatusData(data, expiryDays);
    }
  };

  const handleNonStandardStatus = (activityId: number, data: any) => {
    const messages: Record<number, string> = {
      19358: "Heads Up!!, this item supposed to be in autoclave",
      19359: "Item already used. Please reprocess before use!",
      19407:
        data.failed_reason ||
        data.removal_reason ||
        "Item is not sterilized, reprocess before use!",
    };

    handleOpenModal(
      "alert",
      captializeFirstWords(messages[activityId]) ||
        "Item is not sterilized, reprocess before use!"
    );
  };

  const discardExpiredItem = async (data: any) => {
    await axios.post(
      `${API_URL}web-standalone/discard-item/${data.control_no}`,
      {
        removal_reason: "item expired",
        loaded_id: data.loaded_id,
        article_status: "ready_to_process",
        latest_activity: 19407,
        article_id: data.id,
      },
      {
        headers: {
          Authorization: `Bearer ${getClientToken()} `,
        },
      }
    );

    handleOpenModal(
      "alert",
      "This item is expired and has been discarded, please reprocess before using it again!"
    );
  };

  const updateStatusData = (data: any, expiryDays: number) => {
    setStatusData({
      itemName: data.type,
      totalCycles: data.total_cycles,
      processedDate: new Date(data.processed_date).toLocaleDateString(),
      sterilizer: data.autoclave_name,
      certifiedBy: data.certified_by,
      cycleNo: data.cycle_no,
      status: "Available",
      expiry: expiryDays,
      controlNo: data.control_no,
      errorColor: false,
      showStatus: true,
    });
  };

  const handleConfirmEmailSend = () => {
    setDiscardDialogOpen(false);
    callDiscardArticle();
  };

  const callDiscardArticle = async () => {
    try {
      const response = await axios.post(
        `${API_URL}web-standalone/discard-item/${controlNo}`,
        {
          removal_reason: removalReason,
          loaded_id: loadedId,
          article_status: "ready_to_process",
          latest_activity: 19407,
          article_id: articleId,
        },
        {
          headers: {
            Authorization: `Bearer ${getClientToken()} `,
          },
        }
      );

      if (response.data.status === 205) {
        handleOpenModal("error", "Something went wrong!");
        return;
      }

      clearData();
      handleOpenModal("success", "The item has been discarded!");
    } catch (error) {
      handleOpenModal("error", "Something went wrong!");
    }
  };

  const captializeFirstWords = (sentence: string) => {
    if (!sentence) return sentence;
    return sentence.charAt(0).toUpperCase() + sentence.slice(1);
  };

  const clearData = () => {
    setStatusData((prevStatusData) => ({
      ...prevStatusData,
      showStatus: false,
    }));
  };

  return (
    <>
      <div>
        <ModalComponent />
        <DynamicForm
          initialValues={checkStatusInitialValues}
          validationSchema={checkStatusValidationSchema}
          fields={checkStatusFields}
          formTitle="Check Status"
          onSubmit={handleSubmit}
        />
        {statusData.showStatus && (
          <Grid container justifyContent="center">
            <Grid item lg={8} sm={12} textAlign="center">
              <Typography variant="h4" className="jFontBold" mt={3} mb={1}>
                Status
              </Typography>
              <Typography variant="subtitle1" className="jFontBold" mb={2}>
                (Please scan one item at a time)
              </Typography>
              <Paper elevation={3} sx={{ padding: 3 }}>
                <Grid container spacing={3}>
                  <Grid item md={6}>
                    <Typography variant="h6" fontWeight="bold">
                      Item Name
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{ fontWeight: "bold", color: "#052CA3" }}
                    >
                      {statusData.itemName}
                    </Typography>
                  </Grid>
                  <Grid item md={6}>
                    <Typography variant="h6" fontWeight="bold">
                      Expiry Days
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{ fontWeight: "bold", color: "#052CA3" }}
                    >
                      {statusData.expiry}
                    </Typography>
                  </Grid>
                  <Grid item md={6}>
                    <Typography variant="h6" fontWeight="bold">
                      Processed
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{ fontWeight: "bold", color: "#052CA3" }}
                    >
                      {statusData.processedDate}
                    </Typography>
                  </Grid>
                  <Grid item md={6}>
                    <Typography variant="h6" fontWeight="bold">
                      Sterilizer
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{ fontWeight: "bold", color: "#052CA3" }}
                    >
                      {statusData.sterilizer}
                    </Typography>
                  </Grid>
                  <Grid item md={6}>
                    <Typography variant="h6" fontWeight="bold">
                      Certified By
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{ fontWeight: "bold", color: "#052CA3" }}
                    >
                      {statusData.certifiedBy}
                    </Typography>
                  </Grid>
                  <Grid item md={6}>
                    <Typography variant="h6" fontWeight="bold">
                      Cycle #
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{ fontWeight: "bold", color: "#052CA3" }}
                    >
                      {statusData.cycleNo}
                    </Typography>
                  </Grid>
                  <Grid item md={4}>
                    <Typography variant="h6" fontWeight="bold">
                      Status
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: "bold",
                        color: statusData.errorColor ? "red" : "green",
                      }}
                    >
                      {statusData.status}
                    </Typography>
                  </Grid>
                  <Grid item md={4}>
                    {/* {rfidEnabledValue === 1 && (
                      <>
                        <Typography variant="h6" fontWeight="bold">
                          Total Cycles
                        </Typography>
                        <Typography
                          variant="body1"
                          sx={{ fontWeight: "bold", color: "#052CA3" }}
                        >
                          {statusData.totalCycles}
                        </Typography>
                      </>
                    )} */}
                  </Grid>
                  <Grid item md={4}>
                    <Typography variant="h6" fontWeight="bold">
                      Control No.
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{ fontWeight: "bold", color: "#052CA3" }}
                    >
                      {statusData.controlNo}
                    </Typography>
                  </Grid>
                </Grid>
                <Box mt={3}>
                  <Button
                    variant="contained"
                    color="error"
                    sx={{ m: 1 }}
                    onClick={() => setDiscardDialogOpen(true)}
                  >
                    Discard Item
                  </Button>
                  <Button
                    variant="contained"
                    color="success"
                    sx={{ m: 1 }}
                    onClick={clearData}
                  >
                    Done
                  </Button>
                </Box>
              </Paper>
              {loading && (
                <Box display="flex" justifyContent="center" mt={3}>
                  <CircularProgress color="primary" size={70} />
                </Box>
              )}
            </Grid>
          </Grid>
        )}
      </div>
      {/* Discard Dialog */}
      <Dialog
        open={discardDialogOpen}
        onClose={() => setDiscardDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Enter Removal Reason</DialogTitle>
        <DialogContent style={{ backgroundColor: "#eff9f0" }}>
          <TextField
            fullWidth
            label="Removal Reason"
            variant="standard"
            onChange={(e) => setRemovalReason(e.target.value)}
          />
        </DialogContent>
        <DialogActions style={{ backgroundColor: "#eff9f0" }}>
          <Button onClick={() => setDiscardDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmEmailSend} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CheckStatus;
