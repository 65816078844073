import { Select, MenuItem, SelectChangeEvent } from "@mui/material";
import styles from "./SelectBranchMenu.module.css";
import FetchBranches from "../../utils/FetchBranches";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../stores/store";
import { setBranch } from "../../actions/branchActions";
import { useNavigate } from "react-router-dom";

const SelectBranchMenu = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { branchData } = FetchBranches();
  const currentBranch = useSelector(
    (state: RootState) => state.branch.currentBranch
  );

  const handleChange = (event: SelectChangeEvent<string>) => {
    dispatch(setBranch(event.target.value as string));
    navigate(0);
  };

  const isValidBranch = branchData.some(
    (branch) => branch.branch_id.toString() === currentBranch
  );

  return (
    <div className={styles.container}>
      <Select
        size="small"
        labelId="selectBranch-label"
        id="selectBranch"
        value={isValidBranch ? currentBranch : ""}
        onChange={handleChange}
        className={styles.select}
        displayEmpty
        renderValue={(selected) => {
          if (selected === "") {
            return "All Branches";
          }
          const foundBranch = branchData.find(
            (branch) => branch.branch_id.toString() === selected
          );
          return foundBranch ? foundBranch.branch_name : selected;
        }}
      >
        <MenuItem value="">All Branches</MenuItem>
        {branchData.map((branch) => (
          <MenuItem key={branch.branch_id} value={branch.branch_id.toString()}>
            {branch.branch_name}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default SelectBranchMenu;
