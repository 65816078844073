import * as React from "react";
import { useEffect, useState } from "react";
import { Button, Menu, MenuItem, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../stores/store";

const RegisterMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const clientInfo = useSelector((state: RootState) => state.client.clientInfo);
  const rfidEnabled = Number(
    useSelector((state: RootState) => state.auth.rfidEnabled)
  );
  const [roleId, setRoleId] = useState<number | null>(null);

  useEffect(() => {
    if (clientInfo) {
      setRoleId(Number(clientInfo[0].role_id));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const registrationOptions = [
    { path: "/register-article", label: "Register Article" },
    // { path: "/register-article-set", label: "Register Article Set" },
    { path: "/register-article-type", label: "Register Article Type" },
    { path: "/register-new-autoclave", label: "Register New Autoclave" },
    { path: "/register-autoclave", label: "Assign Autoclave" },
    {
      path: "/register-branch",
      label: "Register Branch",
      condition: roleId === 1,
    },
    { path: "/register-bilot", label: "Register BI LOT" },
    {
      path: "/register-reader",
      label: "Register Reader",
      condition: rfidEnabled === 1,
    },
    { path: "/register-user", label: "Register User", condition: roleId === 1 },
  ];

  return (
    <>
      <Button
        className="nav-item dropdown"
        style={{ zIndex: 1, position: "relative", color: "white" }}
        aria-controls="register-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        Register
      </Button>
      <Menu
        id="register-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {registrationOptions.map(
          (option, index) =>
            option.condition !== false && (
              <MenuItem key={index} onClick={handleClose}>
                <Link
                  to={option.path}
                  className="dropdown-item"
                  style={{ textDecoration: "none" }}
                >
                  <Typography style={{ color: "black" }}>
                    {option.label}
                  </Typography>
                </Link>
              </MenuItem>
            )
        )}
      </Menu>
    </>
  );
};

export default RegisterMenu;
