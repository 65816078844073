import { useLocation } from "react-router-dom";
import { LocationDetails } from "../interface/RouterInterface";

const useExtractionLocation = (): LocationDetails => {
    const location = useLocation();
    const { pathname } = location;

    return { location, pathname };
}

export default useExtractionLocation;