import { useState, useEffect } from "react";
import DynamicTable from "../../components/table/DynamicTable";
import { TableActions, Column, Data } from "../../interface/TableInterface";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import ContainedButton from "../../components/button/ContainedButton";
import useSessionData from "../../hooks/useSessionData";
import useBranches from "../../hooks/useBranches"; // Import useBranches hook
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { RootState } from "../../stores/store";
import { ReaderList } from "../../interface/responseInterface/ReaderInterface";
import { useMessageModal } from "../../hooks/useMessageModal";

const columns: Column[] = [
  { id: "id", label: "#", numeric: false },
  { id: "name", label: "Name", numeric: false },
  { id: "branch_name", label: "Branch Name", numeric: false },
  { id: "current_status", label: "Status", numeric: false },
];

const Readers = () => {
  const { getClientToken, getBaseURL } = useSessionData();
  const API_URL = getBaseURL();
  const { handleOpenModal, ModalComponent } = useMessageModal();
  const { branchOptions } = useBranches();
  const [response, setResponse] = useState<Data[]>([]);
  const [responseData, setResponseData] = useState<ReaderList[]>([]);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedReader, setSelectedReader] = useState<ReaderList | null>(null);
  const [readerName, setReaderName] = useState<string>("");
  const [location, setLocation] = useState<string>("");
  const [branch, setBranch] = useState<string>("");
  const currentBranch = useSelector(
    (state: RootState) => state.branch.currentBranch
  );
  const navigate = useNavigate();

  useEffect(() => {
    const getReaderInformation = async () => {
      let branchQuery: string = `rc.branch_id=${currentBranch}`;
      try {
        const { data } = await axios.get<ReaderList[]>(
          `${API_URL}web-standalone/get-reader?${branchQuery}`,
          {
            headers: {
              Authorization: `Bearer ${getClientToken()}`,
            },
          }
        );

        const mappedData: Data[] = data.map((item, index) => ({
          id: item.loc_id,
          name: item.reader.toUpperCase(),
          branch_name: item.city,
          current_status: item.flag === 0 ? "Inactive" : "Active",
        }));
        setResponse(mappedData);
        setResponseData(data);
      } catch (error) {
        console.error("Error fetching service log:", error);
      }
    };

    getReaderInformation();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEdit = (id: number) => {
    const reader = responseData.find((reader) => reader.loc_id === id);
    if (reader) {
      setSelectedReader(reader);
      setReaderName(reader.reader);
      setLocation(reader.location);
      setBranch(reader.branch_name.toString());
      setOpenEditDialog(true);
    }
  };

  const handleDelete = (id: number) => {
    const reader = responseData.find((reader) => reader.loc_id === id);
    if (reader) {
      setSelectedReader(reader);
      setOpenDeleteDialog(true);
    }
  };

  const handleEditSubmit = async () => {
    if (selectedReader) {
      try {
        const response = await axios.put(
          `${API_URL}web-register/newreader`,
          {
            loc_id: selectedReader.loc_id,
            reader: readerName,
            location: location,
            branch_id: branch,
          },
          {
            headers: {
              Authorization: `Bearer ${getClientToken()}`,
            },
          }
        );
        if (response.status === 201) {
          setResponse((prev) =>
            prev.map((reader) =>
              reader.id === selectedReader.loc_id
                ? {
                    ...reader,
                    name: readerName.toUpperCase(),
                    branch_name: branch,
                  }
                : reader
            )
          );
          setResponseData((prev) =>
            prev.map((reader) =>
              reader.loc_id === selectedReader.loc_id
                ? {
                    ...reader,
                    name: readerName.toUpperCase(),
                    branch_name: branch,
                  }
                : reader
            )
          );
          setOpenEditDialog(false);
          handleOpenModal("success", "Successfully updated reader.");
        }
      } catch (error) {
        setOpenEditDialog(false);
        handleOpenModal("error", "Error updating reader.");
      }
    }
  };

  const handleDeleteSubmit = async () => {
    if (selectedReader) {
      try {
        const response = await axios.delete(
          `${API_URL}web-register/newreader`,
          {
            headers: {
              Authorization: `Bearer ${getClientToken()}`,
            },
            data: { loc_id: selectedReader.loc_id },
          }
        );
        if (response.status === 200) {
          setResponse((prev) =>
            prev.filter((reader) => reader.id !== selectedReader.loc_id)
          );
          setResponseData((prev) =>
            prev.filter((reader) => reader.loc_id !== selectedReader.loc_id)
          );
          setOpenDeleteDialog(false);
          handleOpenModal("success", "Successfully deleted reader.");
        }
      } catch (error) {
        setOpenDeleteDialog(false);
        handleOpenModal("error", "Error deleting reader.");
      }
    }
  };

  const rowActions: TableActions[] = response.map((row) => ({
    id: `edit_${row.id}`,
    label: "Edit",
    onClick: () => handleEdit(row.id),
  }));

  rowActions.push(
    ...response.map((row) => ({
      id: `delete_${row.id}`,
      label: "Delete",
      onClick: () => handleDelete(row.id),
    }))
  );

  const handleButtonClick = () => {
    navigate("/register-reader");
  };

  return (
    <>
      <h4>Readers</h4>
      <Box sx={{ display: "flex", justifyContent: "flex-end", m: 2 }}>
        <ContainedButton buttonTitle="Add" onButtonClick={handleButtonClick} />
      </Box>
      <ModalComponent />
      <DynamicTable headCells={columns} rows={response} actions={rowActions} />

      {/* Edit Reader Dialog */}
      <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)}>
        <DialogTitle>Edit Reader</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Reader Name"
            type="text"
            fullWidth
            value={readerName}
            onChange={(e) => setReaderName(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Location"
            type="text"
            fullWidth
            value={location}
            onChange={(e) => setLocation(e.target.value)}
          />
          <FormControl fullWidth margin="dense">
            <InputLabel>Branch</InputLabel>
            <Select
              value={branch}
              onChange={(e) => setBranch(e.target.value)}
              label="Branch"
            >
              {branchOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditSubmit} color="primary">
            Submit
          </Button>
          <Button onClick={() => setOpenEditDialog(false)} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Reader Dialog */}
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
      >
        <DialogTitle>Delete Reader</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this {readerName} reader?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteSubmit} color="primary">
            Yes
          </Button>
          <Button onClick={() => setOpenDeleteDialog(false)} color="secondary">
            No
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Readers;
