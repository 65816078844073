import React, { useState, useEffect } from "react";
import DynamicTable from "../../components/table/DynamicTable";
import { TableActions } from "../../interface/TableInterface";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  TextField,
  CircularProgress,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  SelectChangeEvent,
} from "@mui/material";
import ContainedButton from "../../components/button/ContainedButton";
import { Column, Data } from "../../interface/TableInterface";
import {
  UserInterface,
  UserEditInterface,
} from "../../interface/responseInterface/UserInterface";
import useSessionData from "../../hooks/useSessionData";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { RootState } from "../../stores/store";
import { useMessageModal } from "../../hooks/useMessageModal";
import useBranches from "../../hooks/useBranches";

const columns: Column[] = [
  { id: "id", label: "#", numeric: false },
  { id: "name", label: "Name", numeric: false },
  { id: "branch_name", label: "Branch Name", numeric: false },
  { id: "role", label: "Role", numeric: false },
  { id: "current_status", label: "Status", numeric: false },
];

const Users = () => {
  const { getClientToken, getBaseURL } = useSessionData();
  const API_URL = getBaseURL();
  const [response, setResponse] = useState<Data[]>([]);
  const [responseData, setResponseData] = useState<UserInterface[]>([]);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState<UserInterface | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { handleOpenModal, ModalComponent } = useMessageModal();
  const [userData, setUserData] = useState<UserEditInterface>({
    id: null,
    name: "",
    email: "",
    role_id: "",
    pin: 0,
    flag: "",
    branch_id: 0,
    city: "",
  });
  const currentBranch = useSelector(
    (state: RootState) => state.branch.currentBranch
  );
  const navigate = useNavigate();
  const { branchOptions } = useBranches();

  const getUserInformation = async () => {
    let branchQuery: string = `br.branch_id=${currentBranch}`;
    try {
      const { data } = await axios.get<UserInterface[]>(
        `${API_URL}user/companyinfo?${branchQuery}`,
        {
          headers: {
            Authorization: `Bearer ${getClientToken()}`,
          },
        }
      );
      setResponseData(data);

      const mappedData: Data[] = data.map((item, index) => ({
        id: item.id,
        name: item.name,
        branch_name: item.branch_name,
        role:
          item.role_id === 1
            ? "Admin"
            : item.role_id === 2
            ? "Supervisor"
            : "Staff Member",
        current_status: item.flag === 0 ? "Inactive" : "Active",
      }));

      setResponse(mappedData);
    } catch (error) {
      handleOpenModal("error", "Error fetching user list");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getUserInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEdit = (id: number) => {
    const user = responseData.find((user) => user.id === id);
    if (user) {
      setSelectedUser(user);
      setUserData({
        id: user.id,
        name: user.name,
        email: user.email,
        pin: user.pin,
        role_id: user.role_id,
        flag: user.flag,
        branch_id: user.branch_id,
        city: user.city,
      });
      setOpenEditDialog(true);
    }
  };

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<string | number>
  ) => {
    const { name, value } = e.target;

    setUserData((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handleDelete = (id: number) => {
    const user = responseData.find((user) => user.id === id);
    if (user) {
      setSelectedUser(user);
      setOpenDeleteDialog(true);
    }
  };

  const handleEditSubmit = async () => {
    if (selectedUser) {
      try {
        const response = await axios.put(
          `${API_URL}user/companyinfo`,
          {
            id: userData.id,
            name: userData.name,
            email: userData.email,
            pin: userData.pin,
            role_id: userData.role_id,
            flag: userData.flag,
            branch_id: userData.branch_id,
          },
          {
            headers: {
              Authorization: `Bearer ${getClientToken()}`,
            },
          }
        );
        if (response.status === 201) {
          setResponse((prev) =>
            prev.map((user) =>
              user.id === selectedUser.id
                ? {
                    ...user,
                    name: userData.name,
                    email: userData.email,
                    pin: userData.pin,
                  }
                : user
            )
          );
          setResponseData((prev) =>
            prev.map((user) =>
              user.id === selectedUser.id
                ? {
                    ...user,
                    name: userData.name,
                    email: userData.email,
                    pin: userData.pin,
                  }
                : user
            )
          );
          setOpenEditDialog(false);
          setSelectedUser(null);
          getUserInformation();
          handleOpenModal("success", "Successfully updated user information");
        }
      } catch (error) {
        handleOpenModal("error", "Error updating user");
      }
    }
  };

  const handleDeleteSubmit = async () => {
    if (selectedUser) {
      try {
        const response = await axios.delete(`${API_URL}user/companyinfo`, {
          headers: {
            Authorization: `Bearer ${getClientToken()}`,
          },
          data: { id: selectedUser.id },
        });
        if (response.status === 201) {
          setResponse((prev) =>
            prev.filter((user) => user.id !== selectedUser.id)
          );
          setOpenDeleteDialog(false);
          handleOpenModal("success", "Successfully deleted user");
        }
      } catch (error) {
        handleOpenModal("error", "Error deleting user");
      }
    }
  };

  const rowActions: TableActions[] = response.map((row) => ({
    id: `edit_${row.id}`,
    label: "Edit",
    onClick: () => handleEdit(row.id),
  }));

  response.forEach((row) => {
    rowActions.push({
      id: `delete_${row.id}`,
      label: "Delete",
      onClick: () => handleDelete(row.id),
    });
  });

  const handleButtonClick = () => {
    navigate("/register-user");
  };

  return (
    <>
      <h4>Users</h4>
      <Box sx={{ display: "flex", justifyContent: "flex-end", m: 2 }}>
        <ContainedButton buttonTitle="Add" onButtonClick={handleButtonClick} />
      </Box>
      <ModalComponent />
      {loading ? (
        <Box display="flex" justifyContent="center" mt={3}>
          <CircularProgress color="primary" size={50} />
        </Box>
      ) : (
        <DynamicTable
          headCells={columns}
          rows={response}
          actions={rowActions}
        />
      )}

      {/* Edit User Dialog */}
      <Dialog
        open={openEditDialog}
        onClose={() => setOpenEditDialog(false)}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>Edit User Information</DialogTitle>
        <DialogContent>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={6}>
              <TextField
                margin="dense"
                label="User Name"
                name="name"
                type="text"
                value={userData.name}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="dense"
                label="Email"
                name="email"
                type="email"
                value={userData.email}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl margin="dense" fullWidth>
                <InputLabel id="branch-label">Branch</InputLabel>
                <Select
                  labelId="branch-label"
                  id="branch"
                  name="branch_id"
                  value={userData.branch_id}
                  label="Branch"
                  onChange={handleChange}
                >
                  {branchOptions.map((item, index) => (
                    <MenuItem key={index} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl margin="dense" fullWidth>
                <InputLabel id="role-label">Role</InputLabel>
                <Select
                  labelId="role-label"
                  name="role_id"
                  id="role"
                  value={userData.role_id}
                  label="Role"
                  onChange={handleChange}
                >
                  <MenuItem value="">-- Select Role --</MenuItem>
                  <MenuItem key={1} value="1">
                    Admin
                  </MenuItem>
                  <MenuItem key={2} value="2">
                    Supervisor
                  </MenuItem>
                  <MenuItem key={3} value="3">
                    User
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="dense"
                label="Pin"
                name="pin"
                type="pin"
                value={userData.pin}
                onChange={handleChange}
                sx={{ flexGrow: 1 }}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl margin="dense" fullWidth>
                <InputLabel id="active-label">Active</InputLabel>
                <Select
                  labelId="active-label"
                  id="active"
                  name="flag"
                  value={userData.flag}
                  label="Active"
                  onChange={handleChange}
                >
                  <MenuItem value="">-- Select Active --</MenuItem>
                  <MenuItem key={1} value="1">
                    Yes
                  </MenuItem>
                  <MenuItem key={0} value="0">
                    No
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditSubmit} color="primary">
            Submit
          </Button>
          <Button onClick={() => setOpenEditDialog(false)} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete User Dialog */}
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
      >
        <DialogTitle>Delete User</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this user?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteSubmit} color="primary">
            Yes
          </Button>
          <Button onClick={() => setOpenDeleteDialog(false)} color="secondary">
            No
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Users;
