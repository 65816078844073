import { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Paper,
  Typography,
  Box,
} from "@mui/material";
import axios from "axios";
import { ViewAutoclavesCyclesProps } from "../../interface/responseInterface/AutoclaveInterface";
import useSessionData from "../../hooks/useSessionData";
import { useMessageModal } from "../../hooks/useMessageModal";

const ViewAutoclavesCycles = ({
  open,
  articleInfo,
  parentCallback,
}: ViewAutoclavesCyclesProps) => {
  const [cycleData, setCycleData] = useState<any[]>([]);
  const { getClientToken, getBaseURL } = useSessionData();
  const { handleOpenModal, ModalComponent } = useMessageModal();
  const API_URL = getBaseURL();

  useEffect(() => {
    if (open) {
      const getCycleInfo = async () => {
        try {
          const response = await axios.get(
            `${API_URL}/web-register/getCycleTypes/${articleInfo.autoclave_id}`,
            {
              headers: {
                Authorization: `Bearer ${getClientToken()}`,
              },
            }
          );
          setCycleData(response.data);
          
          if (response.status === 205) {
            handleOpenModal("error", response.data.message);
            return;
          }
        } catch (error) {
          handleOpenModal("error", "Something went wrong!");
        }
      };
      getCycleInfo();
    } else {
      setCycleData([]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleClose = () => {
    parentCallback(false);
  };

  return (
    <>
      <ModalComponent />
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {articleInfo.name}
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: "#eff9f0" }}>
          {cycleData.length > 0 ? (
            cycleData.map((items, index) => (
              <Box key={index} mb={2}>
                <Typography variant="h5" align="center" gutterBottom>
                  {items.cycle_name}
                </Typography>
                <Box display="flex" justifyContent="space-around">
                  <Paper sx={{ textAlign: "center", padding: 2 }}>
                    <Typography variant="body1" fontWeight="bold">
                      Temperature
                    </Typography>
                    <Typography>{items.temprature_celcius}</Typography>
                  </Paper>
                  <Paper sx={{ textAlign: "center", padding: 2 }}>
                    <Typography variant="body1" fontWeight="bold">
                      Pressure
                    </Typography>
                    <Typography>{items.pressure}</Typography>
                  </Paper>
                  <Paper sx={{ textAlign: "center", padding: 2 }}>
                    <Typography variant="body1" fontWeight="bold">
                      Duration
                    </Typography>
                    <Typography>{items.duration}</Typography>
                  </Paper>
                  <Paper sx={{ textAlign: "center", padding: 2 }}>
                    <Typography variant="body1" fontWeight="bold">
                      Drying Duration
                    </Typography>
                    <Typography>{items.drying_duration}</Typography>
                  </Paper>
                </Box>
              </Box>
            ))
          ) : (
            <Typography variant="body1" align="center">
              No cycle data available.
            </Typography>
          )}
        </DialogContent>
        <DialogActions sx={{ backgroundColor: "#eff9f0" }}>
          <Button onClick={handleClose} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ViewAutoclavesCycles;
