import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import useSessionData from "../../hooks/useSessionData";

interface ImageDialogProps {
  open: boolean;
  onClose: () => void;
  imageURL?: string | null;
}

const ImageDialog = ({ open, onClose, imageURL }: ImageDialogProps) => {

  const { getBaseURL } = useSessionData();
  const API_URL = getBaseURL();
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <DialogTitle>Image</DialogTitle>
      <DialogContent>
        {imageURL ? (
          <img
            src={API_URL + `images/` + imageURL}
            alt="biological_record"
            width="500px"
            height="500px"
          />
        ) : (
          "No Image!"
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" type="button">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImageDialog;
