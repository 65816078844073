import { useEffect, useState } from "react";
import axios from "axios";
import useSessionData from "./useSessionData";
import { BranchResponse } from "../interface/responseInterface/BranchResponseInterface";
import { useSelector } from "react-redux";
import { RootState } from "../stores/store";

interface BranchOption {
  value: string;
  label: string;
}

const useBranches = () => {
  const [branchOptions, setBranchOptions] = useState<BranchOption[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const { getClientToken, getBaseURL } = useSessionData();
  const API_URL = getBaseURL();
  const currentBranch = useSelector(
    (state: RootState) => state.branch.currentBranch
  );
  const clientInfo = useSelector((state: RootState) => state.client.clientInfo);
  const [roleId, setRoleId] = useState<number | null>(null);

  useEffect(() => {
    // Set roleId when clientInfo is available
    if (clientInfo && clientInfo.length > 0) {
      setRoleId(Number(clientInfo[0].role_id));
    }
  }, [clientInfo]);

  useEffect(() => {
    const fetchBranches = async () => {
      if (roleId === null) return; // Wait for roleId to be set

      setLoading(true);
      try {
        const response = await axios.get(
          `${API_URL}/web-standalone/get-branch`,
          {
            headers: {
              Authorization: `Bearer ${getClientToken()}`,
            },
          }
        );

        let branches: BranchOption[] =
          response.data.length > 0
            ? [
                { value: "0", label: "-- Select Branch --" },
                ...response.data.map((branch: BranchResponse) => ({
                  value: branch.branch_id.toString(),
                  label: branch.branch_name,
                })),
              ]
            : [{ value: "0", label: "-- No Branches Available --" }];

        if (roleId !== 1) {
          branches = branches.filter(
            (item) =>
              Number(item.value) === Number(currentBranch) || item.value === "0"
          );
        }

        setBranchOptions(branches);
      } catch (error) {
        setError("Failed to fetch branches");
      } finally {
        setLoading(false);
      }
    };

    fetchBranches();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleId, currentBranch]);

  return { branchOptions, loading, error };
};

export default useBranches;
