import { useEffect, useState } from "react";
import axios from "axios";
import {
  Container,
  Grid,
  Paper,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@mui/material";
import Brightness1Icon from "@mui/icons-material/Brightness1";
import useSessionData from "../../hooks/useSessionData";
import {
  GetArticlesPerLoadProps,
  ItemListType,
} from "../../interface/responseInterface/AutoclaveLoadInterface";

const GetArticlesPerLoad = ({ id, info, onClose }: GetArticlesPerLoadProps) => {
  const { getClientToken, getBaseURL } = useSessionData();
  const API_URL = getBaseURL();
  const [itemList, setItemList] = useState<ItemListType[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [imageURL, setImageURL] = useState<string | null>();

  useEffect(() => {
    const fetchItemList = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/web-reports/itemslist/${id}`,
          {
            headers: {
              Authorization: `Bearer ${getClientToken()}`,
            },
          }
        );

        setItemList(response.data);
      } catch (error) {
        console.error("Error fetching items:", error);
      }
    };

    fetchItemList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setImageToShow = (url: string) => {
    setImageURL(url);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setImageURL(null);
  };

  return (
    <>
      <DialogContent dividers style={{ backgroundColor: "#eff9f0" }}>
        <Container>
          <Typography variant="h5" align="center" gutterBottom>
            Autoclave Details
          </Typography>
          <Divider sx={{ marginBottom: 3 }} />
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <Paper elevation={3} sx={{ padding: 2, textAlign: "center" }}>
                <Typography
                  variant="subtitle2"
                  fontWeight="bold"
                  color="primary"
                >
                  Autoclave Name
                </Typography>
                <Typography variant="subtitle2">{info.Autoclave}</Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Paper elevation={3} sx={{ padding: 2, textAlign: "center" }}>
                <Typography
                  variant="subtitle2"
                  fontWeight="bold"
                  color="primary"
                >
                  Cycle No
                </Typography>
                <Typography variant="subtitle2">{info.cycle_no}</Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Paper elevation={3} sx={{ padding: 2, textAlign: "center" }}>
                <Typography
                  variant="subtitle2"
                  fontWeight="bold"
                  color="primary"
                >
                  Load Date
                </Typography>
                <Typography variant="subtitle2">
                  {info.load_started_at
                    ? new Date(info.load_started_at).toLocaleDateString()
                    : "Not Finished"}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Paper elevation={3} sx={{ padding: 2, textAlign: "center" }}>
                <Typography
                  variant="subtitle2"
                  fontWeight="bold"
                  color="primary"
                >
                  Load By
                </Typography>
                <Typography variant="subtitle2">
                  {info.started_by || "_"}
                </Typography>
              </Paper>
            </Grid>
          </Grid>

          <Typography variant="subtitle1" align="center" sx={{ marginTop: 4 }}>
            Chemical Indicators
          </Typography>
          <Divider sx={{ marginBottom: 3 }} />
          <Grid container spacing={3} justifyContent="center">
            {info.type5_result !== null && (
              <Grid item xs={12} sm={6} md={3}>
                <Paper elevation={3} sx={{ padding: 2, textAlign: "center" }}>
                  <Typography variant="subtitle2" color="secondary">
                    {info.type_id === 1
                      ? "Type 4"
                      : info.type_id === 2
                      ? "Type 5"
                      : info.type_id === 3
                      ? "Type 6"
                      : ""}{" "}
                    <Brightness1Icon
                      sx={{
                        color: info.type5_result === 1 ? "green" : "red",
                        fontSize: "medium",
                      }}
                    />
                  </Typography>
                  <Typography variant="subtitle2" color="primary">
                    By: {info.type5_result_by?.split(" ")[0]}
                  </Typography>
                  {info.type5_image_url === null ? (
                    " No Image"
                  ) : (
                    <Button
                      variant="text"
                      onClick={() => setImageToShow(info.type5_image_url)}
                      sx={{ marginTop: 1 }}
                    >
                      View Photo
                    </Button>
                  )}
                </Paper>
              </Grid>
            )}
            {info.bi_result !== null && (
              <Grid item xs={12} sm={6} md={3}>
                <Paper elevation={3} sx={{ padding: 2, textAlign: "center" }}>
                  <Typography variant="subtitle2" color="secondary">
                    BI{" "}
                    <Brightness1Icon
                      sx={{
                        color: info.bi_result === 1 ? "green" : "red",
                        fontSize: "medium",
                      }}
                    />
                  </Typography>
                  <Typography variant="subtitle2" color="primary">
                    By: {info.bi_result_by?.split(" ")[0]}
                  </Typography>
                  {info.bi_image_url === null ? (
                    " No Image"
                  ) : (
                    <Button
                      variant="text"
                      onClick={() => setImageToShow(info.bi_image_url)}
                      sx={{ marginTop: 1 }}
                    >
                      View Photo
                    </Button>
                  )}
                </Paper>
              </Grid>
            )}
            {info.bowiedick_result !== null && (
              <Grid item xs={12} sm={6} md={3}>
                <Paper elevation={3} sx={{ padding: 2, textAlign: "center" }}>
                  <Typography variant="subtitle2" color="secondary">
                    BI{" "}
                    <Brightness1Icon
                      sx={{
                        color: info.bowiedick_result === 1 ? "green" : "red",
                        fontSize: "medium",
                      }}
                    />
                  </Typography>
                  <Typography variant="subtitle2" color="primary">
                    By: {info.c2_loaded_by?.split(" ")[0]}
                  </Typography>
                  {info.bowiedick_image_url === null ? (
                    " No Image"
                  ) : (
                    <Button
                      variant="text"
                      onClick={() => setImageToShow(info.bowiedick_image_url)}
                      sx={{ marginTop: 1 }}
                    >
                      View Photo
                    </Button>
                  )}
                </Paper>
              </Grid>
            )}
          </Grid>

          <Typography variant="subtitle1" align="center" sx={{ marginTop: 4 }}>
            Autoclave Parameters
          </Typography>
          <Divider sx={{ marginBottom: 3 }} />
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} sm={6} md={3}>
              <Paper elevation={3} sx={{ padding: 2, textAlign: "center" }}>
                <Typography
                  variant="subtitle2"
                  fontWeight="bold"
                  color="secondary"
                >
                  Temperature
                </Typography>
                <Typography variant="subtitle2">
                  {info.temprature || "N/A"}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Paper elevation={3} sx={{ padding: 2, textAlign: "center" }}>
                <Typography
                  variant="subtitle2"
                  fontWeight="bold"
                  color="secondary"
                >
                  Pressure
                </Typography>
                <Typography variant="subtitle2">
                  {info.pressure || "N/A"}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Paper elevation={3} sx={{ padding: 2, textAlign: "center" }}>
                <Typography
                  variant="subtitle2"
                  fontWeight="bold"
                  color="secondary"
                >
                  Duration
                </Typography>
                <Typography variant="subtitle2">
                  {info.duration || "N/A"}
                </Typography>
              </Paper>
            </Grid>
          </Grid>

          <Typography variant="subtitle1" align="center" sx={{ marginTop: 4 }}>
            Articles
          </Typography>
          <Grid container alignItems="center">
            <Grid item xs={12}>
              <Divider sx={{ marginBottom: 3 }} />
              <div className="aritclesList">
                {itemList &&
                  itemList.map((item, index) => (
                    <Paper
                      key={index}
                      elevation={3}
                      sx={{ padding: 2, marginBottom: 2 }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <Typography
                            variant="subtitle2"
                            fontWeight="bold"
                            color="textSecondary"
                          >
                            Type
                          </Typography>
                          <Typography variant="body2" fontWeight={"bold"}>
                            {item.Name === "class-5" ? "class-4" : item.Name}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography
                            variant="subtitle2"
                            fontWeight="bold"
                            color="textSecondary"
                          >
                            Control No
                          </Typography>
                          <Typography variant="body2" fontWeight={"bold"}>
                            {item.control_no || "N/A"}
                          </Typography>
                        </Grid>
                        <Grid item xs={4} style={{ textAlign: "right" }}>
                          <Typography
                            variant="subtitle2"
                            fontWeight="bold"
                            color="textSecondary"
                          >
                            Status
                          </Typography>
                          <Typography
                            variant="body2"
                            style={{
                              fontWeight: "bold",
                              color:
                                item.Name !== "CLASS-5" &&
                                item.Name !== "CLASS-2" &&
                                !item.Name.startsWith("BIOLOGICAL-INDICATOR")
                                  ? item.current_status === "load-removed"
                                    ? "#ff9800" // Warning (orange)
                                    : item.is_used === "yes"
                                    ? "#3f51b5" // Primary (blue)
                                    : item.is_failed === "yes"
                                    ? "red" // Failed (red)
                                    : "green" // Available (green)
                                  : "transparent",
                              padding: "0.2rem 0.5rem",
                              borderRadius: "4px",
                            }}
                          >
                            {item.Name !== "CLASS-5" &&
                            item.Name !== "CLASS-2" &&
                            !item.Name.startsWith("BIOLOGICAL-INDICATOR")
                              ? item.current_status === "load-removed"
                                ? "Removed"
                                : item.is_used === "yes"
                                ? "Used"
                                : item.is_failed === "yes"
                                ? "Failed"
                                : "Available"
                              : ""}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Paper>
                  ))}
              </div>
            </Grid>
          </Grid>
        </Container>
      </DialogContent>

      <DialogActions style={{ backgroundColor: "#eff9f0" }}>
        <Button onClick={() => onClose()} color="primary" variant="contained">
          Close
        </Button>
      </DialogActions>
      {/* Image Dialog */}
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        disableEnforceFocus
        disableAutoFocus
        aria-labelledby="image-dialog-title"
      >
        <DialogTitle>Image</DialogTitle>
        <DialogContent>
          {imageURL ? (
            <img
              src={API_URL + `images/` + imageURL}
              alt="Autoclave"
              // style={{ width: "100%", height: "auto" }}
              width="500px"
              height="500px"
            />
          ) : (
            "No Image!"
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GetArticlesPerLoad;
