import React from "react";
import { Grid, IconButton, MenuItem, Box, TextField } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  StyledCardForAutoclave,
  StyledForm,
  StyledFormControl,
  StyledButton,
  StyledTypography,
} from "../../components/styles/Styles";
import { CycleType } from "../../interface/responseInterface/AutoclaveLoadInterface";
import useSessionData from "../../hooks/useSessionData";
import axios from "axios";
import { useMessageModal } from "../../hooks/useMessageModal";
import { useFormik, FormikProvider, FieldArray } from "formik";
import * as Yup from "yup";

export default function AutoclaveRegistration() {
  const { getClientToken, getBaseURL } = useSessionData();
  const API_URL = getBaseURL();
  const { handleOpenModal, ModalComponent } = useMessageModal();

  const initialValues = {
    autoclaveDetails: {
      autoclave_name: "",
      autoclave_opname: "",
      autoclave_manufacturer: "",
      autoclave_model: "",
      serial_no: "",
      bowie_dick: "",
    },
    inputFields: [
      {
        cycleName: "",
        temperature: "",
        pressure: "",
        duration: "",
        dryingDuration: "",
        unit: "",
      },
    ],
  };

  const validationSchema = Yup.object().shape({
    autoclaveDetails: Yup.object().shape({
      autoclave_name: Yup.string().required("Autoclave name is required."),
      autoclave_opname: Yup.string(),
      autoclave_manufacturer: Yup.string().required(
        "Manufacturer is required."
      ),
      autoclave_model: Yup.string().required("Model is requried."),
      serial_no: Yup.string().required("Serial number is required."),
      bowie_dick: Yup.string().required(
        "Please select if it is bowie-dick autoclave"
      ),
    }),
    inputFields: Yup.array().of(
      Yup.object().shape({
        cycleName: Yup.string().required("Cycle name is required"),
        temperature: Yup.string().required("Temperature is required"),
        pressure: Yup.string().required("Pressure is required"),
        duration: Yup.string().required("Duration is required"),
        dryingDuration: Yup.string().required("Drying duration is requried"),
        unit: Yup.string().required("Unit is required"),
      })
    ),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        const response = await axios.post(
          `${API_URL}web-register/newautoclave`,
          {
            autoclave_name: values.autoclaveDetails.autoclave_name,
            autoclave_model: values.autoclaveDetails.autoclave_model,
            autoclave_manufacturer:
              values.autoclaveDetails.autoclave_manufacturer,
            serial_no: values.autoclaveDetails.serial_no || "null",
            bowie_dick: values.autoclaveDetails.bowie_dick,
            autoclave_opname: values.autoclaveDetails.autoclave_opname,
            cycle_name: values.inputFields.map((item) => item.cycleName),
            temperature_celcius: values.inputFields.map(
              (item) => item.temperature
            ),
            pressure: values.inputFields.map(
              (item) => `${item.pressure} ${item.unit}`
            ),
            duration: values.inputFields.map((item) => item.duration),
            drying_duration: values.inputFields.map(
              (item) => item.dryingDuration
            ),
          },
          {
            headers: {
              Authorization: `Bearer ${getClientToken()}`,
            },
          }
        );

        if (response.data.status === 201) {
          handleOpenModal("error", "Error while registering autoclave.");
        } else {
          handleOpenModal("success", "Successfully registered autoclave.");
          resetForm();
        }
      } catch (error) {
        handleOpenModal("error", "Error while registering autoclave.");
      }
    },
  });

  return (
    <>
      <ModalComponent />
      <StyledCardForAutoclave>
        <StyledTypography variant="h5" gutterBottom>
          Register Autoclave
        </StyledTypography>
        <FormikProvider value={formik}>
          <StyledForm autoComplete="off" onSubmit={formik.handleSubmit}>
            <Grid container spacing={3}>
              <InputField
                label="Autoclave Name"
                name="autoclaveDetails.autoclave_name"
                value={formik.values.autoclaveDetails.autoclave_name}
                onChange={formik.handleChange}
                error={formik.errors.autoclaveDetails?.autoclave_name}
                touched={formik.touched.autoclaveDetails?.autoclave_name}
              />
              <InputField
                label="Autoclave Optional Name"
                name="autoclaveDetails.autoclave_opname"
                value={formik.values.autoclaveDetails.autoclave_opname}
                onChange={formik.handleChange}
                error={formik.errors.autoclaveDetails?.autoclave_opname}
                touched={formik.touched.autoclaveDetails?.autoclave_opname}
              />
              <InputField
                label="Manufacturer"
                name="autoclaveDetails.autoclave_manufacturer"
                value={formik.values.autoclaveDetails.autoclave_manufacturer}
                onChange={formik.handleChange}
                error={formik.errors.autoclaveDetails?.autoclave_manufacturer}
                touched={
                  formik.touched.autoclaveDetails?.autoclave_manufacturer
                }
              />
              <InputField
                label="Model"
                name="autoclaveDetails.autoclave_model"
                value={formik.values.autoclaveDetails.autoclave_model}
                onChange={formik.handleChange}
                error={formik.errors.autoclaveDetails?.autoclave_model}
                touched={formik.touched.autoclaveDetails?.autoclave_model}
              />
              <InputField
                label="Serial Number"
                name="autoclaveDetails.serial_no"
                value={formik.values.autoclaveDetails.serial_no}
                onChange={formik.handleChange}
                error={formik.errors.autoclaveDetails?.serial_no}
                touched={formik.touched.autoclaveDetails?.serial_no}
              />
              <SelectField
                label="Is Bowie-Dick Autoclave?"
                name="autoclaveDetails.bowie_dick"
                value={formik.values.autoclaveDetails.bowie_dick}
                onChange={formik.handleChange}
                options={["Yes", "No"]}
              />
            </Grid>

            <StyledTypography variant="h6" sx={{ mt: 6, mb: 3 }}>
              Cycle Type
              <p style={{ fontSize: "14px", color: "red" }}>
                (Please enter first cycle as initial cycle!)
              </p>
            </StyledTypography>

            <FieldArray name="inputFields">
              {({ push, remove }) => (
                <>
                  {formik.values.inputFields.map((field, index) => (
                    <Grid container spacing={5} key={index} marginBottom={3}>
                      <CycleTypeFields
                        field={field}
                        index={index}
                        handleChange={formik.handleChange}
                        removeCycleField={() => remove(index)}
                        totalFields={formik.values.inputFields.length}
                      />
                    </Grid>
                  ))}
                  <Box sx={{ mt: 4 }}>
                    <StyledButton
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        push({
                          cycleName: "",
                          temperature: "",
                          pressure: "",
                          duration: "",
                          dryingDuration: "",
                          unit: "",
                        })
                      }
                    >
                      Add Cycle
                    </StyledButton>
                  </Box>
                </>
              )}
            </FieldArray>

            <Box sx={{ mt: 4 }}>
              <StyledButton type="submit" variant="contained" color="primary">
                Register Autoclave
              </StyledButton>
            </Box>
          </StyledForm>
        </FormikProvider>
      </StyledCardForAutoclave>
    </>
  );
}

// Reusable Components
function InputField({
  label,
  name,
  value,
  onChange,
  error,
  touched,
}: {
  label: string;
  name: string;
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  error?: string;
  touched?: boolean;
}) {
  return (
    <Grid item xs={12} md={6}>
      <StyledFormControl fullWidth>
        <TextField
          fullWidth
          label={label}
          name={name}
          value={value}
          onChange={onChange}
          variant="outlined"
          size="small"
          error={touched && Boolean(error)}
          helperText={touched && error}
        />
      </StyledFormControl>
    </Grid>
  );
}

function SelectField({
  label,
  name,
  value,
  onChange,
  options,
}: {
  label: string;
  name: string;
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  options: string[];
}) {
  return (
    <Grid item xs={12} md={6}>
      <StyledFormControl fullWidth>
        <TextField
          select
          fullWidth
          label={label}
          name={name}
          value={value}
          onChange={onChange}
          variant="outlined"
          size="small"
        >
          {options.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
      </StyledFormControl>
    </Grid>
  );
}

function CycleTypeFields({
  field,
  index,
  handleChange,
  removeCycleField,
  totalFields,
}: {
  field: CycleType;
  index: number;
  handleChange: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  >;
  removeCycleField: (index: number) => void;
  totalFields: number;
}) {
  return (
    <>
      {totalFields > 1 && (
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center">
            <StyledTypography variant="body1">
              Cycle Type {index + 1}
            </StyledTypography>
            <IconButton
              color="error"
              onClick={() => removeCycleField(index)}
              aria-label="remove cycle"
              sx={{ marginTop: -1 }}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        </Grid>
      )}
      <Grid item xs={12} sm={6} md={4}>
        <StyledFormControl fullWidth>
          <TextField
            fullWidth
            label="Cycle Name"
            name={`inputFields[${index}].cycleName`}
            value={field.cycleName}
            onChange={handleChange}
            variant="outlined"
            size="small"
            required
          />
        </StyledFormControl>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <StyledFormControl fullWidth>
          <TextField
            fullWidth
            label="Temperature (°C)"
            name={`inputFields[${index}].temperature`}
            value={field.temperature}
            onChange={handleChange}
            variant="outlined"
            size="small"
            required
          />
        </StyledFormControl>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <StyledFormControl fullWidth>
          <TextField
            fullWidth
            label="Pressure"
            name={`inputFields[${index}].pressure`}
            value={field.pressure}
            onChange={handleChange}
            variant="outlined"
            size="small"
            required
          />
        </StyledFormControl>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <StyledFormControl fullWidth>
          <TextField
            fullWidth
            label="Duration"
            name={`inputFields[${index}].duration`}
            value={field.duration}
            onChange={handleChange}
            variant="outlined"
            size="small"
            required
          />
        </StyledFormControl>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <StyledFormControl fullWidth>
          <TextField
            fullWidth
            label="Drying Duration"
            name={`inputFields[${index}].dryingDuration`}
            value={field.dryingDuration}
            onChange={handleChange}
            variant="outlined"
            size="small"
            required
          />
        </StyledFormControl>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <StyledFormControl fullWidth>
          <TextField
            fullWidth
            label="Unit"
            name={`inputFields[${index}].unit`}
            value={field.unit}
            onChange={handleChange}
            variant="outlined"
            size="small"
            required
          />
        </StyledFormControl>
      </Grid>
    </>
  );
}
