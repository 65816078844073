import DynamicTable from "../../components/table/DynamicTable";
import { ServiceLogResponse } from "../../interface/responseInterface/ServiceLogInterface";
import axios from "axios";
import { useEffect, useState } from "react";
import useSessionData from "../../hooks/useSessionData";
import { Column, Data } from "../../interface/TableInterface";
import { useSelector } from "react-redux";
import { RootState } from "../../stores/store";

const columns: Column[] = [
  { id: "id", label: "#", numeric: false },
  { id: "name", label: "Name", numeric: false },
  { id: "oos_reason", label: "OOS Reason", numeric: false },
  { id: "oos_by", label: "OOS By", numeric: false },
  { id: "oos_date", label: "OOS Date", numeric: false },
  { id: "bis_reason", label: "BIS Reason", numeric: false },
  { id: "bis_by", label: "BIS By", numeric: false },
  { id: "bis_date", label: "BIS Date", numeric: false },
];

const ServiceLog = () => {
  const { getClientToken, getBaseURL } = useSessionData();
  const API_URL = getBaseURL();
  const [response, setResponse] = useState<Data[]>([]);
  const currentBranch = useSelector(
    (state: RootState) => state.branch.currentBranch
  );

  useEffect(() => {
    const getServiceLog = async () => {
      let branchQuery: string = `st.branch_id=${currentBranch}`;
      try {
        const { data } = await axios.get<ServiceLogResponse[]>(
          `${API_URL}/web-reports/getservicelog?${branchQuery}`,
          {
            headers: {
              Authorization: `Bearer ${getClientToken()}`,
            },
          }
        );

        const mappedData: Data[] = data.map((item) => ({
          id: item.id,
          name: item.name,
          oos_reason: item.oos_reason,
          oos_by: item.oos_by,
          oos_date: item.oos_date.toString().split("T")[0],
          bis_reason: item.bis_reason,
          bis_by: item.bis_by,
          bis_date:
            typeof item.bis_date === "string"
              ? item.bis_date.toString().split("T")[0]
              : "",
        }));

        setResponse(mappedData);
      } catch (error) {
        console.error("Error fetching service log:", error);
      }
    };

    getServiceLog();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <h4>Autoclave Service Logs</h4>
      <DynamicTable headCells={columns} rows={response} />
    </>
  );
};

export default ServiceLog;
