import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import DynamicTable from "../../components/table/DynamicTable";
import UsedArticleInfoDialog from "./UsedArticleInfoDialog";
import useSessionData from "../../hooks/useSessionData";
import { Column, Data, TableActions } from "../../interface/TableInterface";
import { UsedArticleResponseInterface } from "../../interface/responseInterface/UsedArticleInterface";
import { RootState } from "../../stores/store";
import { UsedArticleInterface } from "../../interface/responseInterface/UsedArticleInterface";
import { useMessageModal } from "../../hooks/useMessageModal";

const columns: Column[] = [
  { id: "id", label: "#", numeric: false },
  { id: "article_name", label: "Article Name", numeric: false },
  { id: "control_no", label: "Control Number", numeric: false },
  { id: "used_by", label: "Used By", numeric: false },
  { id: "used_date", label: "Used Date", numeric: false },
];

const UsedArticles = () => {
  const { getClientToken, getBaseURL } = useSessionData();
  const API_URL = getBaseURL();
  const { handleOpenModal, ModalComponent } = useMessageModal();
  const [response, setResponse] = useState<Data[]>([]);
  const [responseData, setResponseData] = useState<UsedArticleInterface[]>([]);
  const [selectedArticle, setSelectedArticle] =
    useState<UsedArticleInterface | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const currentBranch = useSelector(
    (state: RootState) => state.branch.currentBranch
  );

  useEffect(() => {
    const getServiceLog = async () => {
      const branchQuery: string = `ap.branch_id=${currentBranch}`;
      try {
        const { data } = await axios.get<UsedArticleResponseInterface>(
          `${API_URL}web-reports/usedarticlelog?${branchQuery}`,
          {
            headers: {
              Authorization: `Bearer ${getClientToken()}`,
            },
          }
        );
        setResponseData(data.res);
        const mappedData: Data[] = data.res.map((item, index) => ({
          id: item.id,
          article_name: item.type,
          control_no: item.control_no,
          used_by: item.used_by,
          used_date: item.date_used.split("T")[0],
        }));
        setResponse(mappedData);
      } catch (error) {
        handleOpenModal("error", "Something went wrong while fetching data!");
        return;
      }
    };

    getServiceLog();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleViewDetails = (index: string) => {
    // Access the article from responseData using the index
    const article = responseData.find((item) => item.id.toString() === index);
    if (article) {
      setSelectedArticle(article); // Set the selected article to state
      setDialogOpen(true); // Open the dialog
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedArticle(null);
  };

  const rowActions: TableActions[] = responseData.map((row) => ({
    id: `view_${row.id}`,
    label: "View Details",
    onClick: () => handleViewDetails(row.id.toString()),
  }));

  return (
    <>
      <h4>Used Articles</h4>
      <ModalComponent />
      {/* <Box sx={{ display: "flex", justifyContent: "flex-end", m: 2 }}>
        <ContainedButton
          buttonTitle="Export"
          onButtonClick={handleExportButtonClick}
        />
      </Box> */}
      <DynamicTable headCells={columns} rows={response} actions={rowActions} />
      {selectedArticle && (
        <UsedArticleInfoDialog
          open={dialogOpen}
          articleInfo={selectedArticle}
          parentCallback={handleCloseDialog}
        />
      )}
    </>
  );
};

export default UsedArticles;
