import * as React from "react";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import Box from "@mui/material/Box";
import { visuallyHidden } from "@mui/utils";
import { Column, EnhancedTableHeadProps } from "../../interface/TableInterface";

const EnhancedTableHead = ({
  headCells,
  order,
  orderBy,
  onRequestSort,
  onSelectAllClick,
  rowCount,
  selected,
  rowActionsLength,
}: EnhancedTableHeadProps) => {
  const createSortHandler = (property: keyof Column) => {
    return (event: React.MouseEvent<unknown>) => {
      onRequestSort(property);
    };
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            indeterminate={selected.length > 0 && selected.length < rowCount}
            checked={rowCount > 0 && selected.length === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id as string}
            align={headCell.numeric ? "right" : "left"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id as keyof Column)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        {(rowActionsLength || 0) > 0 && (
          <TableCell align="center">Actions</TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};

export default EnhancedTableHead;
