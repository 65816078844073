import { useState } from "react";
import DynamicForm, { FieldConfig } from "../../components/forms/DynamicForm";
import { UseInstrumentInterface } from "../../interface/responseInterface/UseInstrumentInterface";
import * as Yup from "yup";
import FetchBranches from "../../utils/FetchBranches";
import { BranchResponse } from "../../interface/responseInterface/BranchResponseInterface";
import axios from "axios";
import { FormikHelpers } from "formik";
import useSessionData from "../../hooks/useSessionData";
import { useMessageModal } from "../../hooks/useMessageModal";
import useArticleImage from "../../assets/images/used-articles.png";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSelector } from "react-redux";
import { RootState } from "../../stores/store";

interface InstrumentData {
  articleId: string;
  autoclaveIds: string;
  controlNo: string;
  itemName: string;
  tagId: string;
}

const UseInstrument = () => {
  const { branchData } = FetchBranches();
  const { getClientToken, getBaseURL } = useSessionData();
  const { handleOpenModal, ModalComponent } = useMessageModal();
  const API_URL = getBaseURL();
  const [instrumentData, setInstrumentData] = useState<InstrumentData[]>([]);
  const [status, setStatus] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [branchId, setBranchId] = useState<string>("");
  const currentBranch = useSelector(
    (state: RootState) => state.branch.currentBranch
  );

  const branches: { value: string; label: string }[] =
    branchData.length > 0
      ? [
          { value: "0", label: "-- Select Branch --" },
          ...branchData.map((branch: BranchResponse) => ({
            value: branch.branch_id.toString(),
            label: branch.branch_name,
          })),
        ]
      : [{ value: "0", label: "-- No Branches Available --" }];

  const useInstrumentInitialValues: UseInstrumentInterface = {
    controlNo: "",
    branch_id: currentBranch ? currentBranch : "0", // Set currentBranch as default if available
  };

  const useInstrumetValidationSchema: Yup.ObjectSchema<UseInstrumentInterface> =
    Yup.object().shape({
      controlNo: Yup.string().required("Control Number is required"),
      branch_id: Yup.string().required("Branch is required"),
    });

  const useInstrumentFields: FieldConfig[] = [
    {
      name: "controlNo",
      label: "Enter Control Number",
      type: "text",
      fullWidth: true,
    },
    {
      name: "branch_id",
      label: "Select Branch *",
      type: "select",
      options: branches,
      fullWidth: true,
      onChange: (e) => setBranchId(e.target.value),
    },
  ];

  const handleSubmit = async (
    values: UseInstrumentInterface,
    { resetForm }: FormikHelpers<UseInstrumentInterface>
  ) => {
    setLoading(true);
    setBranchId(values.branch_id);

    try {
      const response = await axios.get(
        `${API_URL}web-standalone/get-article-info-using-controlno/${values.controlNo}/${values.branch_id}`,
        {
          headers: {
            Authorization: `Bearer ${getClientToken()}`,
          },
        }
      );

      if (response.data.status === 205) {
        handleOpenModal("error", response.data.error);
        return;
      }

      await handleArticleInfo(response.data, values.controlNo);
    } catch (error) {
      handleOpenModal("error", "Scanned item not found!");
    } finally {
      resetForm();
      setLoading(false);
    }
  };

  const handleArticleInfo = async (data: any, controlNo: string | number) => {
    if (data.loaded_activity_id === 20761) {
      if (
        ["faketag", "faketag2", "faketag3"].includes(data.tag_id) &&
        data.article_type === "material"
      ) {
        handleOpenModal("alert", "Chemical indicator detected!");
        return;
      }

      const result = await axios.get(
        `${API_URL}web-standalone/check-status-using-control-no/${controlNo}`,
        {
          headers: {
            Authorization: `Bearer ${getClientToken()}`,
          },
        }
      );

      await handleStatusCheck(result.data, data);
    } else {
      handleNonStandardStatus(data.loaded_activity_id, data);
    }
  };

  const handleStatusCheck = async (result: any, data: any) => {
    const expiryDays = 180 - (result.load_finished_at % 365);
    if (result.status === 205) {
      handleOpenModal("alert", "This item is held for the B1 test result!");
    } else if (expiryDays < 1) {
      await discardExpiredItem(result, data);
    } else if (result.load_finished_at > 180) {
      setStatus("Expired!");
      handleOpenModal(
        "alert",
        "Found the required Information, but the item is sterilized for more than 30 days, please reprocess before using it again!"
      );
    } else {
      setStatus("Certified");
      setInstrumentData((prevData) => [
        ...prevData,
        {
          controlNo: result.control_no,
          itemName: data.type,
          autoclaveIds: result.process_id,
          tagId: result.article_tag_id,
          articleId: result.id,
        },
      ]);
    }
  };

  const handleNonStandardStatus = (activityId: number, data: any) => {
    const messages: Record<number, string> = {
      19358: "Heads Up!!, this item supposed to be in autoclave",
      19359: "Item already used. Please reprocess before use!",
      19407:
        data.failed_reason ||
        data.removal_reason ||
        "Item is not sterilized, reprocess before use!",
    };

    handleOpenModal(
      "alert",
      captializeFirstWords(messages[activityId]) ||
        "Item is not sterilized, reprocess before use!"
    );
  };

  const discardExpiredItem = async (result: any, data: any) => {
    await axios.post(
      `${API_URL}web-standalone/discard-item/${result.control_no}`,
      {
        removal_reason: "item expired",
        loaded_id: result.loaded_id,
        article_status: "ready_to_process",
        latest_activity: 19407,
        article_id: result.id,
      },
      {
        headers: {
          Authorization: `Bearer ${getClientToken()}`,
        },
      }
    );

    handleOpenModal(
      "error",
      "This item is expired and has been discarded, please reprocess before using it again!"
    );
  };

  const confirmUse = async () => {
    const articleIds = instrumentData.map((item) => item.articleId);
    const autoclaveIds = instrumentData.map((item) => item.autoclaveIds);
    const controlNos = instrumentData.map((item) => item.controlNo);
    const tagIds = instrumentData.map((item) => item.tagId);

    try {
      const response = await axios.post(
        `${API_URL}web-standalone/instrument-item/confirm-use`,
        {
          article_ids: articleIds,
          articleTagIds: tagIds,
          processIds: autoclaveIds,
          controlNos: controlNos,
          article_status: "ready_to_process",
          latest_activity: 19359,
          readerLocation: 1,
          branch_id: branchId,
        },
        {
          headers: {
            Authorization: `Bearer ${getClientToken()}`,
          },
        }
      );

      if (response.data.status === 205) {
        handleOpenModal("error", "Something went wrong");
        return;
      }

      handleOpenModal("success", "Instrument(s) are in use!");
    } catch (error) {
      handleOpenModal("error", "Something went wrong");
    } finally {
      clearInstrumentData();
    }
  };

  const removeItem = (data: InstrumentData) => {
    const newInstrumentList = instrumentData.filter(
      (item) => item.controlNo !== data.controlNo
    );
    setInstrumentData(newInstrumentList);
  };

  const captializeFirstWords = (sentence: string) => {
    if (!sentence) return sentence;
    return sentence.charAt(0).toUpperCase() + sentence.slice(1);
  };

  const clearInstrumentData = () => {
    setInstrumentData([]);
  };

  return (
    <div>
      <ModalComponent />
      <DynamicForm
        initialValues={useInstrumentInitialValues}
        validationSchema={useInstrumetValidationSchema}
        fields={useInstrumentFields}
        formTitle="Use Instrument"
        onSubmit={handleSubmit}
      />
      {loading ? (
        <Box display="flex" justifyContent="center" mt={3}>
          <CircularProgress color="primary" size={70} />
        </Box>
      ) : (
        instrumentData.length > 0 && (
          <Grid container justifyContent="center" mt={3}>
            <Grid item md={6} sm={12} textAlign="center">
              <Typography variant="h4" className="jFontBold" mt={3} mb={3}>
                Status
              </Typography>
              <Paper elevation={3} sx={{ padding: 3 }}>
                <List>
                  {instrumentData.map((item, index) => (
                    <ListItem key={index} sx={{ alignItems: "center" }}>
                      <ListItemAvatar>
                        <Avatar>
                          <img
                            src={useArticleImage}
                            alt=""
                            style={{
                              backgroundColor: "#F1F1F1",
                              width: "40px",
                              height: "40px",
                            }}
                          />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={`Control# ${item.controlNo}`}
                        primaryTypographyProps={{
                          style: { fontWeight: "bold", fontSize: "24px" },
                        }}
                        secondary={item.itemName}
                        secondaryTypographyProps={{
                          style: {
                            fontWeight: "bold",
                            fontSize: "16px",
                            color: "black",
                          },
                        }}
                        sx={{ flexGrow: 1 }}
                      />
                      <Typography
                        variant="body1"
                        sx={{
                          fontWeight: "bold",
                          color: status === "Expired!" ? "red" : "green",
                          marginRight: 2,
                        }}
                      >
                        {status}
                      </Typography>
                      <Button
                        aria-label="delete"
                        style={{ color: "#b22222" }}
                        onClick={() => removeItem(item)}
                      >
                        <DeleteIcon />
                      </Button>
                    </ListItem>
                  ))}
                </List>
              </Paper>
              <Box mt={3}>
                <Button
                  variant="contained"
                  color="success"
                  sx={{ m: 1 }}
                  onClick={confirmUse}
                >
                  Confirm Use
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  sx={{ m: 1 }}
                  onClick={clearInstrumentData}
                >
                  Clear
                </Button>
              </Box>
            </Grid>
          </Grid>
        )
      )}
    </div>
  );
};

export default UseInstrument;
