import React from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { appRoutes } from "../../routes/AppRoutes";
import { RouterConfig } from "../../interface/RouterInterface";

const findRouteByPath = (
  path: string,
  routes: RouterConfig[]
): RouterConfig | undefined => {
  return routes.find((route) => route.path === path);
};

const Breadcrumb = () => {
  const { path: urlPath } = useParams<{ path: string }>();
  const pathnames = urlPath ? urlPath.split("/") : [];

  const createBreadcrumb = () => {
    let breadcrumbs: React.ReactNode[] = [];

    pathnames.forEach((path, index) => {
      const route = findRouteByPath(`/${path}`, appRoutes); // Ensure path starts with '/'
      if (route) {
        breadcrumbs.push(
          <Link
            key={route.path}
            color="inherit"
            component={RouterLink}
            to={route.path}
          >
            {route.name}
          </Link>
        );
      } else if (index === pathnames.length - 1) {
        breadcrumbs.push(
          <Typography key={path} color="text.primary">
            {path}
          </Typography>
        );
      }
    });

    return breadcrumbs;
  };

  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
    >
      {createBreadcrumb()}
    </Breadcrumbs>
  );
};

export default Breadcrumb;
