import { Button, Card, FormControl, Typography, Chip } from "@mui/material";
import { styled } from "@mui/material/styles";
// Styled components
export const StyledCard = styled(Card)({
  maxWidth: 600,
  padding: "16px",
  margin: "auto",
  marginTop: "50px",
  marginBottom: "50px",
});

export const StyledCardForAutoclave = styled(Card)({
  maxWidth: 800,
  padding: "16px",
  margin: "auto",
  marginTop: "50px",
  marginBottom: "50px",
});

export const StyledForm = styled("form")({
  width: "100%",
});

export const StyledFormControl = styled(FormControl)({
  minWidth: 200,
  width: "100%",
  // marginBottom: "8px",
});

export const StyledButton = styled(Button)({
  marginTop: "4px",
});

export const StyledTypography = styled(Typography)({
  textAlign: "center",
  // margin: "10px",
});

export const FormStyledTypography = styled(Typography)({
  textAlign: "left",
  // margin: "10px",
});

export const StyledChip = styled(Chip)({
  marginTop: "8px",
});
