import ModalForm from "../../components/modalForm/ModalForm";
import { ModalFormData } from "../../interface/ModalFormInterface";

const PrintLable = () => {
  const handleFormSubmit = (formData: ModalFormData) => {
    console.log("Form Data", formData);
  };
  const fields = [
    { name: "control-number", label: "Enter Control Number" },
    { name: "branch", label: "Branch" },
  ];
  return (
    <div>
      <ModalForm
        title="Print Label"
        fields={fields}
        onSubmit={handleFormSubmit}
      />
    </div>
  );
};

export default PrintLable;
