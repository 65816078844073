import { useEffect, useState } from "react";
import DynamicForm, { FieldConfig } from "../../components/forms/DynamicForm";
import * as Yup from "yup";
import {
  ArticleTypeFormValues,
  ArticleTypeResponse,
} from "../../interface/responseInterface/ArticleInterface";
import useSessionData from "../../hooks/useSessionData";
import { FormikHelpers } from "formik";
import axios from "axios";
import { useMessageModal } from "../../hooks/useMessageModal";

const articleTypeInitialValues: ArticleTypeFormValues = {
  articleCode: "",
  articleType: "",
  articleManufacture: "",
};

const articleTypeValidationSchema: Yup.ObjectSchema<ArticleTypeFormValues> =
  Yup.object().shape({
    articleCode: Yup.string().required("Code is required"),
    articleType: Yup.string().required("Type is required"),
    articleManufacture: Yup.string().required("Manufacture is required"),
  });

const RegisterArticleType = () => {
  const { getClientToken, getBaseURL } = useSessionData();
  const API_URL = getBaseURL();
  const { handleOpenModal, ModalComponent } = useMessageModal();

  type OptionsType = { value: string; label: string };

  const [articleCodeOptions, setArticleCodeOptions] = useState<OptionsType[]>(
    []
  );
  const [articleTypeOptions, setArticleTypeOptions] = useState<OptionsType[]>(
    []
  );
  const [articleManufacturerOptions, setArticleManufacturerOptions] = useState<
    OptionsType[]
  >([]);
  const [formKey, setFormKey] = useState(0); // Add state for form key

  useEffect(() => {
    const getAllArticleTypes = async () => {
      try {
        const { data } = await axios.get<ArticleTypeResponse[]>(
          `${API_URL}web-standalone/get-article-types`,
          {
            headers: {
              Authorization: `Bearer ${getClientToken()}`,
            },
          }
        );

        const uniqueCodes = Array.from(
          new Set(data.map((item) => item.code))
        ).map((code) => ({
          value: code,
          label: code,
        }));

        const uniqueTypes = Array.from(
          new Set(data.map((item) => item.type))
        ).map((type) => ({
          value: type,
          label: type,
        }));

        const uniqueManufacturers = Array.from(
          new Set(data.map((item) => item.manufacturer))
        ).map((manufacturer) => ({
          value: manufacturer,
          label: manufacturer,
        }));

        setArticleCodeOptions(uniqueCodes);
        setArticleTypeOptions(uniqueTypes);
        setArticleManufacturerOptions(uniqueManufacturers);
      } catch (error) {
        console.error("Error fetching service log:", error);
      }
    };

    getAllArticleTypes();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const articleTypeFields: FieldConfig[] = [
    {
      name: "articleCode",
      label: "Code *",
      type: "autocomplete",
      options: [{ value: "", label: "-- Select --" }, ...articleCodeOptions],
      fullWidth: true,
    },
    {
      name: "articleType",
      label: "Type *",
      type: "autocomplete",
      options: [{ value: "", label: "-- Select --" }, ...articleTypeOptions],
      fullWidth: true,
    },
    {
      name: "articleManufacture",
      label: "Manufacture *",
      type: "autocomplete",
      options: [
        { value: "", label: "-- Select --" },
        ...articleManufacturerOptions,
      ],
      fullWidth: true,
    },
  ];

  const handleSubmit = async (
    values: ArticleTypeFormValues,
    { resetForm }: FormikHelpers<ArticleTypeFormValues>
  ) => {
    try {
      const response = await axios.post(
        `${API_URL}web-standalone/register-type`,
        {
          type: values.articleType,
          code: values.articleCode,
          manufacturer: values.articleManufacture,
        },
        {
          headers: {
            Authorization: `Bearer ${getClientToken()}`,
          },
        }
      );

      if (response.status === 201) {
        handleOpenModal("success", "Article type registered successfully");
        setFormKey(formKey + 1);
      } else {
        handleOpenModal("error", "Error while registering the article type.");
      }
    } catch (error) {
      handleOpenModal("success", "Error while registering the article type.");
    } finally {
      resetForm();
    }
  };

  return (
    <div>
      <ModalComponent />
      <DynamicForm
        key={formKey}
        initialValues={articleTypeInitialValues}
        validationSchema={articleTypeValidationSchema}
        fields={articleTypeFields}
        formTitle="Register Article Type"
        onSubmit={handleSubmit}
      />
    </div>
  );
};

export default RegisterArticleType;
