import React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

interface SummaryCardProps {
  title: string;
  value: number | string;
  component?: React.ReactNode;
}

const SummaryCardContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  margin: theme.spacing(1),
  flexGrow: 1,
  paddingBottom: theme.spacing(2),
}));

const SummaryCardPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  width: "100%", // Fixed width for all cards
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
}));

export const SummaryCard: React.FC<SummaryCardProps> = ({
  title,
  value,
  component,
}) => {
  return (
    <SummaryCardContainer>
      <SummaryCardPaper elevation={2}>
        <Typography color="textSecondary" variant="h6" gutterBottom>
          {title}
        </Typography>
        {component || (
          <Typography color="primary" variant="h4">
            {value}
          </Typography>
        )}
      </SummaryCardPaper>
    </SummaryCardContainer>
  );
};

export default SummaryCard;
