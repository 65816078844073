import "./App.css";
import "./assets/css/globalStyle.css";
import "./assets/css/main-style.css";
import AppRoutes from "./routes/AppRoutes";
import Box from "@mui/material/Box";
import Header from "./layouts/header/Header";
import Sidebar from "./layouts/sidebar/Sidebar";
import { Toolbar } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./utils/theme";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Breadcrumb from "./layouts/breadcrumb/Breadcrumb";
import { AuthProvider } from "./utils/AuthContext";
import LoginRoutes from "./routes/LoginRoutes";
import { useSelector } from "react-redux";
import { RootState } from "./stores/store";

const App = () => {
  return (
    <Router>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <Routes>
            <Route path="/*" element={<AuthenticatedApp />} />
            <Route path="/login/*" element={<LoginRoutes />} />
          </Routes>
        </ThemeProvider>
      </AuthProvider>
    </Router>
  );
};

const AuthenticatedApp = () => {
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAuthenticate
  );
  const drawerWidth = 240;

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }
 

  return (
    <Box sx={{ display: "flex" }}>
      <Header drawerWidth={drawerWidth} />
      <Sidebar drawerWidth={drawerWidth} />
      <Breadcrumb />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <AppRoutes />
      </Box>
    </Box>
  );
};

export default App;
