import React, { useEffect, useState } from "react";
import DynamicTable from "../../components/table/DynamicTable";
import { BiFailedReportResponse } from "../../interface/responseInterface/BiFailedReportInterface";
import axios from "axios";
import useSessionData from "../../hooks/useSessionData";
import { Column, Data, TableActions } from "../../interface/TableInterface";
import { useSelector } from "react-redux";
import { RootState } from "../../stores/store";
import FailedBIReportDialog from "./FailedBIReportDialog";
import { useMessageModal } from "../../hooks/useMessageModal";

const columns: Column[] = [
  { id: "id", label: "#", numeric: false },
  { id: "name", label: "Sterilizer", numeric: false },
  {
    id: "cycle_start",
    label: "Cycle No",
    numeric: true,
  },
  {
    id: "callback_date",
    label: "Callback Date",
    numeric: true,
  },
];

const BiResult = () => {
  const { getClientToken, getBaseURL } = useSessionData();
  const API_URL = getBaseURL();
  const { handleOpenModal, ModalComponent } = useMessageModal();
  const [response, setResponse] = useState<Data[]>([]);
  const [responseData, setResponseData] = useState<BiFailedReportResponse[]>(
    []
  );
  const [selectedArticle, setSelectedArticle] =
    useState<BiFailedReportResponse | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const currentBranch = useSelector(
    (state: RootState) => state.branch.currentBranch
  );

  useEffect(() => {
    const getFailedBiList = async () => {
      let branchQuery: string = `st.branch_id=${currentBranch}`;

      try {
        const response = await axios.get<BiFailedReportResponse[]>(
          `${API_URL}/web-reports/failedbilist?${branchQuery}`,
          {
            headers: {
              Authorization: `Bearer ${getClientToken()}`,
            },
          }
        );

        setResponseData(response.data);

        const mapData: Data[] = response.data.map((item) => {
          return {
            id: item.id,
            name: item.name,
            cycle_start: item.cycle_start,
            callback_date: item.callback_date.split("T")[0],
          };
        });

        setResponse(mapData);
      } catch (error) {
        handleOpenModal("error", "Error fetching BI list.");
      }
    };

    getFailedBiList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleViewDetails = (id: number) => {
    const article = responseData.find((item) => item.id === id);
    if (article) {
      setSelectedArticle(article as BiFailedReportResponse);
      setDialogOpen(true);
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedArticle(null);
  };

  const rowActions: TableActions[] = response.map((row) => ({
    id: `view_${row.id}`,
    label: "View Details",
    onClick: () => handleViewDetails(row.id),
  }));

  return (
    <>
      <h4>BI Callbacks</h4>
      <ModalComponent />
      <DynamicTable headCells={columns} rows={response} actions={rowActions} />

      {selectedArticle && (
        <FailedBIReportDialog
          open={dialogOpen}
          articleInfo={selectedArticle}
          parentCallback={handleCloseDialog}
        />
      )}
    </>
  );
};

export default BiResult;
