import { useState, useMemo, useEffect } from "react";
import DynamicForm, { FieldConfig } from "../../components/forms/DynamicForm";
import * as Yup from "yup";
import {
  ArticleFormValues,
  ArticleTypeResponse,
} from "../../interface/responseInterface/ArticleInterface";
import useSessionData from "../../hooks/useSessionData";
import { FormikHelpers } from "formik";
import axios from "axios";
import useBranches from "../../hooks/useBranches";
import { useMessageModal } from "../../hooks/useMessageModal";

interface ArticleListOption {
  value: string;
  label: string;
}

const RegisterArticle = () => {
  const { getClientToken, getBaseURL } = useSessionData();
  const [articleTypeList, SetArticleTypeList] = useState<ArticleListOption[]>(
    []
  );
  const API_URL = getBaseURL();
  const { branchOptions } = useBranches();
  const { handleOpenModal, ModalComponent } = useMessageModal();

  const getArticleTypes = useMemo(
    () => async () => {
      try {
        const { data } = await axios.get<ArticleTypeResponse[]>(
          `${getBaseURL()}web-standalone/get-article-types`,
          {
            headers: {
              Authorization: `Bearer ${getClientToken()}`,
            },
          }
        );

        const articleList =
          data.length > 0
            ? [
                { value: "0", label: "-- Select Article Type --" },
                ...data.map((articleType: ArticleTypeResponse) => ({
                  value:
                    articleType.id.toString() +
                    "~" +
                    articleType.type +
                    "~" +
                    articleType.code +
                    "~" +
                    articleType.manufacturer,
                  label:
                    articleType.type +
                    "~" +
                    articleType.code +
                    "~" +
                    articleType.manufacturer,
                })),
              ]
            : [{ value: "0", label: "-- No Article Type Available --" }];

        SetArticleTypeList(articleList);
      } catch (error) {
        handleOpenModal("error", "Error fetching article type");
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    getArticleTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const articleInitialValues: ArticleFormValues = {
    scannedTag: "",
    articleType: "0",
    articleCategory: "0",
    lotNumber: 0,
    articleActive: "2",
    branchName: "0",
    additionalInfo: "",
  };

  const articleValidationSchema: Yup.ObjectSchema<ArticleFormValues> =
    Yup.object().shape({
      scannedTag: Yup.string().required("Scanned Tag is required."),
      articleType: Yup.string().required("Article Type is required"),
      articleCategory: Yup.string().required("Article Category is required"),
      lotNumber: Yup.number().required("Lot Number is required"),
      articleActive: Yup.string().required("Article Active is required."),
      branchName: Yup.string().required("Branch is required"),
      additionalInfo: Yup.string(),
    });

  const articleFields: FieldConfig[] = [
    {
      name: "scannedTag",
      label: "Scanned Tags *",
      type: "text",
      fullWidth: true,
    },
    {
      name: "articleType",
      label: "Article Type *",
      type: "select",
      options: articleTypeList,
      fullWidth: true,
    },
    {
      name: "articleCategory",
      label: "Article Category *",
      type: "select",
      options: [
        { value: "0", label: "-- Select Article Category --" },
        { value: "Instrument", label: "Instrument" },
        { value: "Material", label: "Material" },
        { value: "Container", label: "Container" },
      ],
    },
    { name: "lotNumber", label: "Lot Number *", type: "text" },
    {
      name: "articleActive",
      label: "Article Active *",
      type: "select",
      options: [
        { value: "2", label: "-- Select --" },
        { value: "1", label: "Yes" },
        { value: "0", label: "No" },
      ],
    },
    {
      name: "branchName",
      label: "Select Branch *",
      type: "select",
      options: branchOptions,
    },
    {
      name: "additionalInfo",
      label: "Additional Information",
      type: "text",
      fullWidth: true,
    },
  ];

  const handleSubmit = async (
    values: ArticleFormValues,
    { resetForm }: FormikHelpers<ArticleFormValues>
  ) => {
    try {
      const articleValues = values.articleType.split("~");
      const response = await axios.post(
        `${API_URL}web-standalone/register-article`,
        {
          article_type: values.articleCategory,
          tag_id: [values.scannedTag],
          manufacturer: articleValues[3],
          code: articleValues[2],
          type: articleValues[1],
          lot: values.lotNumber?.toString(),
          active: values.articleActive,
          branch_id: values.branchName,
          additional_info: values.additionalInfo,
          life_cycle: "reusable",
        },
        {
          headers: {
            Authorization: `Bearer ${getClientToken()} `,
          },
        }
      );
      if (response.status !== 201) {
        handleOpenModal(
          "error",
          "Registration Failed. Please check your fields!"
        );
        return;
      }
      handleOpenModal("success", "Successfully registered article type!");
    } catch (error) {
      handleOpenModal(
        "error",
        "Registration Failed. Please check your fields!"
      );
    }
  };
  return (
    <div>
      <ModalComponent />
      <DynamicForm
        initialValues={articleInitialValues}
        validationSchema={articleValidationSchema}
        fields={articleFields}
        formTitle="Register Article"
        onSubmit={handleSubmit}
      />
    </div>
  );
};

export default RegisterArticle;
