/* eslint-disable */
import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import CancelIcon from "@mui/icons-material/Cancel";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

interface DateSelectorProps {
  parentCallback?: (date: string) => void;
}

const DateSelector: React.FC<DateSelectorProps> = (props?) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const handleDateChange = (date: Date | null) => {
    // if (date) {
    //   const dateFormat = `${date.getFullYear()}-${
    //     date.getMonth() + 1
    //   }-${date.getDate()}`;
    //   setSelectedDate(date);
    //   props.parentCallback(dateFormat);
    // } else {
    //   setSelectedDate(null);
    //   props.parentCallback("");
    // }
  };

  return (
    <div className="row justify-content-md-center">
      <div className="col-md-7">
        {selectedDate && (
          <Box
            sx={{
              position: "absolute",
              right: 0,
              top: 0,
              marginTop: "8px",
            }}
          >
            <CancelIcon
              onClick={() => handleDateChange(null)}
              sx={{
                color: "#e76f51",
                width: "20px",
                marginTop: "12px",
                cursor: "pointer",
              }}
            />
          </Box>
        )}
      </div>
    </div>
  );
};

export default DateSelector;
