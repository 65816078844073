import React from "react";
import { TextField, Button, Box } from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

interface Step1Props {
  onNext: (data: { firstName: string; lastName: string }) => void;
}

const StepOne: React.FC<Step1Props> = ({ onNext }) => {
  const validationSchema = Yup.object({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
  });

  return (
    <Formik
      initialValues={{ firstName: "", lastName: "" }}
      validationSchema={validationSchema}
      onSubmit={(values) => onNext(values)}
    >
      {({ errors, touched }) => (
        <Form>
          <Box mb={2}>
            <Field
              as={TextField}
              name="firstName"
              label="First Name"
              error={touched.firstName && Boolean(errors.firstName)}
              helperText={touched.firstName && errors.firstName}
              fullWidth
            />
          </Box>
          <Box mb={2}>
            <Field
              as={TextField}
              name="lastName"
              label="Last Name"
              error={touched.lastName && Boolean(errors.lastName)}
              helperText={touched.lastName && errors.lastName}
              fullWidth
            />
          </Box>
          <Button type="submit" variant="contained" color="primary">
            Next
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default StepOne;
