import * as React from "react";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import EnhancedTableHead from "./EnhancedTableHead";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import {
  Data,
  EnhancedTableProps,
  Order,
} from "../../interface/TableInterface";
import { stableSort, getComparator } from "./tableUtils";
import { Button } from "@mui/material";

const DynamicTable = ({
  rows,
  headCells,
  actions = [],
}: EnhancedTableProps) => {
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>(headCells[0].id);
  const [selected, setSelected] = React.useState<number[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (property: keyof Data) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((_, index) => index);
      setSelected(newSelecteds);
    } else {
      setSelected([]);
    }
  };

  const handleClick = (event: React.MouseEvent<unknown>, index: number) => {
    const selectedIndex = selected.indexOf(index);
    let newSelected: number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, index);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (index: number) => selected.indexOf(index) !== -1;

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer>
        <Table aria-labelledby="tableTitle" size="medium">
          <EnhancedTableHead
            headCells={headCells}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            onSelectAllClick={handleSelectAllClick}
            rowCount={rows.length}
            selected={selected}
            rowActionsLength={actions.length}
          />
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const rowIndex = page * rowsPerPage + index;
                const isItemSelected = isSelected(rowIndex);

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, rowIndex)}
                    role="checkbox"
                    tabIndex={-1}
                    key={index}
                    selected={isItemSelected}
                  >
                    {/* <TableCell padding="checkbox">
                      <Checkbox checked={isItemSelected} />
                    </TableCell> */}
                    <TableCell align="left">{rowIndex + 1}</TableCell>
                    {headCells.filter(headCell => headCell.id !== 'id').map((headCell, index) => (
                      <TableCell
                        key={headCell.id as string}
                        align={headCell.numeric ? "right" : "left"}
                      >
                        {row[headCell.id]}
                      </TableCell>
                    ))}
                    <TableCell align="center">
                      {actions
                        .filter((action) => action.id === `edit_${row.id}`)
                        .map((action) => (
                          <Button
                            key={action.id}
                            size="small" // Make the button small
                            onClick={(event: {
                              stopPropagation: () => void;
                            }) => {
                              event.stopPropagation(); // Prevent row click from firing
                              action.onClick(row.id);
                            }}
                          >
                            {action.label}
                          </Button>
                        ))}
                      {actions
                        .filter((action) => action.id === `delete_${row.id}`)
                        .map((action) => (
                          <Button
                            key={action.id}
                            size="small" // Make the button small
                            onClick={(event: {
                              stopPropagation: () => void;
                            }) => {
                              event.stopPropagation(); // Prevent row click from firing
                              action.onClick(row.id);
                            }}
                          >
                            {action.label}
                          </Button>
                        ))}
                      {actions
                        .filter((action) => action.id === `view_${row.id}`)
                        .map((action) => (
                          <Button
                            key={action.id}
                            size="small" // Make the button small
                            onClick={(event: {
                              stopPropagation: () => void;
                            }) => {
                              event.stopPropagation(); // Prevent row click from firing
                              action.onClick(row.id);
                            }}
                          >
                            {action.label}
                          </Button>
                        ))}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default DynamicTable;
