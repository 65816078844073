import React, { useState, useEffect, memo } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Typography,
  Pagination,
  Dialog,
} from "@mui/material";
import axios, { AxiosResponse } from "axios";
import { useSelector } from "react-redux";
import useSessionData from "../../hooks/useSessionData";
import {
  AutoclaveItemProps,
  AutoclaveListProps,
  ProcessListType,
} from "../../interface/responseInterface/AutoclaveLoadInterface";
import { RootState } from "../../stores/store";
import GetArticlesPerLoad from "./GetArticlesPerLoad";
import Brightness1Icon from "@mui/icons-material/Brightness1";
import { useMessageModal } from "../../hooks/useMessageModal";

// AutoclaveItem Component
const AutoclaveItem = memo(
  ({ autoclave, onViewDetails }: AutoclaveItemProps) => {
    const statusColor =
      autoclave.current_status === "in-use"
        ? "green"
        : autoclave.current_status === "finished"
        ? "red"
        : "grey";

    return (
      <Paper
        sx={{
          borderRadius: "8px",
          border: "0.1px solid #979dac",
          padding: "12px",
          marginBottom: "10px",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {autoclave.Autoclave}
            </Typography>
            <Typography variant="body2">
              {autoclave.load_started_at === "none"
                ? "No Data"
                : autoclave.load_started_at?.split("T")[0]}
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ color: statusColor, fontWeight: "bold" }}
            >
              {autoclave.current_status.toUpperCase()}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              sx={{ fontSize: "0.75rem", padding: "4px 8px" }}
              onClick={() => onViewDetails(autoclave)}
            >
              View Details
            </Button>
            <Typography variant="body2" sx={{ marginTop: 1 }}>
              {autoclave.cycle_name}{" "}
              {autoclave.bi_test_needed === 1 && "(First of the Day)"}
            </Typography>
            <Box
              sx={{ display: "flex", alignItems: "center", marginTop: "4px" }}
            >
              {autoclave.cycle_name?.replace(/\s+/g, "").toLowerCase() ===
              "bowiedick" ? (
                <Box sx={{ marginRight: "8px" }}>
                  C2{" "}
                  <Brightness1Icon
                    sx={{
                      color: "green",
                      fontSize: "small",
                    }}
                  />
                </Box>
              ) : (
                ""
              )}
              {autoclave.type5_result !== null && (
                <Box sx={{ marginRight: "8px" }}>
                  C
                  {autoclave.type_id === 1
                    ? "4"
                    : autoclave.type_id === 2
                    ? "5"
                    : autoclave.type_id === 3
                    ? "6"
                    : ""}{" "}
                  <Brightness1Icon
                    sx={{
                      color: autoclave.type5_result !== 1 ? "red" : "green",
                      fontSize: "small",
                    }}
                  />
                </Box>
              )}

              {autoclave.bi_result !== null && (
                <Box>
                  BI{" "}
                  <Brightness1Icon
                    sx={{
                      color: autoclave.bi_result !== 1 ? "red" : "green",
                      fontSize: "small",
                    }}
                  />
                </Box>
              )}
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Typography
              variant="h5"
              sx={{ fontWeight: "bold", textAlign: "right" }}
            >
              <span style={{ fontSize: "14px" }}>Cycle No:</span>
              {autoclave.cycle_no}
            </Typography>
            <Typography variant="body2" sx={{ textAlign: "right" }}>
              {autoclave.current_status !== "load-removed" &&
                `By: ${autoclave.started_by?.split(" ")[0]}`}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    );
  }
);

// AutoclaveList Component
const AutoclaveList = ({ data, onViewDetails }: AutoclaveListProps) => {
  return (
    <Container sx={{ paddingTop: "20px", maxWidth: "800px" }}>
      {data.map((autoclave, index) => (
        <AutoclaveItem
          key={`${autoclave.process_id}-${index}`}
          autoclave={autoclave}
          onViewDetails={onViewDetails}
        />
      ))}
    </Container>
  );
};

// AutoclaveLoads Component
const AutoclaveLoads = () => {
  const { getClientToken, getBaseURL } = useSessionData();
  const API_URL = getBaseURL();
  const currentBranch = useSelector(
    (state: RootState) => state.branch.currentBranch
  );
  const { handleOpenModal, ModalComponent } = useMessageModal();
  const [autoclaveData, setAutoclaveData] = useState<ProcessListType[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedAutoclave, setSelectedAutoclave] =
    useState<ProcessListType | null>(null);
  const itemsPerPage = 10;

  const getUniqueByCycleNo = (arr: any[]) => {
    const seen = new Set();
    return arr.filter((item) => {
      if (!seen.has(item.cycle_no)) {
        seen.add(item.cycle_no);
        return true;
      }
      return false;
    });
  };

  useEffect(() => {
    const fetchAutoclaveData = async () => {
      const branchQuery = `branch_id=${currentBranch}`;

      try {
        const response: AxiosResponse<{ res: ProcessListType[] }> =
          await axios.get(
            `${API_URL}/web-reports/processlist/filter?${branchQuery}&sort_by[id]=DESC`,
            {
              headers: {
                Authorization: `Bearer ${getClientToken()}`,
              },
            }
          );
        const uniqueAutoclaves = getUniqueByCycleNo(response.data.res);
        console.log(uniqueAutoclaves);
        setAutoclaveData(uniqueAutoclaves);
      } catch (error) {
        handleOpenModal("error", "Failed to fetch data.");
        setError("No data found!");
      }
    };

    fetchAutoclaveData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle pagination
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setCurrentPage(page);
  };

  // Handle view details
  const handleViewDetails = (autoclave: ProcessListType) => {
    setSelectedAutoclave(autoclave);
  };

  // Close dialog
  const handleCloseDialog = () => {
    setSelectedAutoclave(null);
  };

  // Calculate the paginated data
  const paginatedData = autoclaveData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <>
      <h4>Autoclave Loads</h4>
      <ModalComponent />
      {error ? (
        <Typography color="error">{error}</Typography>
      ) : (
        <>
          <AutoclaveList
            data={paginatedData}
            onViewDetails={handleViewDetails}
          />
          <Pagination
            count={Math.ceil(autoclaveData.length / itemsPerPage)}
            page={currentPage}
            onChange={handlePageChange}
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          />
        </>
      )}

      {selectedAutoclave && (
        <Dialog
          open={!!selectedAutoclave}
          onClose={handleCloseDialog}
          maxWidth="md"
          fullWidth
        >
          <GetArticlesPerLoad
            id={selectedAutoclave.process_id}
            info={selectedAutoclave}
            onClose={handleCloseDialog}
          />
        </Dialog>
      )}
    </>
  );
};

export default AutoclaveLoads;
