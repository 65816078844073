import DynamicForm, { FieldConfig } from "../../components/forms/DynamicForm";
import * as Yup from "yup";
import { ReaderFormValues } from "../../interface/responseInterface/ReaderInterface";
import useSessionData from "../../hooks/useSessionData";
import { FormikHelpers } from "formik";
import axios from "axios";
import useBranches from "../../hooks/useBranches";

const RegisterReader = () => {
  const { getClientToken, getBaseURL } = useSessionData();
  const API_URL = getBaseURL();
  const { branchOptions } = useBranches();

  const readerInitialValues: ReaderFormValues = {
    readerName: "",
    readerLocation: "",
    branchName: "0",
    city: "",
  };

  const readerValidationSchema: Yup.ObjectSchema<ReaderFormValues> =
    Yup.object().shape({
      readerName: Yup.string().required("Reader Name is required"),
      readerLocation: Yup.string().required("Reader Location is required"),
      branchName: Yup.string().required("Branch is required"),
      city: Yup.string().required("City is required"),
    });

  const readerFields: FieldConfig[] = [
    {
      name: "readerName",
      label: "Reader Name *",
      type: "text",
      fullWidth: true,
    },
    {
      name: "readerLocation",
      label: "Location Name *",
      type: "text",
      fullWidth: true,
    },
    {
      name: "branchName",
      label: "Branch *",
      type: "select",
      options: branchOptions,
      fullWidth: true,
    },
  ];

  const handleSubmit = async (
    values: ReaderFormValues,
    { resetForm }: FormikHelpers<ReaderFormValues>
  ) => {
    console.log("Submit triggered with values:", values);
    try {
      const response = await axios.post(
        `${API_URL}web-register/newreader`,
        {
          reader: values.readerName,
          location: values.readerLocation,
          branch_id: values.branchName,
        },
        {
          headers: {
            Authorization: `Bearer ${getClientToken()} `,
          },
        }
      );
      if (response.status !== 201) {
        alert("Registration Failed. Please check your fields!");
        return;
      }
      resetForm();
    } catch (error) {
      alert("Registration failed. Please check!");
    }
  };

  return (
    <div>
      <DynamicForm
        initialValues={readerInitialValues}
        validationSchema={readerValidationSchema}
        fields={readerFields}
        formTitle="Register Reader"
        onSubmit={handleSubmit}
      />
    </div>
  );
};

export default RegisterReader;
