import { Route, Routes } from "react-router-dom";
import Login from "../pages/company/Login";
import ForgotPassword from "../pages/company/ForgotPassword";
import Onboard from "../pages/onboarding/Onboard";
import PublicRoute from "../utils/PublicRoute";
import ResetPassword from "../pages/company/ResetPassword";

const LoginRoutes = () => {
  const loginRoutes = [
    { path: "/", name: "Login", component: Login },
    {
      path: "/forgot-password",
      name: "Forgot Password",
      component: ForgotPassword,
    },
    {
      path: "reset-password",
      name: "Reset Password",
      component: ResetPassword,
    },
    { path: "/onboard", name: "Onboarding Process", component: Onboard },
  ];

  return (
    <Routes>
      <Route element={<PublicRoute />}>
        {loginRoutes.map((route, index) => (
          <Route key={index} path={route.path} element={<route.component />} />
        ))}
      </Route>
    </Routes>
  );
};

export default LoginRoutes;
