import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "../reducers/authReducer";
import clientReducer from "../reducers/clientReducer";
import branchReducer from "../reducers/branchReducer";
import { logout } from "../actions/authActions";

const appReducer = combineReducers({
    auth: authReducer,
    client: clientReducer,
    branch: branchReducer,
});

const logoutReducer = (state: any, action: any) => {
    if (action.type === logout.type) {
        state = undefined;
    }
    return appReducer(state, action);
};

export default logoutReducer;