import * as React from "react";
import { useState } from "react";
import {
  Button,
  Card,
  CardContent,
  Container,
  CssBaseline,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import {
  ModalFormData,
  ModalFormProps,
} from "../../interface/ModalFormInterface";

const ModalForm = ({ title, fields, onSubmit }: ModalFormProps) => {
  const [formData, setFormData] = useState<ModalFormData>({});

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit(formData);
    setFormData({}); // Reset form data after submission
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  return (
    <Box
      sx={{
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Typography variant="h5" sx={{ fontWeight: "medium" }} gutterBottom>
        {title}
      </Typography>
      <Card
        sx={{
          width: 1 / 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <CardContent>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{ mt: 1 }}
              >
                {fields.map((field) => (
                  <TextField
                    key={field.name}
                    margin="normal"
                    required
                    fullWidth
                    id={field.name}
                    label={field.label}
                    name={field.name}
                    value={formData[field.name] || ""}
                    onChange={handleInputChange}
                    autoComplete={field.name}
                    autoFocus={field.name === fields[0].name}
                  />
                ))}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3 }}
                >
                  Submit
                </Button>
              </Box>
            </Box>
          </Container>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ModalForm;
