import { useCallback, useEffect, useState } from "react";
import { Container, Box, Grid, Typography, Paper } from "@mui/material";
import SummaryCard from "./SummaryCard";
import "../../assets/css/main-style.css";
import DateSelector from "../../components/dateSelector/DateSelector";
import axios from "axios";
import useSessionData from "../../hooks/useSessionData";
import {
  TotolCardCounts,
  ApiDataType,
  LocLoadCount,
  LocInsCount,
} from "../../interface/responseInterface/DashboardInterface";
import { useSelector } from "react-redux";
import { RootState } from "../../stores/store";
import { useMessageModal } from "../../hooks/useMessageModal";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register the required chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Dashboard = () => {
  const { getClientToken, getBaseURL } = useSessionData();
  const API_URL = getBaseURL();
  const { handleOpenModal, ModalComponent } = useMessageModal();
  const currentBranch = useSelector(
    (state: RootState) => state.branch.currentBranch
  );

  const [cardCounts, setCardCounts] = useState<TotolCardCounts>({
    articleCount: "",
    aclaveCount: "",
    readerCount: "",
    pouchCount: "",
    emailCount: "",
    branchCount: "",
  });
  const [chartData, setChartData] = useState<ApiDataType[]>([]);
  const [readerLocData, setReaderLocData] = useState<{
    loads: LocLoadCount[];
    use_ins: LocInsCount[];
  }>({
    loads: [],
    use_ins: [],
  });

  const fetchData = useCallback(async () => {
    try {
      const cardCountResponse = await axios.get<TotolCardCounts[]>(
        `${API_URL}web-reports/totalcardscount/${currentBranch || "null"}`,
        {
          headers: {
            Authorization: `Bearer ${getClientToken()}`,
          },
        }
      );

      if (
        cardCountResponse.status === 200 &&
        cardCountResponse.data.length > 0
      ) {
        setCardCounts(cardCountResponse.data[0]);
      }

      const chartDataResponse = await axios.get<ApiDataType[]>(
        `${API_URL}web-reports/loadcount/${currentBranch || "null"}`,
        {
          headers: {
            Authorization: `Bearer ${getClientToken()}`,
          },
        }
      );

      if (chartDataResponse.status === 200) {
        setChartData(chartDataResponse.data);
      }

      const readerLocResponse = await axios.get<{
        loads: LocLoadCount[];
        use_ins: LocInsCount[];
      }>(`${API_URL}web-reports/readerlocdata/${currentBranch || "null"}`, {
        headers: {
          Authorization: `Bearer ${getClientToken()}`,
        },
      });

      if (readerLocResponse.status === 200) {
        setReaderLocData(readerLocResponse.data);
      }
    } catch (error) {
      handleOpenModal("error", "Something went wrong!");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleDateChange = useCallback(
    (dateFormat: string) => {
      fetchData();
    },
    [fetchData]
  );

  const renderView = () => (
    <Container
      sx={{
        height: "80%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography variant="h5" align="center" marginTop={3}>
            Autoclave Loads Report
          </Typography>
          <Paper
            sx={{
              padding: 3,
              marginBottom: 5,
              boxShadow: 3,
              borderRadius: 1,
              backgroundColor: "white",
            }}
          >
            <DateSelector parentCallback={handleDateChange} />
            <Box sx={{ height: 250 }}>
              <Bar
                options={{ maintainAspectRatio: false }}
                data={{
                  labels: chartData.map((item) => item.av_name),
                  datasets: [
                    {
                      label: "No. of Loads",
                      backgroundColor: "rgb(63, 81, 181)",
                      borderColor: "rgb(255, 99, 132)",
                      data: chartData.map((item) => item.total_loads),
                    },
                  ],
                }}
              />
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h5" align="center" marginTop={3}>
            Reader Location Report
          </Typography>
          <Paper
            sx={{
              padding: 3,
              marginBottom: 5,
              boxShadow: 3,
              borderRadius: 1,
              backgroundColor: "white",
            }}
          >
            <DateSelector parentCallback={handleDateChange} />
            <Box sx={{ height: 250 }}>
              <Bar
                options={{ maintainAspectRatio: false }}
                data={{
                  labels: readerLocData.loads.map((item) => item.location),
                  datasets: [
                    {
                      label: "Loads Done",
                      backgroundColor: "rgb(233, 196, 106)",
                      borderColor: "rgb(255, 99, 132)",
                      data: readerLocData.loads.map((item) => item.load_count),
                    },
                    {
                      label: "Articles Used",
                      backgroundColor: "rgb(42, 157, 143)",
                      borderColor: "rgb(255, 99, 132)",
                      data: readerLocData.use_ins.map(
                        (item) => item.instrument_use_count
                      ),
                    },
                  ],
                }}
              />
            </Box>
          </Paper>
        </Grid>
      </Grid>
      {/* Cards */}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <SummaryCard
            title="Total Used Articles"
            value={cardCounts.articleCount}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <SummaryCard title="AutoClaves Load" value={cardCounts.aclaveCount} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <SummaryCard
            title="Readers Registered"
            value={cardCounts.readerCount}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <SummaryCard
            title="Total Pouch Items"
            value={cardCounts.pouchCount}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <SummaryCard title="Registered Users" value={cardCounts.emailCount} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <SummaryCard title="No. of Branches" value={cardCounts.branchCount} />
        </Grid>
      </Grid>
    </Container>
  );

  return (
    <>
      <ModalComponent />
      <Container
        sx={{
          height: "80%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          paddingTop: "25px",
        }}
      >
        {renderView()}
      </Container>
    </>
  );
};

export default Dashboard;
