import useSessionData from "../hooks/useSessionData";
import axios from "axios";
import { useState, useEffect, useCallback } from "react";
import { BranchResponse } from "../interface/responseInterface/BranchResponseInterface";
import { useSelector } from "react-redux";
import { RootState } from "../stores/store";

const FetchBranches = () => {
  const { getClientToken, getBaseURL } = useSessionData();
  const [branchData, setBranchData] = useState<BranchResponse[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const currentBranch = useSelector(
    (state: RootState) => state.branch.currentBranch
  );
  const clientInfo = useSelector((state: RootState) => state.client.clientInfo);
  const [roleId, setRoleId] = useState<number | null>(null);

  // Set roleId when clientInfo is available
  useEffect(() => {
    if (clientInfo && clientInfo.length > 0) {
      setRoleId(Number(clientInfo[0].role_id));
    }
  }, [clientInfo]);

  // Fetch branches only after roleId is set
  const getBranches = useCallback(async () => {
    if (roleId === null) return; // Don't fetch if roleId is not set

    setLoading(true);
    try {
      const { data } = await axios.get<BranchResponse[]>(
        `${getBaseURL()}web-standalone/get-branch`,
        {
          headers: {
            Authorization: `Bearer ${getClientToken()}`,
          },
        }
      );

      if (roleId !== 1) {
        setBranchData(
          data.filter((item) => item.branch_id === Number(currentBranch))
        );
      } else {
        setBranchData(data);
      }
    } catch (error) {
      setError("Something went wrong while fetching branch data.");
      console.error("Error fetching branch data:", error);
    } finally {
      setLoading(false);
    }
  }, [roleId, currentBranch, getClientToken, getBaseURL]);

  // Fetch branches when roleId changes and is set
  useEffect(() => {
    if (roleId !== null) {
      getBranches();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleId]);

  return { branchData, loading, error, getBranches };
};

export default FetchBranches;
