import { Button } from "@mui/material";
import { ContainedButtonInterface } from "../../interface/ContainedButtonInterface";

const ContainedButton = ({
  buttonTitle,
  onButtonClick,
}: ContainedButtonInterface) => {
  return (
    <Button variant="contained" onClick={onButtonClick}>
      {buttonTitle}
    </Button>
  );
};

export default ContainedButton;
