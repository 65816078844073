import DynamicForm, { FieldConfig } from "../../components/forms/DynamicForm";
import * as Yup from "yup";
import { BiLotFormValues } from "../../interface/responseInterface/BiLotInterface";
import useSessionData from "../../hooks/useSessionData";
import { FormikHelpers } from "formik";
import axios from "axios";
import useBranches from "../../hooks/useBranches";
import { useMessageModal } from "../../hooks/useMessageModal";

const RegisterBiLot = () => {
  const { getClientToken, getBaseURL } = useSessionData();
  const API_URL = getBaseURL();
  const { branchOptions } = useBranches();
  const { handleOpenModal, ModalComponent } = useMessageModal();

  const biLotInitialValues: BiLotFormValues = {
    biLot: "",
    branchName: "0",
  };

  const biLotValidationSchema: Yup.ObjectSchema<BiLotFormValues> =
    Yup.object().shape({
      biLot: Yup.string().required("BI Lot is required"),
      branchName: Yup.string().required("Branch is required"),
    });

  const biLotFields: FieldConfig[] = [
    {
      name: "biLot", // Make sure this matches the initial values field
      label: "Enter BI Lot *",
      type: "text",
      fullWidth: true,
    },
    {
      name: "branchName",
      label: "Branch *",
      type: "select",
      options: branchOptions,
      fullWidth: true,
    },
  ];

  const handleSubmit = async (
    values: BiLotFormValues,
    { resetForm }: FormikHelpers<BiLotFormValues>
  ) => {
    try {
      const response = await axios.post(
        `${API_URL}web-standalone/register-bilot`,
        {
          latest_bi_lot: values.biLot,
          branch_id: values.branchName,
        },
        {
          headers: {
            Authorization: `Bearer ${getClientToken()} `,
          },
        }
      );
      if (response.status !== 201) {
        handleOpenModal("error", "Error while registering bi lot.");
        return;
      }
      handleOpenModal("success", "Successfully registered bi lot.");
    } catch (error) {
      handleOpenModal("error", "Error while registering bi lot.");
    } finally {
      resetForm();
    }
  };

  return (
    <div>
      <ModalComponent />
      <DynamicForm
        initialValues={biLotInitialValues}
        validationSchema={biLotValidationSchema}
        fields={biLotFields}
        formTitle="Register BI Lot"
        onSubmit={handleSubmit}
      />
    </div>
  );
};

export default RegisterBiLot;
