import { configureStore, Middleware } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer } from "redux-persist";
import logoutReducer from "../reducers/logoutReducer";

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth', 'client', 'branch'],
};

const persistedReducer = persistReducer(persistConfig, logoutReducer);

const serializableMiddleware: Middleware = (storeAPI) => (next) => (action: any) => {
    if (action.type === 'persist/PURGE' || action.type === 'persist/REHYDRATE') {
        return next(action);
    }
    return next(action);
};

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE', 'persist/PURGE'],
            },
        }).concat(serializableMiddleware),
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
