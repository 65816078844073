import { useState } from "react";
import MessageModal from "../components/modal/MessageModal";

export const useMessageModal = () => {
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState<
    "error" | "success" | "warning" | "info" | "alert"
  >("error");
  const [modalMessage, setModalMessage] = useState("");

  const handleOpenModal = (
    type: "error" | "success" | "warning" | "info" | "alert",
    message: string
  ) => {
    setModalType(type);
    setModalMessage(message);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const ModalComponent = () => (
    <MessageModal
      open={openModal}
      onClose={handleCloseModal}
      type={modalType}
      message={modalMessage}
    />
  );

  return {
    handleOpenModal,
    handleCloseModal,
    ModalComponent,
  };
};
