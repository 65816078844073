import { Route, Routes } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../stores/store";
import ProtectedRoute from "../utils/ProtectedRoute";
import PageNotFound from "../pages/common/PageNotFound";

// Import all your components
import Dashboard from "../pages/dashboard/Dashboard";
import PrintLabel from "../pages/printLabel/PrintLabel";
import CheckStatus from "../pages/checkStatus/CheckStatus";
import Autoclaves from "../pages/autoclaves/Autoclaves";
import AutoclaveLoads from "../pages/autoclaveloads/AutoclaveLoads";
import ServiceLog from "../pages/serviceLog/ServiceLog";
import Articles from "../pages/articles/Articles";
import BiResult from "../pages/biResult/BiResult";
import ArticleSet from "../pages/articleset/ArticleSet";
import UseInstrument from "../pages/useInstrument/UseInstrument";
import Users from "../pages/users/Users";
import RegisterUser from "../pages/users/RegisterUser";
import RegisterArticle from "../pages/articles/RegisterArticle";
import RegisterArticleSet from "../pages/articleset/RegisterArticleSet";
import RegisterBranch from "../pages/branches/RegisterBranch";
import RegisterReader from "../pages/reader/RegisterReader";
import RegisterAutoclave from "../pages/autoclaves/RegisterAutoclave";
import RegisterBiLot from "../pages/biResult/RegisterBiLot";
import RegsiterArticleType from "../pages/articles/RegsiterArticleType";
import Readers from "../pages/reader/ReaderList";
import BranchList from "../pages/branches/BranchList";
import RegisterNewAutoclave from "../pages/autoclaves/RegisterNewAutoclave";
import BiologicalRecordSheet from "../pages/biRecordSheet/BiologicalRecordSheet";
import { Box, CircularProgress } from "@mui/material";
import BiExternalReport from "../pages/biExternalReport/BiExternalReport";
import UserProfile from "../pages/profile/UserProfile";
import Account from "../pages/profile/Account";

export const appRoutes = [
  { path: "/", name: "Dashboard", component: Dashboard },
  {
    path: "/users",
    name: "Users",
    component: Users,
    condition: (roleId: number) => roleId === 1 || roleId === 2,
  },
  {
    path: "/readers",
    name: "Readers",
    component: Readers,
    condition: (roleId: number, rfidEnabled: number) =>
      (roleId === 1 || roleId === 2) && rfidEnabled === 1,
  },
  {
    path: "/branches",
    name: "Branches",
    component: BranchList,
    condition: (roleId: number) => roleId === 1,
  },
  { path: "/print-label", name: "Print Label", component: PrintLabel },
  { path: "/check-status", name: "Check Status", component: CheckStatus },
  { path: "/autoclaves", name: "Autoclaves", component: Autoclaves },
  {
    path: "/autoclaves-loads",
    name: "Autoclave Loads",
    component: AutoclaveLoads,
  },
  { path: "/service-log", name: "Service Log", component: ServiceLog },
  { path: "/articles", name: "Articles", component: Articles },
  { path: "/article-set", name: "Article Set", component: ArticleSet },
  { path: "/use-instrument", name: "Use Instrument", component: UseInstrument },
  { path: "/bi-callback", name: "BI Callback", component: BiResult },
  {
    path: "/bi-record-sheet",
    name: "BI Record Sheet",
    component: BiologicalRecordSheet,
  },
  {
    path: "/bi-external-report",
    name: "BI External Report",
    component: BiExternalReport,
  },
  {
    path: "/register-user",
    name: "Register User",
    component: RegisterUser,
    condition: (roleId: number) => roleId === 1 || roleId === 2,
  },
  {
    path: "/register-article",
    name: "Register Article",
    component: RegisterArticle,
    condition: (roleId: number) => roleId === 1 || roleId === 2,
  },
  {
    path: "/register-article-set",
    name: "Register Article Set",
    component: RegisterArticleSet,
    condition: (roleId: number) => roleId === 1 || roleId === 2,
  },
  {
    path: "/register-branch",
    name: "Register Branch",
    component: RegisterBranch,
    condition: (roleId: number) => roleId === 1,
  },
  {
    path: "/register-reader",
    name: "Register Reader",
    component: RegisterReader,
    condition: (roleId: number, rfidEnabled: number) =>
      (roleId === 1 || roleId === 2) && rfidEnabled === 1,
  },
  {
    path: "/register-autoclave",
    name: "Register Autoclave",
    component: RegisterAutoclave,
    condition: (roleId: number) => roleId === 1 || roleId === 2,
  },
  {
    path: "/register-new-autoclave",
    name: "Register New Autoclave",
    component: RegisterNewAutoclave,
    condition: (roleId: number) => roleId === 1 || roleId === 2,
  },
  {
    path: "/register-bilot",
    name: "Register BiLot",
    component: RegisterBiLot,
    condition: (roleId: number) => roleId === 1 || roleId === 2,
  },
  {
    path: "/register-article-type",
    name: "Register Article Type",
    component: RegsiterArticleType,
    condition: (roleId: number) => roleId === 1 || roleId === 2,
  },
  {
    path: "/user-profile",
    name: "User Profile",
    component: UserProfile,
  },
  {
    path: "/account",
    name: "Account",
    component: Account,
    condition: (roleId: number) => roleId === 1 || roleId === 2,
  },
  {
    path: "*",
    name: "Page Not Found",
    component: PageNotFound,
  },
];

const AppRoutes = () => {
  const clientInfo = useSelector((state: RootState) => state.client.clientInfo);
  const [roleId, setRoleId] = useState<number | null>(null);
  const rfidEnabled = Number(
    useSelector((state: RootState) => state.auth.rfidEnabled)
  );

  useEffect(() => {
    if (clientInfo) {
      setRoleId(Number(clientInfo[0].role_id));
    }
  }, [clientInfo]);

  if (roleId === null) {
    // Render a loading state until roleId is set
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress size={24} />
      </Box>
    );
  }

  return (
    <Routes>
      <Route element={<ProtectedRoute />}>
        {appRoutes
          .filter(
            (route) => !route.condition || route.condition(roleId, rfidEnabled)
          )
          .map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={<route.component />}
            />
          ))}
      </Route>
    </Routes>
  );
};

export default AppRoutes;
