import React, { useState } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Container,
  Box,
  Avatar,
} from "@mui/material";
import Step1 from "./StepOne";
import Step2 from "./StepTwo";
import Step3 from "./StepThree";
import { styled } from "@mui/material/styles";

import iconImage from "../../assets/images/reprolog_logo.png";

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(10),
  height: theme.spacing(10),
  margin: theme.spacing(1),
  backgroundColor: theme.palette.primary.main,
}));
const steps = ["Step 1", "Step 2", "Step 3"]; // Define your steps here

const Onboard: React.FC = () => {
  const [activeStep, setActiveStep] = useState<number>(0); // State to track the active step
  const [formData, setFormData] = useState<{
    firstName?: string;
    lastName?: string;
    email?: string;
  }>({}); // State to store form data

  const handleNext = (data: any) => {
    setFormData({ ...formData, ...data });
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmit = () => {
    // Handle form submission here
    console.log("Form submitted with data:", formData);
  };

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return <Step1 onNext={handleNext} />;
      case 1:
        return <Step2 onNext={handleNext} />;
      case 2:
        return <Step3 onNext={handleNext} />;
      // Add more cases for each step component
      default:
        return "Unknown step";
    }
  };

  return (
    <Container component="main" maxWidth="lg">
      {/* <Box mt={4} px={0} py={0}> */}
      <Box
        component="section"
        mt={2}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <StyledAvatar>
          <img
            src={iconImage}
            alt="Custom Icon"
            style={{ width: "100%", height: "auto" }}
          />
        </StyledAvatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
      </Box>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {/* </Box> */}
      <Box mt={2}>
        <Typography variant="h6">{getStepContent(activeStep)}</Typography>
      </Box>
      <Box mt={2}>
        <div>
          {activeStep !== 0 && (
            <Button
              onClick={handleBack}
              variant="outlined"
              color="primary"
              sx={{ mr: 1 }}
            >
              Back
            </Button>
          )}
          {activeStep === steps.length - 1 ? (
            <Button
              variant="contained"
              color="primary"
              onClick={
                activeStep === steps.length - 1 ? handleSubmit : () => {}
              } // Replace () => {} with next step handling logic
            >
              Finish
            </Button>
          ) : (
            ""
          )}
        </div>
      </Box>
    </Container>
  );
};

export default Onboard;
