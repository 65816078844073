import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Avatar, Container } from "@mui/material";
import { styled } from "@mui/material/styles";
import iconImage from "../../assets/images/reprolog_logo.png";
import backgroundImage from "../../assets/images/login_bg_2.jpg";
import { UserLoginResponse } from "../../interface/LoginInterface";
import { useState } from "react";
import SessionData from "../../utils/SessionData";
import { useNavigate } from "react-router-dom";
import axios, { AxiosResponse } from "axios";
import { login } from "../../actions/authActions";
import { useDispatch } from "react-redux";
import { setBranch } from "../../actions/branchActions";

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(10),
  height: theme.spacing(10),
  margin: theme.spacing(1),
}));

const sd = new SessionData();
const API_URL = sd.getBaseURL();

export default function Login() {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [clientid, setClientid] = useState<string>("");
  const [warning, setWarning] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogin = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    axios
      .post<UserLoginResponse>(
        `${API_URL}login`,
        {
          username,
          password,
          clientid: `DENTAL_${clientid.trim().toUpperCase()}`,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response: AxiosResponse<UserLoginResponse>) => {
        const data: UserLoginResponse = response.data;

        dispatch(
          login({
            authToken: data.tokens.accessToken,
            clientName: data.user.clientId,
            emailId: username,
            roleId: data.user.roleId,
            rfidEnabled: data.user.rfidEnabled,
          })
        );
        if (Number(data.user.roleId) !== 1) {
          dispatch(setBranch(data.user.branchId));
        }
        // Navigate to the dashboard
        navigate("/");
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          setMessage(
            "Sorry, entered credentials are wrong! Please check and try again."
          );
        } else {
          setMessage(
            "Sorry, user account is not registered or account is deactivated! Please contact administrator."
          );
        }
        setWarning(true);
      });
  };

  return (
    <Container component="main" maxWidth="xl">
      <Box sx={{ marginTop: 4 }}>
        <Grid container>
          <CssBaseline />
          <Grid
            item
            xs={false}
            sm={4}
            md={7}
            sx={{
              backgroundImage: `url(${backgroundImage})`,
              backgroundRepeat: "no-repeat",
              backgroundColor: (t) =>
                t.palette.mode === "light"
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
          >
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <StyledAvatar>
                <img
                  src={iconImage}
                  alt="Custom Icon"
                  style={{ width: "100%", height: "auto" }}
                />
              </StyledAvatar>
              <Typography component="h1" variant="h5">
                Sign in
              </Typography>
              <Box
                component="form"
                noValidate
                onSubmit={handleLogin}
                sx={{ mt: 1 }}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="client"
                  label="Client Name"
                  name="client"
                  autoComplete="client"
                  autoFocus
                  value={clientid}
                  onChange={(e) => setClientid(e.target.value)}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Sign In
                </Button>
                <Grid container>
                  <Grid item xs>
                    <Link href="/login/forgot-password" variant="body2">
                      Forgot password?
                    </Link>
                  </Grid>
                </Grid>
              </Box>
              {warning && (
                <Typography variant="body2" color="error" align="center">
                  {message}
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
