import { createReducer } from "@reduxjs/toolkit";
import { login, logout } from "../actions/authActions";
import { AuthState } from "../interface/reduxInterfaces/AuthStateInterface";

const initialState: AuthState = {
  isAuthenticate: false,
  authToken: null,
  clientName: null,
  emailId: null,
  roleId: null,
  rfidEnabled: null,
  typeId: 0,
};

const authReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(login, (state, action) => {
      state.isAuthenticate = true;
      state.authToken = action.payload.authToken;
      state.clientName = action.payload.clientName;
      state.emailId = action.payload.emailId;
      state.rfidEnabled = action.payload.rfidEnabled;
      state.roleId = action.payload.roleId;
    })
    .addCase(logout, () => initialState);
});

export default authReducer;
