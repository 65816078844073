import DynamicForm, { FieldConfig } from "../../components/forms/DynamicForm";
import * as Yup from "yup";
import {
  AutoclaveFormValues,
  AutoclaveList,
} from "../../interface/responseInterface/AutoclaveInterface";
import useSessionData from "../../hooks/useSessionData";
import { FormikHelpers } from "formik";
import axios from "axios";
import useBranches from "../../hooks/useBranches";
import { useEffect, useState } from "react";
import { useMessageModal } from "../../hooks/useMessageModal";
import { Box, SelectChangeEvent } from "@mui/material";
import ContainedButton from "../../components/button/ContainedButton";
import { useNavigate } from "react-router-dom";

interface AutoclaveListOption {
  value: string;
  label: string;
}

const RegisterAutoclave = () => {
  const { getClientToken, getBaseURL } = useSessionData();
  const API_URL = getBaseURL();
  const navigate = useNavigate();
  const { branchOptions } = useBranches();
  const [autoclaveList, setAutoclaveList] = useState<AutoclaveListOption[]>([]);
  const [autoclaveResponse, setAutoclaveResponse] = useState<AutoclaveList[]>(
    []
  );
  const { handleOpenModal, ModalComponent } = useMessageModal();
  const [autoclaveParams, setAutoclaveParams] = useState({
    avName: "",
    autoclaveOpName: "",
    description: "",
    manuf: "",
    model: "",
    serialNo: "",
    bowieDick: "",
    newAvId: 0,
  });

  const autoclaveInitialValues: AutoclaveFormValues = {
    autoclaveName: "0",
    branchName: "0",
    cycleNumber: "0",
  };

  const autoclaveValidationSchema: Yup.ObjectSchema<AutoclaveFormValues> =
    Yup.object().shape({
      autoclaveName: Yup.string().required("Autoclave Name is required"),
      branchName: Yup.string().required("Branch is required"),
      cycleNumber: Yup.number().required("Cycle Number is required"),
    });

  useEffect(() => {
    const getAutoclaveList = async () => {
      try {
        const { data } = await axios.get<AutoclaveList[]>(
          `${getBaseURL()}web-register/getautoclaves`,
          {
            headers: {
              Authorization: `Bearer ${getClientToken()}`,
            },
          }
        );

        const autoclaveSelectList =
          data.length > 0
            ? [
                { value: "0", label: "-- Select Autoclave Type --" },
                ...data.map((autoclaveType: AutoclaveList) => ({
                  value: autoclaveType.id.toString(),
                  label:
                    autoclaveType.autoclave_name +
                    "-" +
                    autoclaveType.autoclave_model +
                    "-" +
                    autoclaveType.autoclave_manufacturer,
                })),
              ]
            : [{ value: "0", label: "-- No Autoclave Available --" }];

        setAutoclaveResponse(data);
        setAutoclaveList(autoclaveSelectList);
      } catch (error) {
        handleOpenModal("error", "Error while fetching autoclave list.");
      }
    };

    getAutoclaveList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAutoclaveChange = (e: SelectChangeEvent<any>) => {
    const avId = e.target.value;
    const selectedAutoclave = autoclaveResponse.find(
      (item) => item.id.toString() === avId
    );

    if (selectedAutoclave) {
      setAutoclaveParams({
        avName: selectedAutoclave.autoclave_name,
        autoclaveOpName: selectedAutoclave.autoclave_opname || "",
        description: "",
        manuf: selectedAutoclave.autoclave_manufacturer || "",
        model: selectedAutoclave.autoclave_model || "",
        serialNo: selectedAutoclave.serial_no || "",
        bowieDick: selectedAutoclave.bowie_dick,
        newAvId: selectedAutoclave.id,
      });
    }
  };

  const autoclaveFields: FieldConfig[] = [
    {
      name: "autoclaveName",
      label: "Select Autoclave *",
      type: "select",
      options: autoclaveList,
      fullWidth: true,
      onChange: handleAutoclaveChange,
    },
    {
      name: "branchName",
      label: "Select Branch *",
      type: "select",
      options: branchOptions,
    },
    {
      name: "cycleNumber",
      label: "Cycle # *",
      type: "text",
    },
  ];

  const handleSubmit = async (
    values: AutoclaveFormValues,
    { resetForm }: FormikHelpers<AutoclaveFormValues>
  ) => {
    try {
      const response = await axios.post(
        `${API_URL}web-register/autoclave`,
        {
          avName: autoclaveParams.avName,
          cycleNo: values.cycleNumber,
          branch_id: values.branchName,
          description: autoclaveParams.description,
          manuf: autoclaveParams.manuf,
          model: autoclaveParams.model,
          serialNo: autoclaveParams.serialNo,
          bowieDick: autoclaveParams.bowieDick,
          avId: autoclaveParams.newAvId,
          autoclaveOpName: autoclaveParams.autoclaveOpName,
        },
        {
          headers: {
            Authorization: `Bearer ${getClientToken()} `,
          },
        }
      );
      if (response.status !== 201) {
        handleOpenModal(
          "error",
          "Error while registering autoclave to branch."
        );
        return;
      }
      handleOpenModal(
        "success",
        "Successfully registered autoclave to branch."
      );
    } catch (error) {
      handleOpenModal("error", "Error while registering autoclave to branch.");
    }
  };

  const handleButtonClick = () => {
    navigate("/register-new-autoclave");
  };

  return (
    <div>
      <ModalComponent />
      <Box sx={{ display: "flex", justifyContent: "flex-end", m: 2 }}>
        <ContainedButton
          buttonTitle="Add New"
          onButtonClick={handleButtonClick}
        />
      </Box>
      <DynamicForm
        initialValues={autoclaveInitialValues}
        validationSchema={autoclaveValidationSchema}
        fields={autoclaveFields}
        formTitle="Assign Autoclave to Branch"
        onSubmit={handleSubmit}
      />
    </div>
  );
};

export default RegisterAutoclave;
