import { Field, FieldProps, useField, useFormikContext } from "formik";
import {
  TextField,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Typography,
  Autocomplete,
  SelectChangeEvent,
} from "@mui/material";

interface FormikTextFieldProps {
  name: string;
  label: string;
  type?: string;
}

export const FormikTextField = ({
  name,
  label,
  type = "text",
}: FormikTextFieldProps) => (
  <Field name={name}>
    {({ field, meta }: FieldProps) => (
      <TextField
        {...field}
        label={label}
        type={type}
        error={meta.touched && Boolean(meta.error)}
        helperText={meta.touched && meta.error}
        fullWidth
        size="small"
        variant="outlined"
        inputProps={
          type === "number" ? { inputMode: "numeric", pattern: "[0-9]*" } : {}
        }
      />
    )}
  </Field>
);

interface FormikSelectProps {
  name: string;
  label: string;
  options?: { value: string; label: string }[];
  onChange?: (e: SelectChangeEvent<any>) => void;
}

export const FormikSelect = ({
  name,
  label,
  options,
  onChange,
}: FormikSelectProps) => (
  <Field name={name}>
    {({ field, meta }: FieldProps) => (
      <FormControl fullWidth variant="outlined" size="small">
        <Select
          {...field}
          error={meta.touched && Boolean(meta.error)}
          label={label}
          onChange={(e: SelectChangeEvent<any>) => {
            field.onChange(e); // Call Formik's onChange
            if (onChange) onChange(e); // Call custom onChange if provided
          }}
        >
          {options &&
            options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
        </Select>
        {meta.touched && meta.error && (
          <Typography color="error" variant="caption">
            {meta.error}
          </Typography>
        )}
      </FormControl>
    )}
  </Field>
);

interface FormikCheckboxProps {
  name: string;
  label: string;
}

export const FormikCheckbox = ({ name, label }: FormikCheckboxProps) => (
  <Field name={name}>
    {({ field }: FieldProps) => (
      <FormControlLabel
        control={
          <Checkbox
            {...field}
            checked={field.value}
            color="primary"
            size="small"
          />
        }
        label={label}
      />
    )}
  </Field>
);

interface FormikAutocompleteProps {
  name: string;
  label: string;
  options?: { value: string; label: string }[];
}

export const FormikAutocomplete = ({
  name,
  label,
  options = [],
}: FormikAutocompleteProps) => {
  const { setFieldValue } = useFormikContext();
  const [, meta] = useField(name);

  return (
    <Autocomplete
      size="small"
      options={options}
      getOptionLabel={(option) => option.label}
      onChange={(event, value) => setFieldValue(name, value?.value || "")}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          error={meta.touched && Boolean(meta.error)}
          helperText={meta.touched && meta.error}
        />
      )}
    />
  );
};
