import { createReducer } from "@reduxjs/toolkit";
import { fetchClientFailure, fetchClientRequest, fetchClientSuccess, logout } from "../actions/clientActions";
import { ClientState } from "../interface/reduxInterfaces/ClientInfoInterface";

const initialState: ClientState = {
    clientInfo: null,
    loading: false,
}

const clientReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(fetchClientRequest, (state) => {
            state.loading = true;
        })
        .addCase(fetchClientSuccess, (state, action) => {
            state.loading = false;
            state.clientInfo = action.payload; // `payload` is an array of `ClientInfo`
        })
        .addCase(fetchClientFailure, (state, action) => {
            state.loading = false;
        })
        .addCase(logout, () => initialState);
})

export default clientReducer;
