import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Avatar, Container } from "@mui/material";
import { styled } from "@mui/material/styles";
import iconImage from "../../assets/images/reprolog_logo.png";
import backgroundImage from "../../assets/images/login_bg_2.jpg";
import Link from "@mui/material/Link";
import SessionData from "../../utils/SessionData";
import axios, { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { useMessageModal } from "../../hooks/useMessageModal";
import { useLocation } from "react-router-dom";

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(10),
  height: theme.spacing(10),
  margin: theme.spacing(1),
}));

interface ForgotPasswordFormInterface {
  password: string;
  secretCode: string;
}

export default function ResetPassword() {
  const sd = new SessionData();
  const API_URL = sd.getBaseURL();
  const location = useLocation();
  const [token, setToken] = useState<string | null>(null);
  // Manage the whole form state as a single object
  const [formData, setFormData] = useState<ForgotPasswordFormInterface>({
    password: "",
    secretCode: "",
  });

  const { handleOpenModal, ModalComponent } = useMessageModal();
  let query: string = "";

  useEffect(() => {
    // Create an instance of URLSearchParams to extract the query parameters
    const params = new URLSearchParams(location.search);
    const tokenParam = params.get("token"); // Get the token from the URL
    setToken(tokenParam); // Set token in state
  }, [location]);

  // Function to handle input change and update formData dynamically
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { password, secretCode } = formData;
    if (password && secretCode) {
      query = `password=${password}&cid=DENTAL_${secretCode.toUpperCase()}`;
    } else {
      handleOpenModal("error", "Please enter password and client name");
      return;
    }

    try {
      const response = await axios.put<AxiosResponse>(
        `${API_URL}user/reset-password`,
        {
          token: token,
          password: password,
          secret: secretCode,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        handleOpenModal("success", "Your password has been updated");
      } else {
        handleOpenModal("error", "Request Failed. Something went wrong");
      }
    } catch (error) {
      handleOpenModal(
        "error",
        "Request Failed. Please check your password and client name"
      );
    } finally {
      setFormData({
        password: "",
        secretCode: "",
      });
    }
  };

  return (
    <Container component="main" maxWidth="xl">
      <ModalComponent />
      <Box
        sx={{
          marginTop: 8,
        }}
      >
        <Grid container>
          <CssBaseline />
          <Grid
            item
            xs={false}
            sm={4}
            md={7}
            sx={{
              backgroundImage: `url(${backgroundImage})`,
              backgroundRepeat: "no-repeat",
              backgroundColor: (t) =>
                t.palette.mode === "light"
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
          >
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <StyledAvatar>
                <img
                  src={iconImage}
                  alt="Custom Icon"
                  style={{ width: "100%", height: "auto" }}
                />
              </StyledAvatar>
              <Typography component="h1" variant="h5">
                Reset Password
              </Typography>
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 1 }}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="secretCode"
                  label="Secret Code"
                  name="secretCode"
                  autoComplete="secretCode"
                  autoFocus
                  value={formData.secretCode}
                  onChange={handleInputChange}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="password"
                  label="New Password"
                  name="password"
                  autoComplete="password"
                  value={formData.password}
                  onChange={handleInputChange}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 2,
                    bgcolor: "error.main",
                    "&:hover": { bgcolor: "error.dark" },
                  }}
                >
                  Send Email
                </Button>
                <Grid container>
                  <Grid item xs>
                    <Link href="/login" variant="body2">
                      Login?
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
