import { useState } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import { ExternalBiReportInterface } from "../../interface/responseInterface/BiExternalReportInterface";
import Button from "@mui/material/Button";

const styles = StyleSheet.create({
  container: {
    padding: 20,
    backgroundColor: "white",
    fontFamily: "Helvetica",
    fontSize: 12,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
  },
  header: {
    textAlign: "center",
    marginBottom: 20,
  },
  headerContent: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  headerText: {
    fontSize: 18,
    fontWeight: "bold",
  },
  branchText: {
    fontSize: 14,
    fontStyle: "italic",
  },
  tableContainer: {
    marginBottom: 20,
    border: "1px solid black",
    borderRadius: 4,
    overflow: "hidden",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
  },
  tableHeader: {
    fontWeight: "bold",
    textAlign: "center",
    flexDirection: "row",
    backgroundColor: "lightblue",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCell: {
    border: "1px solid black",
    padding: 8,
    textAlign: "center",
    borderColor: "#000",
  },
  tableCellTestDate: {
    flex: 1,
    width: "20%",
  },
  tableCellSterilizer: {
    flex: 2,
    width: "35%",
  },
  tableCellResults: {
    flex: 1,
    width: "15%",
  },
  tableCellColspan: {
    flex: 2,
    width: "35%",
  },
  passStyle: {
    color: "green",
  },
  failStyle: {
    color: "red",
  },
  footer: {
    textAlign: "center",
    marginTop: 30,
    fontSize: 12,
    fontStyle: "italic",
    color: "#555",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

// Define prop types
interface ExportPDFButtonProps {
  groupedData: { [sterilizerId: number]: ExternalBiReportInterface[] };
  branchTitle: string | null;
}

// Function to split data into pages
const splitDataIntoPages = (
  data: ExternalBiReportInterface[],
  rowsPerPage: number
) => {
  const pages = [];
  for (let i = 0; i < data.length; i += rowsPerPage) {
    pages.push(data.slice(i, i + rowsPerPage));
  }
  return pages;
};

// Create a Document Component
const MyDocument = ({ groupedData, branchTitle }: ExportPDFButtonProps) => {
  const rowsPerPage = 4;
  const pages = Object.entries(groupedData).map(([sterilizerId, records]) =>
    splitDataIntoPages(records, rowsPerPage)
  );

  return (
    <Document>
      {pages.map((sterilizerPages, sterilizerIndex) =>
        sterilizerPages.map((pageData, pageIndex) => (
          <Page
            key={`${sterilizerIndex}-${pageIndex}`}
            size="A4"
            style={styles.container}
          >
            <View style={styles.header}>
              <View style={styles.headerContent}>
                <Text style={styles.headerText}>
                  Biological External Report
                </Text>
                <Text style={styles.branchText}>
                  {branchTitle ? branchTitle : ""}
                </Text>
              </View>
            </View>

            <View style={styles.tableContainer}>
              <View style={styles.table}>
                {/* Table Header */}
                <View style={styles.tableHeader}>
                  <Text style={[styles.tableCell, styles.tableCellTestDate]}>
                    Test Date
                  </Text>
                  <Text style={[styles.tableCell, styles.tableCellSterilizer]}>
                    Sterilizer
                  </Text>
                  <Text style={[styles.tableCell, styles.tableCellResults]}>
                    Transfer No.
                  </Text>
                  <Text style={[styles.tableCell, styles.tableCellResults]}>
                    Date Received
                  </Text>
                  <Text style={[styles.tableCell, styles.tableCellResults]}>
                    Date out
                  </Text>
                  <Text style={[styles.tableCell, styles.tableCellResults]}>
                    Test Results
                  </Text>
                </View>

                {/* Table Rows */}
                {pageData.map((item, index) => (
                  <>
                    <View key={index} style={styles.tableRow} wrap={false}>
                      <Text
                        style={[styles.tableCell, styles.tableCellTestDate]}
                      >
                        {item.test_date.split("T")[0]}
                      </Text>
                      <Text
                        style={[styles.tableCell, styles.tableCellSterilizer]}
                      >
                        {item.sterilizer_name}
                      </Text>
                      <Text style={[styles.tableCell, styles.tableCellResults]}>
                        {item.transfer_no}
                      </Text>
                      <Text style={[styles.tableCell, styles.tableCellResults]}>
                        {item.date_received.split("T")[0]}
                      </Text>
                      <Text style={[styles.tableCell, styles.tableCellResults]}>
                        {item.date_out.split("T")[0]}
                      </Text>
                      <Text style={[styles.tableCell, styles.tableCellResults]}>
                        {item.test_result === "Pass" ? (
                          <Text style={styles.passStyle}>Passed</Text>
                        ) : (
                          <Text style={styles.failStyle}>Failed</Text>
                        )}
                      </Text>
                    </View>
                  </>
                ))}
              </View>
            </View>

            <View style={styles.footer}>
              <Text
                style={styles.pageNumber}
                render={({ pageNumber, totalPages }) =>
                  `${pageNumber} / ${totalPages}`
                }
                fixed
              />
            </View>
          </Page>
        ))
      )}
    </Document>
  );
};

const ExportPDFButton = ({
  data,
  branchTitle,
}: {
  data: ExternalBiReportInterface[];
  branchTitle: string | null;
}) => {
  const [isReadyToDownload, setIsReadyToDownload] = useState(false);

  // Group data by sterilizer ID
  const groupedData = data.reduce(
    (acc: { [key: number]: ExternalBiReportInterface[] }, record) => {
      if (!acc[record.sterilizer_id]) {
        acc[record.sterilizer_id] = [];
      }
      acc[record.sterilizer_id].push(record);
      return acc;
    },
    {}
  );

  const handleDownload = () => {
    setIsReadyToDownload(true);
  };

  return (
    <div>
      {!isReadyToDownload ? (
        <Button variant="contained" color="primary" onClick={handleDownload}>
          Download PDF
        </Button>
      ) : (
        <PDFDownloadLink
          document={
            <MyDocument groupedData={groupedData} branchTitle={branchTitle} />
          }
          fileName="Biological_External_Report.pdf"
        >
          {({ loading }) =>
            loading ? "Preparing document..." : "Click to download PDF"
          }
        </PDFDownloadLink>
      )}
    </div>
  );
};

export default ExportPDFButton;
