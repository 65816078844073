import DynamicForm, { FieldConfig } from "../../components/forms/DynamicForm";
import * as Yup from "yup";
import { BranchFormValues } from "../../interface/responseInterface/BranchInterface";
import useSessionData from "../../hooks/useSessionData";
import { FormikHelpers } from "formik";
import axios from "axios";
import { useMessageModal } from "../../hooks/useMessageModal";
import FetchBranches from "../../utils/FetchBranches";
import { useNavigate } from "react-router-dom";

const branchInitialValues: BranchFormValues = {
  branchName: "",
  city: "",
  contact: "",
  rfidEnabled: "select",
  ciType: 0,
};

const branchValidationSchema: Yup.ObjectSchema<BranchFormValues> =
  Yup.object().shape({
    branchName: Yup.string().required("Branch Name is required"),
    city: Yup.string().required("City Location is required"),
    contact: Yup.string().required("Contact is required"),
    rfidEnabled: Yup.string().required("RFID is required"),
    ciType: Yup.number().required("Type is required"),
  });

const branchFields: FieldConfig[] = [
  { name: "branchName", label: "Branch Name *", type: "text", fullWidth: true },
  {
    name: "city",
    label: "City *",
    type: "text",
  },
  {
    name: "contact",
    label: "Contact *",
    type: "text",
  },
  {
    name: "rfidEnabled",
    label: "RFID Enabled *",
    type: "select",
    options: [
      { value: "select", label: "-- Select --" },
      { value: "1", label: "Yes" },
      { value: "0", label: "NO" },
    ],
  },
  {
    name: "ciType",
    label: "Type *",
    type: "number",
  },
];

const RegisterBranch = () => {
  const { getClientToken, getBaseURL } = useSessionData();
  const API_URL = getBaseURL();
  const { handleOpenModal, ModalComponent } = useMessageModal();
  const { getBranches } = FetchBranches();
  const navigate = useNavigate();

  const handleSubmit = async (
    values: BranchFormValues,
    { resetForm }: FormikHelpers<BranchFormValues>
  ) => {
    try {
      const response = await axios.post(
        `${API_URL}web-register/newbranch`,
        {
          branch_name: values.branchName,
          city: values.city,
          contact: Number(values.contact),
          rfid_enabled: values.rfidEnabled,
          ci_type: values.ciType,
        },
        {
          headers: {
            Authorization: `Bearer ${getClientToken()} `,
          },
        }
      );
      if (response.status !== 201) {
        handleOpenModal(
          "error",
          "Registration failed. Please try again later."
        );
        return;
      }
      handleOpenModal("success", "Successfully registered the branch.");
      getBranches();
      navigate(0);
    } catch (error) {
      handleOpenModal("error", "Registration failed. Please try again later.");
    }
  };

  return (
    <div>
      <ModalComponent />
      <DynamicForm
        initialValues={branchInitialValues}
        validationSchema={branchValidationSchema}
        fields={branchFields}
        formTitle="Register Branch"
        onSubmit={handleSubmit}
      />
    </div>
  );
};

export default RegisterBranch;
