import DynamicTable from "../../components/table/DynamicTable";
import { ArticleSetResponse } from "../../interface/responseInterface/ArticleSetInterface";
import axios from "axios";
import { useEffect, useState } from "react";
import useSessionData from "../../hooks/useSessionData";
import { Column, Data } from "../../interface/TableInterface";
import ContainedButton from "../../components/button/ContainedButton";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../stores/store";

const columns: Column[] = [
  { id: "id", label: "#", numeric: false },
  { id: "ak_name", label: "Article Kit Name", numeric: false },
  { id: "ak_tray_name", label: "Tray Name", numeric: false },
  { id: "tag_array", label: "Articles Contain", numeric: false },
  { id: "branch_name", label: "Branch", numeric: false },
  { id: "current_status", label: "Status", numeric: false },
];

const ArticleSet = () => {
  const { getClientToken, getBaseURL } = useSessionData();
  const API_URL = getBaseURL();
  const [response, setResponse] = useState<Data[]>([]);
  const currentBranch = useSelector(
    (state: RootState) => state.branch.currentBranch
  );

  useEffect(() => {
    const getServiceLog = async () => {
      let branchQuery: string = `br.branch_id=${currentBranch}`;
      try {
        const { data } = await axios.get<ArticleSetResponse[]>(
          `${API_URL}article-kits/all?${branchQuery}`,
          {
            headers: {
              Authorization: `Bearer ${getClientToken()}`,
            },
          }
        );

        const mappedData: Data[] = data.map((item, index) => ({
          id: index + 1,
          ak_name: item.ak_name,
          ak_tray_name: item.ak_tray_name,
          tag_array: "any",
          branch_name: item.branch_name,
          current_status: item.current_status,
        }));

        setResponse(mappedData);
      } catch (error) {
        console.error("Error fetching service log:", error);
      }
    };

    getServiceLog();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleButtonClick = () => {
    // Handle button click logic here
    console.log("Button clicked");
  };

  return (
    <>
      <h4>Article Set</h4>
      <Box sx={{ display: "flex", justifyContent: "flex-end", m: 2 }}>
        <ContainedButton buttonTitle="Add" onButtonClick={handleButtonClick} />
      </Box>
      <DynamicTable headCells={columns} rows={response} />
    </>
  );
};

export default ArticleSet;
