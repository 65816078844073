import React, { useState, useEffect } from "react";
import DynamicTable from "../../components/table/DynamicTable";
import { TableActions, Column, Data } from "../../interface/TableInterface";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import ContainedButton from "../../components/button/ContainedButton";
import { useNavigate } from "react-router-dom";
import FetchBranches from "../../utils/FetchBranches";
import axios from "axios";
import useSessionData from "../../hooks/useSessionData";
import { useMessageModal } from "../../hooks/useMessageModal";

const columns: Column[] = [
  { id: "id", label: "#", numeric: false },
  { id: "name", label: "Branch Name", numeric: false },
  { id: "city", label: "Branch City", numeric: false },
  { id: "current_status", label: "Status", numeric: false },
  { id: "rfid_enabled", label: "RFID", numeric: false },
  { id: "type_id", label: "Type", numeric: false },
];

const BranchList = () => {
  const [response, setResponse] = useState<Data[]>([]);
  const [responseData, setResponseData] = useState<any[]>([]);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState<any>(null);
  const [branchName, setBranchName] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [contact, setContact] = useState<number>(0);
  const [rfidEnabled, setRfidEnabled] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const { getClientToken, getBaseURL } = useSessionData();
  const { handleOpenModal, ModalComponent } = useMessageModal();
  const { branchData } = FetchBranches();
  const navigate = useNavigate();
  const API_URL = getBaseURL();

  useEffect(() => {
    try {
      setLoading(true);
      if (branchData && branchData.length > 0) {
        setResponseData(branchData);
        const mappedData: Data[] = branchData.map((item, index) => ({
          id: item.branch_id,
          name: item.branch_name,
          city: item.city,
          current_status: item.flag === 0 ? "Inactive" : "Active",
          rfid_enabled: item.rfid_enabled === 0 ? "No" : "Yes",
          type_id: item.type_id,
        }));
        setResponse(mappedData);
      }
    } catch (error) {
      handleOpenModal("error", "Error fetching branch list");
    } finally {
      setLoading(false);
    }
  }, [branchData]);

  const handleEdit = (id: number) => {
    const branch = responseData.find((branch) => branch.branch_id === id);
    if (branch) {
      setSelectedBranch(branch);
      setBranchName(branch.branch_name);
      setCity(branch.city);
      setContact(branch.branch_contact);
      setRfidEnabled(branch.rfid_enabled ? "1" : "0");
      setOpenEditDialog(true);
    }
  };

  const handleDelete = (id: number) => {
    const branch = responseData.find((branch) => branch.branch_id === id);
    if (branch) {
      setSelectedBranch(branch);
      setOpenDeleteDialog(true);
    }
  };

  const handleEditSubmit = async () => {
    if (selectedBranch) {
      try {
        const response = await axios.put(
          `${API_URL}web-register/newbranch`,
          {
            branch_id: selectedBranch.branch_id,
            branch_name: branchName,
            city: city,
            contact: contact,
            rfid_enabled: Number(rfidEnabled),
          },
          {
            headers: {
              Authorization: `Bearer ${getClientToken()}`,
            },
          }
        );
        if (response.status === 201) {
          // Update the branch list with the edited branch data
          setResponse((prev) =>
            prev.map((branch) =>
              branch.id === selectedBranch.branch_id
                ? {
                    ...branch,
                    name: branchName,
                    city,
                    rfid_enabled: rfidEnabled === "1" ? "Yes" : "No",
                  }
                : branch
            )
          );
          setResponseData((prev) =>
            prev.map((branch) =>
              branch.id === selectedBranch.branch_id
                ? {
                    ...branch,
                    name: branchName,
                    city,
                    rfid_enabled: rfidEnabled === "1" ? "Yes" : "No",
                  }
                : branch
            )
          );
          setOpenEditDialog(false);
          handleOpenModal("success", "Successfully modified branch.");
        }
      } catch (error) {
        handleOpenModal("error", "Error updating branch.");
      }
    }
  };

  const handleDeleteSubmit = async () => {
    if (selectedBranch) {
      try {
        const response = await axios.delete(
          `${API_URL}web-register/newbranch`,
          {
            headers: {
              Authorization: `Bearer ${getClientToken()}`,
            },
            data: { branch_id: selectedBranch.branch_id },
          }
        );
        console.log(response);
        if (response.data.response === "Branch Deleted!") {
          setResponse((prev) =>
            prev.filter((branch) => branch.id !== selectedBranch.branch_id)
          );
          setResponseData((prev) =>
            prev.filter((branch) => branch.id !== selectedBranch.branch_id)
          );
          setOpenDeleteDialog(false);
          handleOpenModal("success", "Successfully deleted branch.");
        }
      } catch (error) {
        handleOpenModal("error", "Error deleting branch.");
      }
    }
  };

  const rowActions: TableActions[] = response
    .map((row) => [
      {
        id: `edit_${row.id}`,
        label: "Edit",
        onClick: () => handleEdit(row.id),
      },
      {
        id: `delete_${row.id}`,
        label: "Delete",
        onClick: () => handleDelete(row.id),
      },
    ])
    .flat();

  const handleButtonClick = () => {
    navigate("/register-branch");
  };

  return (
    <>
      <h4>Branches</h4>
      <Box sx={{ display: "flex", justifyContent: "flex-end", m: 2 }}>
        <ContainedButton buttonTitle="Add" onButtonClick={handleButtonClick} />
      </Box>
      <ModalComponent />
      {loading ? (
        <Box display="flex" justifyContent="center" mt={3}>
          <CircularProgress color="primary" size={50} />
        </Box>
      ) : (
        <DynamicTable
          headCells={columns}
          rows={response}
          actions={rowActions}
        />
      )}

      {/* Edit Branch Dialog */}
      <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)}>
        <DialogTitle>Edit Branch</DialogTitle>
        <DialogContent>
          <div>
            {" "}
            {/* Replace DialogContentText with div */}
            <TextField
              margin="dense"
              label="Branch Name"
              type="text"
              fullWidth
              value={branchName}
              onChange={(e) => setBranchName(e.target.value)}
            />
            <TextField
              margin="dense"
              label="City"
              type="text"
              fullWidth
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
            <TextField
              margin="dense"
              label="Contact"
              type="text"
              fullWidth
              value={contact.toString()}
              onChange={(e) => setContact(Number(e.target.value))}
            />
            <Select
              label="RFID Enabled"
              fullWidth
              value={rfidEnabled}
              onChange={(e) => setRfidEnabled(e.target.value)}
            >
              <MenuItem value="1">Yes</MenuItem>
              <MenuItem value="0">No</MenuItem>
            </Select>
          </div>{" "}
          {/* End of replacement */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditSubmit} color="primary">
            Submit
          </Button>
          <Button onClick={() => setOpenEditDialog(false)} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Branch Dialog */}
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
      >
        <DialogTitle>Delete Branch</DialogTitle>
        <DialogContent>
          <div>
            {" "}
            {/* Replace DialogContentText with div */}
            Are you sure you want to delete the {
              selectedBranch?.branch_name
            }{" "}
            branch?
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteSubmit} color="primary">
            Yes
          </Button>
          <Button onClick={() => setOpenDeleteDialog(false)} color="secondary">
            No
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BranchList;
