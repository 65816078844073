import React from "react";
import { Box, TextField, Grid } from "@mui/material";
import {
  StyledCard,
  StyledForm,
  StyledFormControl,
  StyledButton,
} from "../../components/styles/Styles"; // Adjust the path as needed
import DeleteIcon from "@mui/icons-material/Delete";

interface Article {
  articleName: string;
  articleTag: string;
}

interface ArticleFormProps {
  input: Article;
  index: number;
  handleArticleChange: (
    index: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  removeArticleData: (index: number) => void;
}

const ArticleForm: React.FC<ArticleFormProps> = ({
  input,
  index,
  handleArticleChange,
  removeArticleData,
}) => {
  return (
    <StyledCard>
      <StyledForm>
        <Box mb={3}>
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <StyledFormControl>
                <TextField
                  fullWidth
                  label="Article Name"
                  name="articleName"
                  placeholder="Article Name"
                  value={input.articleName}
                  onChange={(e) => handleArticleChange(index, e)}
                  variant="outlined"
                  size="small"
                />
              </StyledFormControl>
            </Grid>
            <Grid item xs={5}>
              <StyledFormControl>
                <TextField
                  fullWidth
                  label="Article RFID Tag"
                  name="articleTag"
                  placeholder="Article RFID Tag"
                  value={input.articleTag}
                  onChange={(e) => handleArticleChange(index, e)}
                  variant="outlined"
                  size="small"
                />
              </StyledFormControl>
            </Grid>
            <Grid item xs={2}>
              <StyledButton
                color="error"
                size="small"
                onClick={() => removeArticleData(index)}
              >
                <DeleteIcon />
              </StyledButton>
            </Grid>
          </Grid>
        </Box>
      </StyledForm>
    </StyledCard>
  );
};

export default ArticleForm;
