import { AppDispatch } from "../stores/store";
import {
  fetchClientFailure,
  fetchClientRequest,
  fetchClientSuccess,
} from "../actions/clientActions";
import { ClientInfo } from "../interface/reduxInterfaces/ClientInfoInterface";

export const fetchClient =
  (emailId: string | null, baseUrl: string, token: string) =>
  async (dispatch: AppDispatch) => {
    dispatch(fetchClientRequest());

    try {
      const response = await fetch(
        `${baseUrl}user/companyinfo?u.email=${emailId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const contentType = response.headers.get("Content-Type");
      const rawText = await response.text();
      if (response.ok && contentType?.includes("application/json")) {
        // If the response is an array with one object, adjust accordingly
        const data: ClientInfo[] = JSON.parse(rawText);
        dispatch(fetchClientSuccess(data));
      } else {
        throw new Error(`Unexpected response: ${rawText}`);
      }
    } catch (error) {
      let errorMessage = "Something went wrong";
      if (error instanceof Error) {
        errorMessage = error.message;
      } else if (typeof error === "string") {
        errorMessage = error;
      }
      dispatch(fetchClientFailure(errorMessage));
    }
  };
