import { useState, useEffect } from "react";
import { Box, CircularProgress } from "@mui/material";
import DynamicTable from "../../components/table/DynamicTable";
import { BilogicalRecordSheetResponse } from "../../interface/responseInterface/BiFailedReportInterface";
import axios from "axios";
import useSessionData from "../../hooks/useSessionData";
import { Column, Data, TableActions } from "../../interface/TableInterface";
import { useSelector } from "react-redux";
import { RootState } from "../../stores/store";
import DetailsDialog from "./DetailsDialog";
import ImageDialog from "./ImageDialog";
import { useMessageModal } from "../../hooks/useMessageModal";
import ExportPDFButton from "./ExportBiRecordSheet";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Dayjs } from "dayjs";
import moment from "moment";

const columns: Column[] = [
  { id: "id", label: "#", numeric: false },
  { id: "sterilizer", label: "Sterilizer", numeric: false },
  { id: "test_date", label: "Test Date", numeric: false },
  { id: "load_by", label: "Load By", numeric: false },
  { id: "load_no", label: "Load No", numeric: false },
];

const BilogicalRecordSheet = () => {
  const { getClientToken, getBaseURL } = useSessionData();
  const API_URL = getBaseURL();
  const { handleOpenModal, ModalComponent } = useMessageModal();
  const [response, setResponse] = useState<Data[]>([]);
  const [responseData, setResponseData] = useState<
    BilogicalRecordSheetResponse[]
  >([]);
  const [selectedArticle, setSelectedArticle] =
    useState<BilogicalRecordSheetResponse | null>(null);
  const currentBranch = useSelector(
    (state: RootState) => state.branch.currentBranch
  );
  const [branchName, setBranchName] = useState<string | null>(null);
  const clientInfo = useSelector((state: RootState) => state.client.clientInfo);

  useEffect(() => {
    if (clientInfo && clientInfo.length > 0) {
      setBranchName(clientInfo[0].branch_name);
    }
  }, [clientInfo]);

  const [imageURL, setImageURL] = useState<string | null>(null);
  const [isDetailsOpen, setIsDetailsOpen] = useState<boolean>(false);
  const [isImageOpen, setIsImageOpen] = useState<boolean>(false);

  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const getFailedBiList = async () => {
    setLoading(true);

    // Construct the branch query
    let branchQuery = `ap.branch_id=${currentBranch}&app.bi_image_url[neq]=nophoto`;

    // Date query initialization
    let dateQuery = "";

    // Format the start and end dates
    const startDateFormatted = startDate
      ? startDate.format("YYYY-MM-DD HH:mm:ss.SSSSSS")
      : "1970-01-01 00:00:00.000000";

    const endDateFormatted = endDate
      ? endDate.format("YYYY-MM-DD HH:mm:ss.SSSSSS")
      : moment().format("YYYY-MM-DD HH:mm:ss.SSSSSS");

    // Append date range to the query if dates are provided
    if (startDate || endDate) {
      dateQuery = `&ap.load_started_at[gte]=${startDateFormatted}&ap.load_started_at[lte]=${endDateFormatted}`;
    }

    // Full query string, ensuring no extra `&` or `AND`
    const fullQuery = `${branchQuery}${dateQuery}`;

    try {
      // Fetch data using axios with constructed query
      const result = await axios.get<BilogicalRecordSheetResponse[]>(
        `${API_URL}web-reports/getBiReports?${fullQuery}`,
        {
          headers: {
            Authorization: `Bearer ${getClientToken()}`,
          },
        }
      );

      // Map the response data into the required structure
      const mapData: Data[] = result.data.map((item) => ({
        id: item.id,
        sterilizer: item.sterilizer_name,
        load_by: item.load_started_by,
        test_date: item.load_started_at.split("T")[0],
        load_no: item.cycle_no,
      }));

      setResponseData(result.data);
      setResponse(mapData);
    } catch (error) {
      handleOpenModal("alert", "No data found!");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getFailedBiList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBranch, startDate, endDate]);

  const handleViewDetails = (id: number) => {
    const article = responseData.find((item) => item.id === id);
    if (article) {
      setSelectedArticle(article);
      setIsDetailsOpen(true);
    }
  };

  const handleImageOpen = (id: number) => {
    const article = responseData.find((item) => item.id === id);
    if (article) {
      setImageURL(article.image_bi_url);
      setIsImageOpen(true);
    }
  };

  const handleDialogClose = () => {
    setSelectedArticle(null);
    setIsDetailsOpen(false);
  };

  const handleImageClose = () => {
    setImageURL(null);
    setIsImageOpen(false);
  };

  const rowActions: TableActions[] = response.map((row) => ({
    id: `view_${row.id}`,
    label: "View Details",
    onClick: () => handleViewDetails(row.id),
  }));

  const handleStartDateChange = (newValue: Dayjs | null) => {
    if (newValue && endDate && newValue.isAfter(endDate)) {
      alert("Start date cannot be greater than end date.");
      resetDates();
    } else {
      setStartDate(newValue);
    }
  };

  const handleEndDateChange = (newValue: Dayjs | null) => {
    if (newValue && startDate && newValue.isBefore(startDate)) {
      alert("End date cannot be less than start date.");
      resetDates();
    } else {
      setEndDate(newValue);
    }
  };

  const resetDates = () => {
    setStartDate(null);
    setEndDate(null);
  };

  return (
    <>
      <h4>Biological Record Sheet</h4>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            m: 2,
          }}
        >
          <Box sx={{ display: "flex", gap: 2 }}>
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={handleStartDateChange}
            />
            <DatePicker
              label="End Date"
              value={endDate}
              onChange={handleEndDateChange}
            />
          </Box>
          {/* ExportPDFButton will always generate a new PDF based on the updated responseData */}
          <ExportPDFButton data={responseData} branchTitle={branchName} />
        </Box>
      </LocalizationProvider>
      <ModalComponent />
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress size={24} />
        </Box>
      ) : (
        <DynamicTable
          headCells={columns}
          rows={response}
          actions={rowActions}
        />
      )}
      <DetailsDialog
        open={isDetailsOpen}
        onClose={handleDialogClose}
        article={selectedArticle}
        onViewPhoto={() => handleImageOpen(selectedArticle?.id || 0)}
      />
      <ImageDialog
        open={isImageOpen}
        onClose={handleImageClose}
        imageURL={imageURL}
      />
    </>
  );
};

export default BilogicalRecordSheet;
