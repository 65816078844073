import React, { useState } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import { BilogicalRecordSheetResponse } from "../../interface/responseInterface/BiFailedReportInterface";
import Button from "@mui/material/Button";

// Create styles
const styles = StyleSheet.create({
  container: {
    padding: 20,
    backgroundColor: "white",
    fontFamily: "Helvetica",
    fontSize: 12,
  },
  header: {
    textAlign: "center",
    marginBottom: 20,
  },
  headerContent: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  headerText: {
    fontSize: 18,
    fontWeight: "bold",
  },
  branchText: {
    fontSize: 14,
    fontStyle: "italic",
  },
  tableContainer: {
    marginBottom: 20,
    border: "1px solid black",
    borderRadius: 4,
    overflow: "hidden",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
  },
  tableHeader: {
    fontWeight: "bold",
    textAlign: "center",
    flexDirection: "row",
    backgroundColor: "lightblue",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCell: {
    border: "1px solid black",
    padding: 8,
    textAlign: "center",
    borderColor: "#000",
  },
  tableCellTestDate: {
    flex: 1,
    width: "20%",
  },
  tableCellSterilizer: {
    flex: 2,
    width: "35%",
  },
  tableCellResults: {
    flex: 1,
    width: "15%",
  },
  tableCellColspan: {
    flex: 2,
    width: "35%",
  },
  passStyle: {
    color: "green",
  },
  failStyle: {
    color: "red",
  },
  footer: {
    textAlign: "center",
    marginTop: 30,
    fontSize: 12,
    fontStyle: "italic",
    color: "#555",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

// Define prop types
interface MyDocumentProps {
  data: BilogicalRecordSheetResponse[];
  branchTitle: string | null;
}

// Function to split data into pages
const splitDataIntoPages = (
  data: BilogicalRecordSheetResponse[],
  rowsPerPage: number
) => {
  const pages = [];
  for (let i = 0; i < data.length; i += rowsPerPage) {
    pages.push(data.slice(i, i + rowsPerPage));
  }
  return pages;
};

const getInitials = (fullName: string) => {
  const nameParts = fullName.split(" ");
  const initials = nameParts.map((part) => part.charAt(0)).join("");
  return initials.toUpperCase();
};

// Create a Document Component
const MyDocument = ({ data, branchTitle }: MyDocumentProps) => {
  const rowsPerPage = 12; // Adjust this value based on your content and page size
  const pages = splitDataIntoPages(data, rowsPerPage);

  return (
    <Document>
      {pages.map((pageData, pageIndex) => (
        <Page
          key={pageIndex}
          orientation="landscape"
          size="A4"
          style={styles.container}
        >
          <View style={styles.header}>
            <View style={styles.headerContent}>
              <Text style={styles.headerText}>Biological Record Sheet</Text>
              <Text style={styles.branchText}>
                {branchTitle ? branchTitle : ""}
              </Text>
            </View>
          </View>

          <View style={styles.tableContainer}>
            <View style={styles.table}>
              {/* Table Header */}
              <View style={styles.tableHeader}>
                <Text style={[styles.tableCell, styles.tableCellTestDate]}>
                  Test Date
                </Text>
                <Text style={[styles.tableCell, styles.tableCellSterilizer]}>
                  Sterilizer
                </Text>
                <Text style={[styles.tableCell, styles.tableCellResults]}>
                  Cycle No.
                </Text>
                <Text style={[styles.tableCell, styles.tableCellResults]}>
                  Initials
                </Text>
                <Text style={[styles.tableCell, styles.tableCellResults]}>
                  Incubation IN
                </Text>
                <Text style={[styles.tableCell, styles.tableCellResults]}>
                  Incubation Out
                </Text>
                <Text style={[styles.tableCell, styles.tableCellResults]}>
                  Results
                </Text>
                <Text style={[styles.tableCell, styles.tableCellResults]}>
                  Comments
                </Text>
              </View>

              {/* Table Rows */}
              {pageData.map((item, index) => (
                <View key={index} style={styles.tableRow} wrap={false}>
                  <Text style={[styles.tableCell, styles.tableCellTestDate]}>
                    {item.load_started_at.split("T")[0]}
                  </Text>
                  <Text style={[styles.tableCell, styles.tableCellSterilizer]}>
                    {item.sterilizer_name}
                  </Text>
                  <Text style={[styles.tableCell, styles.tableCellResults]}>
                    {item.cycle_no}
                  </Text>
                  <Text style={[styles.tableCell, styles.tableCellResults]}>
                    {getInitials(item.load_started_by.toString())}
                  </Text>
                  <Text style={[styles.tableCell, styles.tableCellResults]}>
                    {item.load_started_at.split("T")[0]}
                  </Text>
                  <Text style={[styles.tableCell, styles.tableCellResults]}>
                    {item.bi_finished_at.split("T")[0]}
                  </Text>
                  <Text style={[styles.tableCell, styles.tableCellResults]}>
                    {Number(item.bi_result) === 1 ? (
                      <Text style={styles.passStyle}>Passed</Text>
                    ) : (
                      <Text style={styles.failStyle}>Failed</Text>
                    )}
                  </Text>
                  <Text
                    style={[styles.tableCell, styles.tableCellResults]}
                  ></Text>
                </View>
              ))}
            </View>
          </View>

          <View style={styles.footer}>
            <Text
              style={styles.pageNumber}
              render={({ pageNumber, totalPages }) =>
                `${pageNumber} / ${totalPages}`
              }
              fixed
            />
          </View>
        </Page>
      ))}
    </Document>
  );
};

const ExportPDFButton = ({ data, branchTitle }: MyDocumentProps) => {
  const [isReadyToDownload, setIsReadyToDownload] = useState(false);

  const handleDownload = () => {
    setIsReadyToDownload(true);
  };

  return (
    <div>
      {!isReadyToDownload ? (
        <Button variant="contained" color="primary" onClick={handleDownload}>
          Download PDF
        </Button>
      ) : (
        <PDFDownloadLink
          document={<MyDocument data={data} branchTitle={branchTitle} />}
          fileName="Biological_Record_Sheet.pdf"
        >
          {({ loading }) =>
            loading ? "Preparing document..." : "Click to download PDF"
          }
        </PDFDownloadLink>
      )}
    </div>
  );
};

export default ExportPDFButton;
