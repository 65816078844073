import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Paper,
  Typography,
  Grid,
  Box,
} from "@mui/material";
import { UsedArticleInfoDialogProps } from "../../interface/responseInterface/UsedArticleInterface";

const UsedArticleInfoDialog = ({
  open,
  articleInfo,
  parentCallback,
}: UsedArticleInfoDialogProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(open);
  const [pouchData, setPouchData] = useState<string[]>([]);

  useEffect(() => {
    if (articleInfo.pouch_articles) {
      setPouchData(articleInfo.pouch_articles.split(","));
    }
    setIsOpen(open);
  }, [open, articleInfo]);

  const handleClose = () => {
    setIsOpen(false);
    parentCallback(false);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">{articleInfo.type}</DialogTitle>
      <DialogContent sx={{ backgroundColor: "#eff9f0" }}>
        <Box sx={{ my: 2 }}>
          <Typography variant="h6" align="center">
            Article Details
          </Typography>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={6} sm={3}>
              <Paper sx={{ padding: 2, textAlign: "center" }}>
                <Typography variant="body1" fontWeight="bold">
                  Control No
                </Typography>
                <Typography>{articleInfo.control_no}</Typography>
              </Paper>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Paper sx={{ padding: 2, textAlign: "center" }}>
                <Typography variant="body1" fontWeight="bold">
                  Total Cycles
                </Typography>
                <Typography>{articleInfo.total_cycles}</Typography>
              </Paper>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Paper sx={{ padding: 2, textAlign: "center" }}>
                <Typography variant="body1" fontWeight="bold">
                  Used By
                </Typography>
                <Typography>{articleInfo.used_by}</Typography>
              </Paper>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Paper sx={{ padding: 2, textAlign: "center" }}>
                <Typography variant="body1" fontWeight="bold">
                  Used Date
                </Typography>
                <Typography>{articleInfo.date_used.split("T")[0]}</Typography>
              </Paper>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ my: 2 }}>
          <Typography variant="h6" align="center">
            Autoclave Details
          </Typography>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={6} sm={3}>
              <Paper sx={{ padding: 2, textAlign: "center" }}>
                <Typography variant="body1" fontWeight="bold">
                  Autoclave
                </Typography>
                <Typography>{articleInfo.autoclave_name}</Typography>
              </Paper>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Paper sx={{ padding: 2, textAlign: "center" }}>
                <Typography variant="body1" fontWeight="bold">
                  Cycle No
                </Typography>
                <Typography>{articleInfo.cycle_no}</Typography>
              </Paper>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Paper sx={{ padding: 2, textAlign: "center" }}>
                <Typography variant="body1" fontWeight="bold">
                  Load Date
                </Typography>
                <Typography>
                  {articleInfo.load_finished_at.split("T")[0]}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Paper sx={{ padding: 2, textAlign: "center" }}>
                <Typography variant="body1" fontWeight="bold">
                  Load By
                </Typography>
                <Typography>{articleInfo.load_finished_by}</Typography>
              </Paper>
            </Grid>
          </Grid>
        </Box>

        {pouchData.length > 1 && (
          <Box sx={{ my: 2 }}>
            <Typography variant="h6" align="center">
              Pouch Articles
            </Typography>
            <Paper sx={{ padding: 2 }}>
              <ul>
                {pouchData.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </Paper>
          </Box>
        )}
      </DialogContent>
      <DialogActions sx={{ backgroundColor: "#eff9f0" }}>
        <Button onClick={handleClose} color="primary" autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UsedArticleInfoDialog;
