import { useEffect, useMemo, useState } from "react";
import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";
import { useMessageModal } from "../../hooks/useMessageModal";
import useSessionData from "../../hooks/useSessionData";
import axios from "axios";
import { ExternalBiReportInterface } from "../../interface/responseInterface/BiExternalReportInterface";
import { useSelector } from "react-redux";
import { RootState } from "../../stores/store";
import ExportPDFButton from "./ExportBiExternalReport";

interface GroupedData {
  [sterilizerId: number]: ExternalBiReportInterface[];
}

const BiExternalReport = () => {
  const [reportDate, setReportDate] = useState<Dayjs | null>(null);
  const { getClientToken, getBaseURL } = useSessionData();
  const API_URL = getBaseURL();
  const { handleOpenModal, ModalComponent } = useMessageModal();
  const [data, setData] = useState<ExternalBiReportInterface[]>([]);

  const [branchName, setBranchName] = useState<string | null>(null);
  const clientInfo = useSelector((state: RootState) => state.client.clientInfo);

  useEffect(() => {
    if (clientInfo && clientInfo.length > 0) {
      setBranchName(clientInfo[0].branch_name);
    }
  }, [clientInfo]);

  const currentBranch = useSelector(
    (state: RootState) => state.branch.currentBranch
  );

  const fetchData = async () => {
    let branchQuery = `ebr.branch_id=${currentBranch}`;
    let dateQuery = "";

    // Format the start and end dates
    const reportDateFormatted = reportDate
      ? reportDate.format("YYYY-MM-DD HH:mm:ss.SSSSSS")
      : "";

    if (reportDate) {
      dateQuery = `&ebr.report_date[eq]=${reportDateFormatted}`;
    }

    try {
      const result = await axios.get<ExternalBiReportInterface[]>(
        `${API_URL}web-reports/biexternalreport?${branchQuery}${dateQuery}`,
        {
          headers: {
            Authorization: `Bearer ${getClientToken()}`,
          },
        }
      );
      setData(result.data);
    } catch (error) {
      setData([]);
      handleOpenModal("error", "Failed to fetch result.");
    } finally {
      resetDates();
    }
  };

  const groupedData: GroupedData = useMemo(() => {
    return data.reduce((acc, item) => {
      if (!acc[item.sterilizer_id]) {
        acc[item.sterilizer_id] = [];
      }
      acc[item.sterilizer_id].push(item);
      return acc;
    }, {} as GroupedData);
  }, [data]);

  const handleSubmit = () => {
    if (reportDate) {
      fetchData();
    } else {
      handleOpenModal("alert", "Please select report date.");
    }
  };

  const handleReportDateChange = (newValue: Dayjs | null) => {
    setReportDate(newValue);
  };

  const resetDates = () => {
    setReportDate(null);
  };
  return (
    <>
      <h4>Biological External Report</h4>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            m: 2,
          }}
        >
          <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
            <DatePicker
              label="Report Date"
              value={reportDate}
              onChange={handleReportDateChange}
            />
            <Button
              variant="contained"
              onClick={handleSubmit}
              size="medium"
              disabled={!reportDate}
            >
              Submit
            </Button>
          </Box>
          <ExportPDFButton data={data} branchTitle={branchName} />
        </Box>
      </LocalizationProvider>
      <ModalComponent />

      {Object.entries(groupedData).map(([sterilizerId, records]) => (
        <Paper
          key={sterilizerId}
          sx={{
            width: "100%",
            borderRadius: "8px",
            border: "0.1px solid #979dac",
            padding: "12px",
            marginBottom: "10px",
          }}
        >
          <Typography
            variant="h6"
            sx={{ fontWeight: "bold", textAlign: "center" }}
          >
            {records[0].sterilizer_name}
          </Typography>
          {records.map((record: ExternalBiReportInterface) => (
            <Grid
              container
              spacing={2}
              sx={{ textAlign: "center" }}
              key={record.id}
            >
              <Grid item xs={2}>
                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                  Transfer Number
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "bold", marginTop: 1 }}
                >
                  {record.transfer_no}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                  Test Date
                </Typography>
                <Typography variant="body2" sx={{ marginTop: 1 }}>
                  {record.test_date.split("T")[0]}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                  Date Received
                </Typography>
                <Typography variant="body2" sx={{ marginTop: 1 }}>
                  {record.date_received.split("T")[0]}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                  Date Out
                </Typography>
                <Typography variant="body2" sx={{ marginTop: 1 }}>
                  {record.date_out.split("T")[0]}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                  Test Results
                </Typography>
                <Typography variant="body2" sx={{ marginTop: 1 }}>
                  {record.test_result === "Pass"
                    ? "Passed. No Growth observed after incubation period."
                    : "Failed."}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Paper>
      ))}
    </>
  );
};

export default BiExternalReport;
