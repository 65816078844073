import { setBranch, logout } from "../actions/branchActions";
import { createReducer } from "@reduxjs/toolkit";
import { Branches } from "../interface/reduxInterfaces/BranchInterface";

const initialState: Branches = {
    currentBranch: '',
    branch_id: null
};

const branchReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setBranch, (state, action) => {
            state.currentBranch = action.payload;
        })
        .addCase(logout, () => initialState);
});

export default branchReducer;