import { Formik, FormikHelpers, Form } from "formik";
import { Grid, CardContent, SelectChangeEvent } from "@mui/material";
import {
  FormikTextField,
  FormikSelect,
  FormikCheckbox,
  FormikAutocomplete,
} from "./FormsField";
import * as Yup from "yup";
import {
  StyledCard,
  StyledFormControl,
  StyledButton,
  StyledTypography,
  FormStyledTypography,
} from "../../components/styles/Styles";

export interface FieldConfig {
  name: string;
  label: string;
  type: "text" | "select" | "checkbox" | "autocomplete" | "number";
  inputType?: string; // for text and number fields
  options?: { value: string; label: string }[]; // for select and autocomplete fields
  fullWidth?: boolean;
  onChange?: (e: SelectChangeEvent<any>) => void;
}

interface DynamicFormProps<T extends Record<string, any>> {
  initialValues: T;
  validationSchema: Yup.ObjectSchema<T>;
  fields: FieldConfig[];
  formTitle: string;
  onSubmit: (
    values: T,
    formikHelpers: FormikHelpers<T>
  ) => void | Promise<void>;
}

const DynamicForm = <T extends Record<string, any>>({
  initialValues,
  validationSchema,
  fields,
  formTitle,
  onSubmit,
}: DynamicFormProps<T>) => (
  <Formik
    initialValues={initialValues}
    validationSchema={validationSchema}
    onSubmit={(values, actions) => {
      onSubmit(values, actions);
      actions.resetForm();
    }}
  >
    {({ handleSubmit }) => (
      <>
        <StyledTypography
          variant="h5"
          sx={{ fontWeight: "medium" }}
          gutterBottom
        >
          {formTitle}
        </StyledTypography>
        <StyledCard>
          <CardContent>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit(e);
              }}
            >
              <Grid container spacing={2}>
                {fields.map((field, index) => (
                  <Grid item xs={12} sm={field.fullWidth ? 12 : 6} key={index}>
                    <FormStyledTypography
                      variant="body2"
                      sx={{ fontWeight: "heavy" }}
                      gutterBottom
                    >
                      {field.label}
                    </FormStyledTypography>
                    <StyledFormControl>
                      {field.type === "text" && (
                        <FormikTextField
                          name={field.name}
                          label={field.label}
                          type={field.inputType}
                        />
                      )}
                      {field.type === "number" && (
                        <FormikTextField
                          name={field.name}
                          label={field.label}
                          type="number"
                        />
                      )}
                      {field.type === "select" && (
                        <FormikSelect
                          name={field.name}
                          label={field.label}
                          options={field.options}
                          onChange={field.onChange}
                        />
                      )}
                      {field.type === "checkbox" && (
                        <FormikCheckbox name={field.name} label={field.label} />
                      )}
                      {field.type === "autocomplete" && (
                        <FormikAutocomplete
                          name={field.name}
                          label={field.label}
                          options={field.options || []}
                        />
                      )}
                    </StyledFormControl>
                  </Grid>
                ))}
                <Grid item xs={12}>
                  <StyledButton
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Submit
                  </StyledButton>
                </Grid>
              </Grid>
            </Form>
          </CardContent>
        </StyledCard>
      </>
    )}
  </Formik>
);

export default DynamicForm;
